import React, { useContext, useEffect, useState } from 'react';
import { Modal } from "antd";
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import CancelOutlined from "@mui/icons-material/CancelOutlined";
import { EyeIcon, EyeIconReviewed } from "../../constants/menuIcons";

import TestcaseTable from '../../screens/testcaseTable';
import ReviewTestCases from '../../components/messages/reviewTestCases';

import { getSortDirection } from '../../utils/sorterUtils';
import { standalone_SuperAdmin_columns, standalone_columns } from '../../constants/constants';
import { collapseSideMenu, updateTestScenarioName } from '../../redux/common/common.slice';

import { getRowClassName, getSelectedRowKeys, getSelectedTestCasesOfScenario } from './utils';
import PayaptTable from '../../shared/components/Table';
import { useGetTestScenariosQuery, useUpdateActiveStatusMutation } from '../../redux/testScenarios/testScenarios.api';
import WrapperComponent from '../../shared/components/wrapperComponent';
import XTwoWayLabelSwitch from '../../shared/components/XTwoWayLabelSwitch';
import XSwitch from '../../shared/components/XSwitch';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';


function TestCaseMessages(props) {
    const { testType: qpTestType } = useParams();
    const dispatch = useDispatch();
    const Snackbar = useContext(SnackbarContext);
    const pageSize = useSelector((state) => state?.common?.pageSize);
    const { customerId } = useSelector((state) => state?.common);
    const { customer } = useContext(DropdownValueContext);
    const { userName, userRole } = useSelector((state) => state?.common?.userDetails);

    const {
        selectedTestScenarios,
        setSelectedScenarios,
        setTestcaseDetailsVisibility,
        setSelectedTestcase,
        searchTerm,
        // customer,
        messageTypePayload,
    } = props;
    console.log('customerrrr', customer);

    let columns;
    if (userRole?.name === 'Super Admin') {
        columns = standalone_SuperAdmin_columns;
    } else if (userRole?.name === 'Admin' || userRole?.name === 'Tester') {
        columns = standalone_columns;
    }
    // const columns = standalone_columns;
    const initialPageOptions = {
        pageNo: 0,
        pageSize,
        customerMessageType: messageTypePayload,
        sortBy: 'customerTestScenarioName',
        customerId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
        sortDir: 'asc',

    }
    const [selectedTestScenario, setSelectedTestScenario] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pageOptions, setPageOptions] = useState({ ...initialPageOptions });
    const [updateActiveStatus] = useUpdateActiveStatusMutation();
    const { data, isLoading, isFetching, isSuccess, isError, refetch } = useGetTestScenariosQuery({ ...pageOptions }, {
        skip: qpTestType === ""
    });
    React.useEffect(() => {
        setPageOptions(initialPageOptions);
        const xPageOptions = {
            ...initialPageOptions,
            // customerMessageType: 'Pacs.008'
            // customerMessageType: qpTestType
        }
        setPageOptions(xPageOptions);
    }, [qpTestType])
    React.useEffect(() => {
        setPageOptions((prevSt) => ({ ...prevSt, searchBoxInput: searchTerm }));
        if (searchTerm === "") {
            setPageOptions(initialPageOptions)
        }
    }, [searchTerm])

    useEffect(() => {
        if (customer  && messageTypePayload) {
            setPageOptions(initialPageOptions)
            // refetch(); 
        }
        // setPageOptions(initialPageOptions)
        // refetch();
    }, [customer, messageTypePayload])

    useEffect(() => {
        if (userRole?.name === 'Admin' || userRole?.name === 'Tester') {
            setPageOptions(initialPageOptions) 
            refetch();
        }
    },[])

    const openReviewModal = (visible, rec) => {
        if (!visible) {
            setSelectedTestScenario(null);
            refetch();
        } else {
            setSelectedTestScenario(rec);
            // console.log("dataCLick",rec.customerDirection);
            dispatch(updateTestScenarioName(rec.customerDirection))
        }
        setIsModalVisible(visible);
    };
    const setTestcaseDetailsVisible = (visible) => {
        dispatch(collapseSideMenu(visible))
        setTestcaseDetailsVisibility(visible);
    };
    const onTestcaseClick = (rec) => {
        setSelectedTestcase(rec);
        setTestcaseDetailsVisible(true);
    };
    const setSelectedTestCasesToRun = (keys, scenarioKey, isAllTestcasesSelected) => {
        console.log("keys",keys);
        const loData = { ...selectedTestScenarios };
        const selectedTC = _.find(data?.content, d => d?.customerTestScenarioId === scenarioKey);
        loData[scenarioKey] = {
            isAllTestcasesSelected,
            selectedKeys: [...keys],
            totalTescaseCount: selectedTC?.totalCount || 0
        }; console.log('loData', loData);
        setSelectedScenarios(loData);
    };
    // const setSelectedTestCasesToRun = (keys, scenarioKey, isAllTestcasesSelected) => {
    //     console.log("keys", keys);
    //     const loData = { ...selectedTestScenarios };
    //     const selectedTC = _.find(data?.content, d => d?.customerTestScenarioId === scenarioKey);
        
    //     // Check if messageId is null in the customerTestDataId array
    //     const isMessageIdNull = keys.some(
    //         key => key.customerTestDataId.some(data => data.messageId === null)
    //     );
    
    //     if (data?.preReqSelection === true && isMessageIdNull) {
    //         Snackbar.displayMsg("Please Select the Pre-requisite MessageId",ALERT_SEVERITY.ERROR)
    //         return; // Stop further execution if messageId is null
    //     }
    
    //     loData[scenarioKey] = {
    //         isAllTestcasesSelected,
    //         selectedKeys: [...keys],
    //         totalTescaseCount: selectedTC?.totalCount || 0
    //     };
    //     console.log('loData', loData);
    //     setSelectedScenarios(loData);
    // };
    
    const updatePageAndGetData = (yPageOptions) => {
        setPageOptions((prevState) => ({
            ...prevState,
            ...yPageOptions
        }));
    };
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            // console.log("selectedRows",selectedRows[0]?.customerTestScenarioName);
            dispatch(updateTestScenarioName(selectedRows[0]?.customerTestScenarioName))
            const loData = { ...selectedTestScenarios };
            const selectedKeys = Object.keys(selectedTestScenarios);
            const onlyScenarios = _.filter(selectedKeys, k => loData[k]?.isAllTestcasesSelected)
            const removedKeys = _.difference(onlyScenarios, selectedRowKeys);
            _.map(removedKeys, (k) => delete loData[k]);
            _.map(selectedRowKeys, (k) => {
                const selectedTC = _.find(data?.content, d => d?.customerTestScenarioId === k);
                loData[k] = {
                    isAllTestcasesSelected: true,
                    selectedKeys: [],
                    totalTescaseCount: selectedTC?.totalCount || 0
                };
            });
            setSelectedScenarios(loData);
        },
        getCheckboxProps: (record) => ({
            className: "checkbox-red",
            disabled: !record.reviewed,
        }),
    };

    const payload = {}
    const [testScenario, setTestScenario] = useState();

    useEffect(() => {
        if (data) {
            setTestScenario(data?.content)
            // dispatch(updateTestScenarioName(testScenario?.customerDirection))
        }
    }, [data])
    // console.log("dtaaaaa",testScenario?.customerDirection);
    const [payloadData, setPayloadData] = useState([]);
    // console.log("payloadData",payloadData);
    const handleActiveInactive = async (isActive, customerTestScenarioId) => {
        try {
            
            const updateTestScenario = testScenario?.map(testScen =>
                testScen.customerTestScenarioId === customerTestScenarioId ? { ...testScen, active: isActive } : testScen
            );
            setTestScenario(updateTestScenario);

            const existingPayloadIndex = payloadData.findIndex(item => item.customerTestScenarioId === customerTestScenarioId);
            if (existingPayloadIndex !== -1) {
                payloadData[existingPayloadIndex].active = isActive;
            } else {
                payloadData.push({
                    customerTestScenarioId: customerTestScenarioId,
                    active: isActive,
                });
            }

            const payload = [{
                customerTestScenarioId: customerTestScenarioId,
                active: isActive,
            }]

            await updateActiveStatus(payload).unwrap().then((res) => {
                console.log("RESSUS", res);
                if (res?.status === "success") {
                    Snackbar.displayMsg("Test Cases Status Updated Successfully",ALERT_SEVERITY.SUCCESS)
                } else {
                   Snackbar.displayMsg("Test Cases Failed to Update",ALERT_SEVERITY.ERROR) 
                }
            })
        } catch (error) {
            console.error('Error updating active status:', error);
        }
    };
    // console.log("PAYLOADDATA",payloadData);

    columns[6].render = (data, rec) => (
        <a onClick={() => openReviewModal(true, rec)}>
            <span>{data ? <EyeIconReviewed /> : <EyeIcon />}</span>
            <span className={data ? "review-column-checked" : "review-column"}>
                {data ? "Reviewed" : "Click to Review"}
            </span>
        </a>
    );
    {
        (userRole?.name === "Super Admin" ?
            columns[7].render = (data, rec) => (
                <span>
                    <XSwitch
                        // primary={"Active"} secondary={"InActive"} 
                        checked={rec?.active}
                        onChange={(newActiveStatus) => handleActiveInactive(!rec?.active, rec.customerTestScenarioId)}
                        sx={{
                            // Define your styles here
                            '& .MuiSwitch-track': {
                                backgroundColor: rec.active ? '#009966 !important' : '#ACACAC !important',
                                opacity: 1,
                            },
                        }}
                    // onChange={handleActiveInactive}
                    />
                </span>
            ) : (<></>)
        )
    }


    return (
        <>
            <Box my={2}>
                <WrapperComponent isFetching={isFetching} isError={isError} isSuccess={isSuccess}>
                    <PayaptTable
                        size="small"
                        rowClassName={(record, index) => getRowClassName(index, record, selectedTestScenario)}
                        className="standalone_table"
                        columns={columns}
                        rowSelection={{
                            ...rowSelection,
                            checkStrictly: false,
                            selectedRowKeys: getSelectedRowKeys(selectedTestScenarios),
                            preserveSelectedRowKeys: true,
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                // onClick: (event) => setTestcaseDetailsVisibility(false), 
                                onClick: (event) => setTestcaseDetailsVisible(false)
                            };
                        }}
                        scroll={{ y: "50vh" }}
                        data={!_.isEmpty(testScenario) ? testScenario : []}
                        loading={isLoading}
                        rowKey={(rec) => rec.customerTestScenarioId}
                        onChange={(pagination, filters, sorter) =>
                            updatePageAndGetData({
                                pageNo: pagination.current - 1,
                                pageSize,
                                sortBy: sorter?.field || pageOptions.sortBy,
                                sortDir: getSortDirection(sorter.order) || pageOptions.sortDir,
                                searchText: '',
                            })
                        }
                        pagination={{
                            pageSize,
                            current: !_.isEmpty(data) ? data.pageNo + 1 : 1,
                            total: !_.isEmpty(data) ? data.totalElements : 0,
                            showPrevNextJumpers: true,
                            showTitle: true,
                            hideOnSinglePage: true,
                            size: "small",
                        }}
                        expandable={{
                            expandRowByClick: false,
                            expandedRowKeys: [selectedTestScenario?.customerTestScenarioId],
                            onExpand: (isExpanded, rec) => {
                                // setTestcaseDetailsVisibility(false);
                                setTestcaseDetailsVisible(false)
                                if (isExpanded) {
                                    setSelectedTestScenario(rec);
                                    return;
                                }
                                setSelectedTestScenario(null);
                            },
                            expandedRowRender: (record) => (
                                <TestcaseTable
                                    setIsModalVisible={setIsModalVisible}
                                    onTestcaseClick={onTestcaseClick}
                                    testCaseScenarioId={record.customerTestScenarioId}
                                    pageSize={pageSize}
                                    isAllTestcasesSelected={_.get(
                                        selectedTestScenarios[record.customerTestScenarioId],
                                        "isAllTestcasesSelected",
                                        false
                                    )}
                                    setSelectedTestCasesToRun={setSelectedTestCasesToRun}
                                    selectedTestCases={getSelectedTestCasesOfScenario(record.customerTestScenarioId, selectedTestScenarios)}
                                    disabledSelection={!record.reviewed}
                                    searchTerm={searchTerm}
                                />
                            ),
                        }}
                    />
                </WrapperComponent>
            </Box>

            {isModalVisible && <Modal
                onCancel={() => openReviewModal(false)}
                open={isModalVisible}
                footer={[]}
                width="80%"
                closeIcon={
                    <CancelOutlined
                        sx={{ color: "#001A11", backgroundColor: "white" }}
                    />
                }
                style={{ width: "80%", height: "40%", borderRadius: "20px" }}
                destroyOnClose
            >
                <ReviewTestCases selectedTestScenario={selectedTestScenario} testCaseScenarioId={selectedTestScenario?.customerTestScenarioId} onClose={() => openReviewModal(false)} messageTypePayload={messageTypePayload} />
            </Modal>}
        </>

    );
}

export default TestCaseMessages;