import { Radio, Space, message } from 'antd';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { onboardingScreen, participationTypeOptions } from '../../constants/constants';
import { InfoCircleFilled, LeftCircleFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux';

import MUIButton from '../../shared/components/MUIButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { updateCurrentStep, updateParticipationType } from '../../redux/customerOnBoarding/customerOnBoarding.slice';
import { useGetParticipationTypeQuery, useSaveParticipationTypeMutation } from '../../redux/customerOnBoarding/customerOnBoarding.api';
import { updateOnboardingCurrentStatus } from '../../redux/common/common.slice';
import { useNavigate } from 'react-router-dom';
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { Box } from '@mui/material';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import PayaptLoader from "../../shared/components/Spinner";
import { useGetCustomerDataByIdMutation } from '../../redux/customers/customer.api';


const ChooseParticipationType = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Snackbar = useContext(SnackbarContext);
    const data = useGetParticipationTypeQuery();
    const customerId = useSelector((state) => state?.common?.customerId);
    const { participationType } = useSelector((state) => state?.common?.customerOnBoarding);
    const {userRole} = useSelector((state) => state?.common?.userDetails)
    const [ selectedParticipationType, setSelectedParticipationType ] = useState({});
    // console.log("selectedParticipationType",selectedParticipationType);
    const { isDashboard } = useSelector((state) => state.common);
    const [getCustomerDataById, { data: customerAccountList }] = useGetCustomerDataByIdMutation();

    // console.log("customerAccountList",customerAccountList);

    const payloadCustomer = {
        customerId: customerId
    }

    React.useEffect(() => {
        if (customerId) {
            getCustomerDataById(payloadCustomer);
        }
    }, [customerId])
    
    useEffect(() => {
        if(!_.isEmpty(customerAccountList)){
            setSelectedParticipationType({
                participation_type_id: customerAccountList?.participationType,
                participation_type_name: customerAccountList?.participationTypeName
            })
        }
        dispatch(updateOnboardingCurrentStatus({participationType: {
            participationTypeId: customerAccountList?.participationType,
            participationTypeName: customerAccountList?.participationTypeName,
            isConfigured: false
        }}))
    }, [customerAccountList]);
    const [updateParticipationType, { isLoading }] = useSaveParticipationTypeMutation();
    var payload = { 
       participationTypeId : selectedParticipationType?.participation_type_id,
       customerId : customerId
    }
    console.log('selectedParticipationType',selectedParticipationType);
    const saveParticipationType = async () => {
        await updateParticipationType(payload).unwrap().then((res) => {
            if (res.status) {
                message.success(res?.status)
            }
        })
        dispatch(updateOnboardingCurrentStatus({participationType: {
            participationTypeId: customerAccountList?.participationType,
            participationTypeName: customerAccountList?.participationTypeName,
            isConfigured: false
        }}))
        navigate(`/customer/onboarding?screen=${onboardingScreen.CONFIGURE_PARTICIPATION_TYPE}`)
    }

    const saveSettings = async () => {
        try{
        await updateParticipationType(payload).unwrap().then((res) => {
            console.log("RESSSS",res);
            if (res.message) {
                Snackbar.displayMsg(res?.message, ALERT_SEVERITY.SUCCESS);
            }
            else{
                Snackbar.displayMsg("Participation Type Failed",ALERT_SEVERITY.ERROR)
            }
        })
       await dispatch(updateOnboardingCurrentStatus({participationType: {
            participationTypeId: customerAccountList?.participationType,
            participationTypeName: customerAccountList?.participationTypeName,
            isConfigured: false
        }}))
        navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`)
    }
    catch(e){
        console.log("e",e);
    }
    }
    console.log('selectedParticipationType',selectedParticipationType);
    return (
        
        <div className='onboarding-step-container'>
            <div className='onboarding-step-items-container'>
            <Box mx={3}>
                {isDashboard ?
                <span className='onboarding-step-title header1'>Select Participation Type&nbsp;&nbsp;<InfoCircleFilled twoToneColor='#ACACAC' className='step-info-icon' />
                </span>
                :
                <span className='onboarding-step-title header1'>Selected Participation Type&nbsp;&nbsp;
                {/* <InfoCircleFilled twoToneColor='#ACACAC' className='step-info-icon' /> */}
                </span>
                }
                <span className='onboarding-step-description header2-italic'>About available participation type details goes here</span>
                <div className='onboarding-step-items'>
                    <Radio.Group>
                        <Space direction='vertical' className='participation-value-container'>
                            {
                                _.map(data.data, sp => (
                                    <div key={sp.participation_type_id} onClick={() => setSelectedParticipationType(sp)}>
                                        <input 
                                            type='radio' 
                                            value={sp.participation_type_id} 
                                            className='participation-radio-item' 
                                            id={sp.participation_type_id} 
                                            checked={ selectedParticipationType?.participation_type_id  === sp.participation_type_id}
                                            disabled={userRole?.name !== 'Super Admin'} 
                                            />
                                        <label className={selectedParticipationType?.participation_type_id === sp.participation_type_id ? 'input-radio-label-checked' : 'input-radio-label'} for={sp.participation_type_id}>{sp.participation_type_name}</label>
                                    </div>
                                ))
                            }
                        </Space>
                    </Radio.Group>
                </div>
                {/* <div className='footer-buttons'> */}
                    {isDashboard? 
                    <>
                    <MUIButton
                        startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                        sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                        color="inherit"
                        title="Back"
                        onClick={() => navigate(`/customer/onboarding?screen=${onboardingScreen.CHOOSE_SUBSCRIPTION}`)}
                    />
                    <MUIButton
                        startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                        title="Confirm"
                        onClick={() => saveParticipationType()}
                    /> 
                    </>
                    : 
                    
                     (userRole?.name === 'Admin' || userRole?.name === 'Tester') ?
                     <>
                    <MUIButton
                        startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                        sx={{ backgroundColor: '#F0F2F5', color: '#808080',}}
                        color="inherit"
                        title="Back"
                        onClick={() => navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)}
                    />
                    </>

                    :
                    <>
                    <MUIButton
                        startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                        sx={{ backgroundColor: '#F0F2F5', color: '#808080',}}
                        color="inherit"
                        title="Back"
                        onClick={() => navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`)}
                    />
                    <MUIButton
                        startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                        title="Save"
                        onClick={() => saveSettings()}
                        sx={{mx:2}}
                    /> 
                    </>
                    }
                     
                {/* </div> */}
                </Box>
            </div>
            { isLoading && <PayaptLoader/>}
        </div>
    )
}

export default ChooseParticipationType;
