import { Box, InputAdornment, Typography } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

import PayaptTextField from '../../shared/components/TextField';
import { INPUT_HEIGHT } from '../../shared/dims';
import { ExpandIcon, ExternalLinkIcon } from '../../assets/base64/images';
// import { useGetAllNonTestcasesOfBatchQuery } from '../../redux/batchFiles/batchFile.api';
import PayaptLoader from '../../shared/components/Spinner';
import BatchBoxTitle from '../BatchFiles/BatchBoxTitle';
import DraggableTestcase from '../BatchFiles/draggableTestcase';
import { useGetAllNonTestcasesOfBatchesQuery } from '../../redux/testRuns/BatchExecution.api';


const styles = {
    container: {
        display: "flex",
        flexDirection: "column"
    },
    boxContainer: {
        border: '1px solid rgba(0,0,0, 0.1)',
        p: 2,
        height: '55vh',
        overflowY: 'auto'
    },
    searchContainer: {
        width: "100%",
    },
    contentContainer: {
        // p: 2
        mt: 1,
        maxHeight: "50vh",
        overflowY: "auto"
    },
    cardStyle: {
        width: '100%',
        display:"flex",
        alignItems:"flex-start",
        px: 2,
        backgroundColor: "rgba(0,0,0, 0.05)",
        my: 2,
        py: 2,
        borderRadius: '4px'
    },
    inputSx: {
        '.MuiOutlinedInput-notchedOutline': {
            borderRadius: '4px'
        },
        '.MuiInputBase-root': {
            height: INPUT_HEIGHT
        }
    }
}

function TestCaseAddition(props) {
    const { selectedBatch, openBatchTestcaseDetails,customer } = props;
    const [searchTerm, setSearchTerm] = React.useState("");
    // const [data, setData] = React.useState(DATA);
    const handleOnSearchChange = (xValue) => {
        setSearchTerm(xValue);
        
    }
    const getPayload = () => {
        const options = {
            batchId: selectedBatch?.customerBatchId,
            customerId:customer
        }
        if(searchTerm){
            options.testCaseName = searchTerm;
        }
        return options;
    }
    const { data, isFetching } = useGetAllNonTestcasesOfBatchesQuery(
        { ...getPayload() }, {
            skip: _.isEmpty(selectedBatch?.customerBatchId) // this will skip the fetchCall when testCaseScenarioId value is empty
          }
    )
    console.log('thirdData',data);
    return (
        <Box sx={styles.container}>
            <BatchBoxTitle title={`Test Cases to Add (${_.size(data)})`} />
            <Box sx={styles.boxContainer}>
                <Box sx={styles.searchContainer}>
                    <PayaptTextField
                        fullWidth
                        sx={styles.inputSx}
                        placeholder="Search here ..."
                        value={searchTerm}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                        onChange={(event) => handleOnSearchChange(event.target.value)} />
                </Box>
             {data?.length !== 0 && data !== undefined ?
                (<Box sx={styles.contentContainer}>
                    {isFetching && <PayaptLoader />}
                    {data?.map((xRes, xIdx) => (
                        <DraggableTestcase
                        isDraggable = {true}
                        title = {xRes?.testCaseName}
                        className="without-sharp"
                        index = {xIdx}
                        item = {{...xRes, batchId:selectedBatch?.customerBatchId}}
                        showExpandIcon = {false}
                        actions = {[
                            {
                                icon: <ExternalLinkIcon />,
                                onClick: openBatchTestcaseDetails
                            }
                        ]}
                    />
                    ))}
                </Box>) : _.isEmpty(selectedBatch?.customerBatchId) ? "SELECT ANY BATCH FILE" : 'NO DATA'
               }
            </Box>
        </Box>
    );
}


export default TestCaseAddition;