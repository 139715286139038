import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import React, { useCallback } from 'react';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import { FORM_TYPES } from '../constants/formTypes';
import { greyBorderColor, primaryColor } from '../constants/constants';
import DateFromToPicker from '../../components/Dashboard/DateRangePicker';
import { DatePicker } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;

const styles = {
    checkBox: {
        color: greyBorderColor,
        [`&.${checkboxClasses.checked}`]: {
            color: primaryColor,
        },
    },
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '50%',
            },
        },
    },
    select: {
        minWidth: 250,
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: primaryColor,
        },
        '&.Mui-focused .MuiInputLabel': {
            color: 'orange'
        }
    },
    inputLabel: {
        color: `${greyBorderColor} !important`
    }
}
function RenderCondFormType(props) {
    // props
    const {
        value = '',
        type = '',
        label = '',
        catName = '',
        name = '',
        onChange = () => { },
        dropdownOptions = []
    } = props;
    console.log("CHECKEDVA", value);
    // constants
    const id = `asd-formInput-${name}`;

    const onChangeDateFrom = (event) => {
        onChange(event, name, catName);
    }

    // methods

    const handleDropdownOnChange =
        (val) => {
            const selectedOp =
                dropdownOptions?.find((yEl) => yEl['value'] === val) || val;
            if (value === selectedOp?.value) {
                onChange(null, name, catName,true);
            } else {
                onChange(selectedOp?.value, name, catName,true);
            }
            typeof value === 'string' ? value.split(',') : value
        }
        const handleDropdownOnChangee =
        (val) => {
            const selectedOp =
                dropdownOptions?.find((yEl) => yEl['value'] === val) || val;
            if (value === selectedOp?.value) {
                onChange(null, name, catName,false);
            } else {
                onChange(selectedOp?.value, name, catName,false);
            }
            // typeof value === 'string' ? value.split(',') : value
        }
    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };
    // conditional return statements
    if (type === FORM_TYPES.ADD_CHECK_BOX) {
        return (
            <FormControlLabel id={id} label={label} control={<Checkbox
                onChange={(event) => onChange(event.target.checked, name, catName)}
                sx={styles.checkBox}
                checkedIcon={<CheckBoxOutlinedIcon />}
                checked={!!value}
            // checked={!!value} onChange={(event) => onChange(event.target.checked, name, catName)}
            />} />
        )
    }
    if (type === FORM_TYPES.ADD_DROP_DOWN) {
        return (
            <FormControl>
                {label && (<InputLabel sx={styles.inputLabel} id={id}>{label}</InputLabel>)}
                <Select
                    id={id}
                    labelId={`label-${id}`}
                    variant="outlined"
                    label={label}
                    value={value || [value]}
                    displayEmpty
                    multiple
                    // onSelect={(event) => handleDropdownOnChange(event, name, catName)}
                    MenuProps={styles.menuProps}
                    sx={styles.select}
                >
                    {dropdownOptions?.map((xEl, k) => (
                        <MenuItem key={`opt-${k}`} value={xEl.value}
                            onClick={(event) => handleDropdownOnChange(xEl.value, name, catName,true)}
                        >
                            {xEl.label}
                        </MenuItem>
                    ))}
                    {(
                        <MenuItem key='clear' onClick={(event) => handleDropdownOnChange('')} value={''}>{label}</MenuItem>
                    )}
                </Select>
            </FormControl>

        )
    }
    if (type === FORM_TYPES.ADDED_DROP_DOWNN) {
        return (
            <FormControl>
                {label && (<InputLabel sx={styles.inputLabel} id={id}>{label}</InputLabel>)}
                <Select
                    id={id}
                    labelId={`label-${id}`}
                    variant="outlined"
                    label={label}
                    value={value}
                    displayEmpty
                    // multiple
                    // onSelect={(event) => handleDropdownOnChange(event, name, catName)}
                    MenuProps={styles.menuProps}
                    sx={styles.select}
                >
                    {dropdownOptions?.map((xEl, k) => (
                        <MenuItem key={`opt-${k}`} value={xEl.value}
                            onClick={(event) => handleDropdownOnChangee(xEl.value, name, catName,false)}
                        >
                            {xEl.label}
                        </MenuItem>
                    ))}
                    {/* {(
                        <MenuItem key='clear' onClick={(event) => handleDropdownOnChange('')} value={''}>{label}</MenuItem>
                    )} */}
                </Select>
            </FormControl>

        )
    }
    if (type === FORM_TYPES.ADD_DATE_PICKER) {
        return (
            <FormControl sx={{ width: '79%' }}>
                {label && (<InputLabel sx={styles.inputLabel} id={id}>{label}</InputLabel>)}
                <DateFromToPicker onChangeDateFrom={onChangeDateFrom} id={id} label={label} disabledDate={disabledDate}></DateFromToPicker>
                {/* <RangePicker  size='large' 
                    format="DD-MM-YYYY"
                    placeholder={["From date", "To date"]}
                    className={true? 'createDateRangePicker' : ''}
                    onChange={(e) => onChangeDateFrom(e)}
                    style={{height:'56px',borderRadius:4}}
                    /> */}
            </FormControl>

        )
    }

    if (type === FORM_TYPES.ADD_RADIO_BUTTON) {
        return (
            <FormControl>
                <RadioGroup
                    id={id}
                    value={value}
                    aria-label="payment-method"
                    onChange={(event) => onChange(event.target.value, name, catName)}
                    row
                >
                    {dropdownOptions?.map(option => (
                        <FormControlLabel
                            name="payment-method"
                            id={id}
                            control={<Radio />}
                            label={option?.label}
                            value={option?.value}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
    
        )
        }

    return null;
}

export default RenderCondFormType;
