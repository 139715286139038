import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import SettingCard from './SettingCard';
import "./settingStyles.css";
import { CARD_DATA } from './Constants';
import TestCaseHeader from '../testCaseHeader';
import { useSelector } from 'react-redux';
import { useCardData } from './Constants';
import { APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { EditIcon, ViewIcon } from "../../assets/base64/images";
import { InfoCircleFilled } from '@ant-design/icons';
import { useLocation } from "react-router-dom";
import { useGetCustomerDataByIdMutation } from '../../redux/customers/customer.api';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '2%',
    marginRight: '2%',
    marginTop: '2%',
    // width: '96%'
  },
  title: {
      fontWeight: '600', fontSize: '1.2rem', pr: 1
  },
  title1: {
      fontWeight: '600', fontSize: '1rem', pr: 1,pt: 0.4
  },
    subTitle: {
      fontSize: '0.85rem', fontStyle: 'italic'
    }
}

const ManageSettings = (props) => {
  
  const  {participationType} = useSelector(state => state?.common?.customerOnBoarding);
  console.log("participationTypeSetting",participationType);
  const { subscriptionPlan } = useSelector((state) => state?.common?.customerOnBoarding);
  const { userName , userRole} = useSelector((state) => state?.common?.userDetails);
  const { customerId} = useSelector((state) => state?.common);
  const {customerTypeId} = useSelector((state) => state?.common);
  console.log("customerTypeId",customerTypeId);
  const {customerOrganizationName} = useSelector((state) => state?.common)
  const location = useLocation();

  const [getCustomerDataById, { data: customerAccountList }] = useGetCustomerDataByIdMutation();

    console.log("customerAccountList",customerAccountList);

    const payloadCustomer = {
        customerId: customerId
    }

    React.useEffect(() => {
      if (customerId) {
          getCustomerDataById(payloadCustomer);
      }
  }, [customerId])

   const CARD_DATA = [
    {
      title: "Account Info",
      linkText: "Click Here To View Info",
      urlPath: userRole.name == 'Super Admin'? `${APP_ROUTE_KEYS.CUSTOMERS.ACCOUNT_INFO_TAB}` : `${APP_ROUTE_KEYS.SETTINGS.ACCOUNT_INFO_SETTINGS}`,
      iconType: <EditIcon />,
    },
    // {
    //   title: "Subscription Plan",
    //   subTitle: "",
    //   urlPath: userRole.name == 'Super Admin'? `${APP_ROUTE_KEYS.CUSTOMERS.CHOOSE_PLAN_SETTINGS}` : `${APP_ROUTE_KEYS.SETTINGS.CHOOSE_PLAN}`,
    //   iconType: <ViewIcon />,
    // },
    // {
    //   title: "Organization Configuration",
    //   // subTitle: "",
    //   linkText: "Click Here To View Info",
    //   urlPath: userRole.name == 'Super Admin'? `${APP_ROUTE_KEYS.CUSTOMERS.ORGANIZ_DETAILS_SETTINGS}` : `${APP_ROUTE_KEYS.SETTINGS.ORGANIZ_DETAILS}`,
    //   iconType: <EditIcon />,
    // },
    {
      title: "Participation Type",
      subTitle: "",
      urlPath:userRole.name == 'Super Admin'? `${APP_ROUTE_KEYS.CUSTOMERS.CHOOSE_TYPE_SETTINGS}` : `${APP_ROUTE_KEYS.SETTINGS.CHOOSE_TYPE}`,
      iconType: <EditIcon />,
    },
    {
      title: "Participation Type Configuration",
      linkText: "Click Here To View Info",
      urlPath:userRole.name == 'Super Admin'?  `${APP_ROUTE_KEYS.CUSTOMERS.CONFIG_TYPE_SETTINGS}` : `${APP_ROUTE_KEYS.SETTINGS.CONFIG_TYPE}` ,
      iconType: <EditIcon />,
    },
    // {
    //   title: "Connectivity Options",
    //   linkText: "Click Here To View Info",
    //   urlPath:userRole.name == 'Super Admin'?  `${APP_ROUTE_KEYS.CUSTOMERS.CONNECTIVITY_SETTINGS}` : `${APP_ROUTE_KEYS.SETTINGS.CONNECTIVITY}` ,
    //   iconType: <EditIcon />,
    // },

    {
      title: "Configuration Parameters",
      linkText: "Click Here To View Info",
      urlPath:userRole.name == 'Super Admin'?  `${APP_ROUTE_KEYS.CUSTOMERS.CONFIG_PARAMS_SETTINGS}` : `${APP_ROUTE_KEYS.SETTINGS.CONFIG_PARAMS}` , 
      // APP_ROUTE_KEYS.CUSTOMERS.SIM_DATA_SETTINGS
      iconType: <EditIcon />,
      // showEOD:true
    },
    
    // {
    //   title: "Simulator Test Data",
    //   linkText: "Click Here To View Info",
    //   urlPath:userRole.name == 'Super Admin'?  `${APP_ROUTE_KEYS.CUSTOMERS.TEST_DATA_SETTINGS}` : `${APP_ROUTE_KEYS.SETTINGS.TEST_DATA}` ,
    //   iconType: <EditIcon />,
    // },
    // {
    //   title: "Configuration Parameters",
    //   linkText: "Click Here To View Info",
    //   urlPath:userRole.name == 'Super Admin'?  `${APP_ROUTE_KEYS.CUSTOMERS.EOD_PROCESS_SETTINGS}` : `${APP_ROUTE_KEYS.SETTINGS.EOD_PROCESS}` , 
    //   // APP_ROUTE_KEYS.CUSTOMERS.SIM_DATA_SETTINGS
    //   iconType: <EditIcon />,
    //   // showEOD:true
    // },
    // {
    //   title: "Test Data for Response Messages",
    //   linkText: "Click Here To View Info",
    //   urlPath:userRole.name == 'Super Admin'?  `${APP_ROUTE_KEYS.CUSTOMERS.STIMULATOR_TEST_DATA_NEW_TABLE_SETTINGS}` : `${APP_ROUTE_KEYS.SETTINGS.STIMULATOR_TEST_DATA_NEW_TABLE}` , 
    //   // APP_ROUTE_KEYS.CUSTOMERS.SIM_DATA_SETTINGS
    //   iconType: <EditIcon />,
    //   // showEOD:true
    // },
    // {1 == customerTypeName && },
    // {
    //   title: "Respondent Banks",
    //   linkText: "Click Here To View Info",
    //   urlPath:userRole.name == 'Super Admin'?  `${APP_ROUTE_KEYS.CUSTOMERS.RESPONDENT_BANK}` : `${APP_ROUTE_KEYS.SETTINGS.RESPONDENT_BANK}`, 
    //   // APP_ROUTE_KEYS.CUSTOMERS.SIM_DATA_SETTINGS
    //   iconType: <EditIcon />,
    //   // showEOD:true
    // },
    // {
    //   title: "Settlement Account Transaction",
    //   linkText: "Click Here To View Info",
    //   urlPath:userRole.name == 'Super Admin'?  `${APP_ROUTE_KEYS.CUSTOMERS.SETTLEMENT_ACCOUNT}` : `${APP_ROUTE_KEYS.SETTINGS.SETTLEMENT_ACCOUNT}` , 
    //   // APP_ROUTE_KEYS.CUSTOMERS.SIM_DATA_SETTINGS
    //   iconType: <EditIcon />,
    //   // showEOD:true
    // },
    
  ];

  const isSuperAdmin = userRole.name === 'Super Admin';

  if (1 === customerTypeId) {
    CARD_DATA.push(
        {
            title: "Respondent Banks",
            linkText: "Click Here To View Info",
            // urlPath:  `${APP_ROUTE_KEYS.SETTINGS.RESPONDENT_BANK}`,
            urlPath: userRole.name === 'Super Admin' ? `${APP_ROUTE_KEYS.CUSTOMERS.RESPONDENT_BANK}` : `${APP_ROUTE_KEYS.SETTINGS.RESPONDENT_BANK}`,
            iconType: <EditIcon />,
        },
        {
            title: "Settlement Account Transaction",
            linkText: "Click Here To View Info",
            // urlPath:  `${APP_ROUTE_KEYS.SETTINGS.SETTLEMENT_ACCOUNT}`,
            urlPath: userRole.name === 'Super Admin' ? `${APP_ROUTE_KEYS.CUSTOMERS.SETTLEMENT_ACCOUNT}` : `${APP_ROUTE_KEYS.SETTINGS.SETTLEMENT_ACCOUNT}`,
            iconType: <EditIcon />,
        }
    );
}

  const modifiedCardData = CARD_DATA.map((card) => {
    if (card.title === "Subscription Plan") {
    
      return {
        ...card,
        subTitle: subscriptionPlan && subscriptionPlan.subscriptionPlanName
        ? subscriptionPlan.subscriptionPlanName
        : "Select a plan",
      };
    } else if (card.title === "Participation Type") {
      return {
        ...card,
        subTitle: participationType && customerAccountList?.participationTypeName
        ? customerAccountList?.participationTypeName
        : "Select Participation Type",
      };
    } else {
      return card;
    }
  });
  return (
    <Box sx={{overflowY: 'auto',height: `calc(100vh - 100px)`,}}>
      {userRole.name == 'Super Admin'? (
        <Box sx={styles.container}>
        <Box display={"flex"} flexDirection="row" sx = {{ width:'100%'}}>
          <Box display={"flex"} flexDirection="row"  sx={{ width:'73%',textAlign:'left'}}>
            <Typography sx={styles.title}>{"Manage Settings"}</Typography>
            {/* <InfoCircleFilled className='step-info-icon' /> */}
          </Box>
          <Box display={"flex"} flexDirection="row" sx = {{ width:'27%'}}>
            <Typography sx={styles.title1}>{customerId}</Typography>
            
          </Box>
        </Box>
        <Box display={"flex"} flexDirection="row" sx = {{ width:'100%'}}>
          <Box display={"flex"} flexDirection="row"  sx={{ width:'73%',textAlign:'left'}}>
          <Typography sx={styles.subTitle}>{"You can manage your profile and onboarding information here..."}</Typography>
          </Box>
          <Box display={"flex"} flexDirection="row" sx = {{ width:'27%'}}>
            <Typography sx={styles.title1}>{customerOrganizationName}</Typography>
            
          </Box>
        </Box>
      </Box>
      ):(
      <TestCaseHeader
        title="Manage Settings"
        subtitle="You can manage your profile and onboarding information here..."
      />  
      )
      }
      <Grid container sx={{px:1}} >
        {
          modifiedCardData?.map((xEl) => {
            return (
              <SettingCard
                title={xEl.title}
                linkText={xEl.linkText}
                urlPath={xEl.urlPath}
                subTitle={xEl.subTitle}
                iconType={xEl.iconType}
                showEOD={xEl.showEOD}
              />
            )
          })
        }

      </Grid>


    </Box>
  )
}

export default ManageSettings;
