import { Box, Typography } from '@mui/material';
import React from 'react';
import TestCaseHeader from '../testCaseHeader';
import { useState } from 'react';
import PayaptTable from '../../shared/components/Table';
import MUIButton from '../../shared/components/MUIButton';
import { LeftCircleFilled } from '@ant-design/icons';
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { retrievalRequest_columns } from '../../constants/constants';
import { useGetAdmi006TransactionListMutation, useGetRetreivalRequestResponseMutation, useSaveMessageIdAdmi006Mutation } from '../../redux/Reports/reports.api';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { convertToLocalTime } from '../../shared/utils';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { useContext } from 'react';
import { Input, Modal, Pagination } from 'antd';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';

const RetrievalRequest = () => {
  const { TextArea } = Input;
  const navigate = useNavigate();
  const location = useLocation();
  const Snackbar = useContext(SnackbarContext);
  const { customer } = useContext(DropdownValueContext);
  const pageSize = useSelector((state) => state?.common?.pageSize);
  const initialPageOptions = location.state ? location.state.paylod : null;

  // console.log("initialPageOptions", initialPageOptions);
  // const [customer, selectedCustomer] = useState();
  const [searchTerm, setSearchTerm] = useState(null);
  const [pageOptions, setPageOptions] = useState({ ...initialPageOptions });
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [isModalVisible, setModalVisibility] = useState(false);
  const [masterRtn, setMasterRtn] = useState();
  const [value, setValue] = useState([]);

  // console.log("selectedRowKeys", selectedRowKeys);
  const columns = retrievalRequest_columns;
  const [getAdmi006TransactionList, { data, isLoading }] = useGetAdmi006TransactionListMutation();
  const [saveMessageIdAdmi006] = useSaveMessageIdAdmi006Mutation();
  const [retreivalRequestResponse] = useGetRetreivalRequestResponseMutation();
  const payloadRtn = {
    masterrRtn: masterRtn
  }

  // const value = [
  //   {


  //     response: "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?><p:Document xmlns:p=\"urn:iso:std:iso:20022:tech:xsd:admi.006.001.01\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:admi.006.001.01 FedNow_Service_Release_1_RetrievalRequest_admi_006_001_01_20221208_1726_iso15enriched_.xsd \">\n  <h:AppHdr xmlns:h=\"urn:iso:std:iso:20022:tech:xsd:head.001.001.02\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:head.001.001.02 BusinessApplicationHeader_head_001_001_02_20210617.xsd\">\n    <h:Fr>\n      <h:FIId>\n        <h:FinInstnId>\n\t  <h:ClrSysMmbId>\n\t    <h:MmbId>021040078</h:MmbId>\n          </h:ClrSysMmbId>\n        </h:FinInstnId>\n      </h:FIId>\n    </h:Fr>\n    <h:To>\n      <h:FIId>\n        <h:FinInstnId>\n\t  <h:ClrSysMmbId>\n\t    <h:MmbId>021150706</h:MmbId>\n\t  </h:ClrSysMmbId>\n        </h:FinInstnId>\n      </h:FIId>\n    </h:To>\n    <h:BizMsgIdr>20231211618132625skkHtrzBU32157359</h:BizMsgIdr>\n    <h:MsgDefIdr>admi.006.001.01</h:MsgDefIdr>\n    <h:MktPrctc>\n      <h:Regy>www2.swift.com/mystandards/#/group/Federal_Reserve_Financial_Services/FedNow_Service</h:Regy>\n      <h:Id>frb.fednow.01</h:Id>\n    </h:MktPrctc>\n    <h:CreDt>2023-12-11T12:09:40</h:CreDt>\n  </h:AppHdr>\n  <p:RsndReq>\n    <p:MsgHdr>\n      <p:MsgId>20231211021040078lAGyjaQFi76499871</p:MsgId>\n      <p:CreDtTm>2023-12-11T12:09:40</p:CreDtTm>\n    </p:MsgHdr>\n    <p:RsndSchCrit>\n      <p:BizDt/>\n      <p:OrgnlMsgNmId>Pacs.004</p:OrgnlMsgNmId>\n      <p:FileRef>20231209021040078452826946489051520</p:FileRef>\n      <p:Rcpt>\n        <p:Id>\n          <p:PrtryId>\n            <p:Id>021040078</p:Id>\n            <p:Issr>NA</p:Issr>\n          </p:PrtryId>\n        </p:Id>\n      </p:Rcpt>\n    </p:RsndSchCrit></p:RsndReq>\n</p:Document>",
  //     admi006RetreivalId: "6576fc05bdc88a2a606ec806"


  //   },
  //   {
  //     response: "HII",
  //     admi006RetreivalId: "6576fc05bdc88a2a606ec806"
  //   },
  //   {
  //     response: "Hello",
  //     admi006RetreivalId: "6576fc05bdc88a2a606ec806"
  //   }
  // ];
  // console.log("payloadRtn", value);
  useEffect(() => {
    if (isModalVisible !== false && masterRtn !== undefined) {
      retreivalRequestResponse(payloadRtn).then(res => {
        // console.log("Res2",res);
        setValue(res?.data?.data)
      })
    }
  }, [masterRtn, isModalVisible])

  const onCustomerSelection = (event) => {
    selectedCustomer(event);
  };

  const onBack = () => {
    navigate(`${APP_ROUTE_KEYS.REPORTS.RETRIEVAL_REQUEST_ADMI006}`)
  };

  const onPageChange = (yPageOptions) => {
    const xPayload = {
      ...pageOptions,
      ...yPageOptions,
    }
    setPageOptions(xPayload);
    getAdmi006TransactionList(xPayload)
  }



  const rowSelection = {
    // selectedRowKeys,
    onChange: (selectedRowKeys, seclectedRows) => {
      // console.log("selectedRowKeys", selectedRowKeys);
      if (selectedRowKeys?.length > 50) {
        Snackbar.displayMsg("Exceeded maximum selection limit (50)", ALERT_SEVERITY.ERROR)
      }
      setSelectedRowKeys(selectedRowKeys)
    },

  };

  // useEffect(() => {
  //   getAdmi006TransactionList(initialPageOptions);
  // }, [])

  useEffect(() => {
    setSearchTerm(null);
    // setPageOptions(initialPageOptions);
    const xPageOptions = {
      ...initialPageOptions
    }
    setPageOptions(xPageOptions)
  }, [])
  React.useEffect(() => {
    setPageOptions((prevSt) => ({ ...prevSt, search: searchTerm ? null : null }));
    if (searchTerm === "" ? null : null) {
      setPageOptions(initialPageOptions)
    }
    getAdmi006TransactionList(pageOptions);
    console.log("pageOptions", pageOptions);
  }, [searchTerm]);
  columns[6].render = (data, rec) => (

    <>
      <span style={{ textTransform: 'lowercase' }}>{data != null ? convertToLocalTime(data) : ""}</span>
    </>
  )

  const handleSearchOnChange = (xValue) => {
    setSearchTerm(xValue);
    setPageOptions({ ...initialPageOptions, search: xValue ? xValue : null });
  };
  const messageIdPayload = {
    messageId: selectedRowKeys
  };

  const handleRetrieval = async (isVisible) => {
    await saveMessageIdAdmi006(messageIdPayload).then(res => {
      setMasterRtn(res?.data?.data);
      console.log("Res", res);
      if (res?.data?.status === "success") {
        Snackbar.displayMsg("Retrieve Message Executed Successfully", ALERT_SEVERITY.SUCCESS);

        setTimeout(() => {
          setModalVisibility(isVisible);
        }, 5000);
      } else {
        Snackbar.displayMsg("Failed to Retrieve Message", ALERT_SEVERITY.ERROR);
      }
    })
    // setModalVisibility(isVisible);
  }
  const showButton = selectedRowKeys?.length > 0;

  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 1; // Define the number of items per page
  // Assuming your data is in this format or shape
  // const data = []; // Replace this with your actual data array

  // Calculate the start and end indexes of items to display based on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const xmlData = value[startIndex]?.response;

  const downloadFile = () => {
    if (value?.length > 0) {
      const element = document.createElement("a");
      const file = new Blob([xmlData], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = "Retrieve Message Xml"
      document.body.appendChild(element);
      element.click();
    } else {
      Snackbar.displayMsg("No data to download",ALERT_SEVERITY.ERROR);
    }

  };
  return (
    <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
      <TestCaseHeader
        title="Admi.006 - Retrieval Request"
        subtitle={`${!_.isEmpty(data) ? data?.data[0]?.messageType : ""} Message`}
        showTestCustomer={{
          show: false,
          // onChange: onCustomerSelection,
          // value: customer
        }}
        showSearch={{
          show: true,
          value: searchTerm,
          onChange: handleSearchOnChange
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', }} className='standalone_table'>
        <Typography sx={{ textAlign: 'left', fontSize: 14 }}>Total message Id selected: <b>{selectedRowKeys?.length}</b></Typography>
      </Box>
      <Box my={2} style={{ textTransform: 'none' }} width={"100%"} px={"0%"}>
        <PayaptTable
          size={"small"}
          className={"standalone_table"}
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
          columns={columns}
          loading={isLoading}
          data={!_.isEmpty(data) ? data?.data : []}
          onChange={(pagination) =>
            onPageChange(
              {
                page: pagination.current - 1,
                search: null,
                pageSize
              }
            )
          }
          pagination={{
            pageSize,
            current: !_.isEmpty(data) ? data.totalNumber + 1 : 1,
            total: !_.isEmpty(data) ? data.totalElements : 0,
            size: 'small',
            hideOnSinglePage: true,
            showPrevNextJumpers: true,

          }}
          rowKey={(rec) => rec.settlementAccountTransactionId}
          rowSelection={{
            ...rowSelection,
            selectedRowKeys: selectedRowKeys,
            preserveSelectedRowKeys: true
          }}
        />

      </Box>
      <Box sx={{ display: "flex", }} px={"2%"} mt={4}>
        <MUIButton
          startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
          title="Back"
          sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
          color="inherit"
          onClick={onBack}
        />&nbsp;&nbsp;&nbsp;
        {showButton && (
          <MUIButton
            startIcon={<CheckCircleIcon style={{ fontSize: 18, }} />}
            title="Retrieve Message"
            onClick={handleRetrieval}
          />)}
      </Box>

      <Modal
        onCancel={() => setModalVisibility(false)}
        open={isModalVisible} footer={[]}
        closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
        destroyOnClose
        width={"70%"}
        maskClosable={false}
      >
        <Box>
          <TestCaseHeader
            title={"Retrieve Message Xml"}
            showFileUpload={{
              show: true,
              customIcon: <FileDownloadIcon />,
              onClick: () => downloadFile()
            }}
          />
          <div style={{ marginTop: 5 }}>
            <TextArea
              className="xml-txt-area"
              rows={15}
              readOnly
              style={{ height: '100%' }}
              // value={value && value?.map((xml) => xml.response)}
              value={value?.length > 0 ? value[startIndex]?.response : 'NO DATA'} // Display the response based on the current page
            />

            <div style={{ marginTop: 4, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={value?.length}
                onChange={changePage}
              />
            </div>
          </div>

        </Box>

      </Modal>
    </Box>
  )
}

export default RetrievalRequest;
