import React, { useEffect, useState } from 'react';
import TestCaseHeader from '../testCaseHeader';
import { Box, Grid, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PayaptTable from '../../shared/components/Table';
import { testcase_columns } from '../../constants/constants';
import { getSelectedRowKeys } from '../../containers/TestCases/utils';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { EyeIcon } from '../../constants/menuIcons';
import _ from 'lodash';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Modal } from 'antd';
import ReviewTestCases from '../messages/reviewTestCases';
import CloudUploadSharpIcon from '@mui/icons-material/CloudUploadSharp';
import DirectionDropdown from './DirectionDropdown';
import { useGetMasterTestCaseListQuery, useMasterTestCaseListMutation, useSelectAPICallForMasterMutation } from '../../redux/uploadTestcase/uploadTestcase.api';
import { collapseSideMenu, updateSelectedMenuKeys } from '../../redux/common/common.slice';
import ViewTestCaseDetails from '../messages/testcaseDetails';
import ViewTestCaseMaster from '../messages/testcaseMaster';
import { useLazyGetTestRunXMLDataByLogKeyIdQuery } from '../../redux/testRuns/testRuns.api';
import PayaptLoader from '../../shared/components/Spinner';
import { generateCurrentEpochTimeValue } from '../../shared/utils';


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    height: `calc(100vh - 100ox)`,
  }
};
const TestCaseRepo = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pageSize = useSelector((state) => state?.common?.pageSize);
  const { customerId } = useSelector((state) => state?.common);
  const [customer, selectedCustomer] = useState();
  const [xSelectedRows, setXselectedRows] = useState([]);
  const [inboundOrOutbound, setInboundOrOutboundValue] = useState();
  const [directionValue, setDirectionValue] = useState();
  const [selectedTestCases, setSelectedTestCases] = useState();
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isXmlShow, setIsXmlShow] = useState(true);
  const [isTestcaseDetailsVisible, setTestcaseDetailsVisibility] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [isBatchMode, setIsBatchMode] = useState(true);
  const [selectedVersions, setSelectedVersions] = useState({});
  const [selectedTestcase, setSelectedTestcase] = useState({});
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const initialPageOptions = {
    pageNo: 0,
    pageSize,
    direction: directionValue,
    messageType: customer,
    customerId: customerId,
    // testCaseName:0,
    // customerMessageType: messageTypePayload, 
    // sortBy: 'customerTestScenarioName',
    // customerId : userRole?.name == 'Super Admin'? customer : 0,
    // sortDir: 'asc', 
  }
  const [pageOptions, setPageOptions] = useState({ ...initialPageOptions });
  const columns = testcase_columns;

  const [getMasterTestCaseList, { data, isLoading }] = useMasterTestCaseListMutation({ ...pageOptions },
    //   {
    //   skip: testCaseName ===""
    // }
  );
  const [selectAPICallForMaster] = useSelectAPICallForMasterMutation();
  
  console.log("DATAMASTER", data);

  const condGridValue = (isTestcaseDetailsVisible) ? 7 : 12;
  const condGridDirection = (isTestcaseDetailsVisible) ? "row" : "column";

  useEffect(() => {
    if (customer && directionValue) {
      getMasterTestCaseList(initialPageOptions)
      setPageOptions(initialPageOptions)
    }

  }, [customer, directionValue])
  const handleModalVisible = (isVisible, rec) => {
    if (isVisible) {
      setSelectedRecord(rec)
    } else {
      setSelectedRecord({})
    }
    setModalVisibility(isVisible)
  }

  const setTestDataDetailsVisible = (visible) => {
    dispatch(collapseSideMenu(visible))
    setTestcaseDetailsVisibility(visible);
    if (isBatchMode) {
      setSelectedTestcase({})
    }
  };
  const onTestCaseClick = (testcase) => {
    setTestDataDetailsVisible(false)
    dispatch(collapseSideMenu(true))
    setSelectedTestcase({ ...testcase, testCaseId: testcase.testCaseId })
    setTestcaseDetailsVisibility(true);
  }

  columns[1].render = (data, rec, rowIndex) => {
    return (
      <span onClick={() => onTestCaseClick(rec, rowIndex)}>{rec.testCaseName}</span>
    )
  }
  // columns[5].render = (data,rec) => (
  //   <span>{"2023-08-03"}</span>
  // )
  columns[6].render = (data, rec) => (
    <a onClick={() => handleModalVisible(true, rec, 'viewData')} style={{ justifyContent: 'center', display: 'flex' }}>
      <span>{<EyeIcon />}</span>
    </a>

  )

  const onPageChange = (options) => {
    const xPayload = {
      direction: directionValue,
      messageType: customer,
      pageNo: options.page,
      pageSize: pageSize,
      customerId: customerId,
    }
    console.log("cPage", xPayload);
    getMasterTestCaseList(xPayload)
  }

  const [selectedMaster, setSelectedMaster] = useState();
  const [messageType, setFileName] = useState();
  
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log("🚀 ~ file: testcaseTable.js:140 ~ onChange:", { selectedRowKeys, selectedRows });
      const mappedData = _.map(selectedRows, d => ({
        triggerMessagetype: d.triggerMessagetype,
        inboundOrOutbound: d.inboundOrOutbound,
        // testCaseId: d.testCaseId,
        // testCaseVerionId: d.testCaseVerionId,
        // testCaseId: _.get(selectedVersions[d.testCaseId], 'value', null)
      }));
      // console.log("mappedData", mappedData);
      setSelectedMaster(selectedRowKeys)
      setFileName(mappedData)
      //     setSelectedTestCasesToRun(mappedData, testCaseScenarioId, false);
    },
    getCheckboxProps: (record) => ({
      className: "checkbox-red",
    }),
  };

  const onTestCaseCustomerSelection = (event) => {
    selectedCustomer(event);
  }
  // const onChangeInboundOrOutbound = (e) => {
  //   setInboundOrOutboundValue(e)
  // }
  const onChangeDirectionDropdown = (value) => {
    setDirectionValue(value)
  }

  const handleUploadClick = () => {
    const xPayload = {
      selectedMenuKey: 'Upload Test Cases',
    }
    dispatch(updateSelectedMenuKeys(xPayload));
    navigate(`${APP_ROUTE_KEYS.TEST_CASES_REPO.UPLOAD_TESTCASE}`)
  }
  const payload = {
    scenarioId: null,
    testCaseId: selectedMaster,
    testCaseType: "SA",
  }
  // console.log("PAYYYYYYYYYYYY", payload);
  const handleDownloadClick = async () => {
    await selectAPICallForMaster(payload).unwrap().then((res) => {
      // console.log("RESSSS", res);
      if (res.mfTestCaseAPI === true) {
        handleDownload1Click()
      }
      if (res.mfTestDataAPI === true) {
        handleDownload2Click()
      } if (res.saAPI === true) {
        handleDownload3Click()
      }
    })
  }
  const handleDownload3Click = async () => {
    setIsDownloadLoading(true)
    const token = await localStorage.getItem('jwttoken');
    await fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/TestCaseUpload/downloadTestCasesForSAForMaster`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          scenarioId: null,
          testCaseId: selectedMaster,
          testCaseType: "SA"
        })
      })

      .then((response) => response.blob())
      .then((blob) => {
        // console.log('blob', JSON.stringify(blob));
        // console.log('response', JSON.stringify(blob));
        // Create blob link to download  
        setIsDownloadLoading(false)
        const url = window.URL.createObjectURL(new Blob([blob]))
        // const filename = url.split('/').pop()
        // console.log("filename:",filename);
        const link = document.createElement('a');
        const fileName = messageType[0]?.triggerMessagetype + "_" + messageType[0]?.inboundOrOutbound + "_" + "Master SA" + "_" + generateCurrentEpochTimeValue()
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        // Append to html link element page    
        document.body.appendChild(link); // Start download    
        link.click();// Clean up and remove the link    
        link.parentNode.removeChild(link);
      })
  }

  const handleDownload2Click = async () => {
    setIsDownloadLoading(true)
    const token = await localStorage.getItem('jwttoken');
    await fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/TestCaseUpload/downloadCustomerTestDataforMaster`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          scenarioId: null,
          testCaseId: selectedMaster,
          testCaseType: "MF"
        })
      })
      .then((response) => response.blob())
      .then((blob) => {

        // Create blob link to download  
        setIsDownloadLoading(false)
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a');
        link.href = url;
        const fileName = messageType[0]?.triggerMessagetype + "_" + messageType[0]?.inboundOrOutbound + "_" + "MF Master TestData" + "_" + generateCurrentEpochTimeValue()
        link.setAttribute('download', `${fileName}.xlsx`);
        // Append to html link element page    
        document.body.appendChild(link); // Start download    
        link.click();// Clean up and remove the link    
        link.parentNode.removeChild(link);
      })

  }

  const handleDownload1Click = async () => {
    setIsDownloadLoading(true)
    const token = await localStorage.getItem('jwttoken');
    try {
      await fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/TestCaseUpload/downloadCustomerTestCaseforMaster`,

        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            scenarioId: null,
            testCaseId: selectedMaster,
            testCaseType: "MF"
          })
        })
        .then((response) => response.blob())
        .then((blob) => {
          console.log('blob', JSON.stringify(blob));
          // Create blob link to download 
          setIsDownloadLoading(false)
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement('a');
          link.href = url;
          const fileName = messageType[0]?.triggerMessagetype + "_" + messageType[0]?.inboundOrOutbound + "_" + "MF Master TestCase" + "_" + generateCurrentEpochTimeValue()
          link.setAttribute('download', `${fileName}.xlsx`);
          // Append to html link element page    
          document.body.appendChild(link); // Start download    
          link.click();// Clean up and remove the link    
          link.parentNode.removeChild(link);
        })
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", overflowY: "auto", height: `calc(100vh - 100px)` }}>
      {isDownloadLoading && <PayaptLoader sx={{ position: "absolute", left: "55%" }} />}
      <Grid container direction={condGridDirection}>
        <Grid item xs={condGridValue} md={condGridValue}>
          <TestCaseHeader
            title="Master Test Cases"
            subtitle="Details of test cases for customer credit transfer"

            showDirectionDropdown={{
              show: true,
              onChange: onChangeDirectionDropdown,
              value: directionValue
            }}
            showMessageDropdown={{
              show: true,
              onChange: onTestCaseCustomerSelection,
              value: customer
            }}
            showFileUpload={{
              show: true,
              onClick: handleUploadClick,
              disabled: _.isEmpty(selectedMaster),
              customIcon: <UploadIcon />
            }}
            showFileDownload={{
              show: true,
              onClick: handleDownloadClick,
              disabled: _.isEmpty(selectedMaster),
              customIcon: <FileDownloadIcon />
            }}
          />
          {/* <Box sx={{display:"flex",paddingLeft:10,marginTop:-3,color:"#808080",}}> 
        <CloudUploadSharpIcon style={{fontSize:30}}/>
      </Box> */}

          <Box my={2}>
            <PayaptTable
              size="small"
              rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
              data={directionValue === '' || customer === '' ? [] : !_.isEmpty(data) ? data.content : []}
              loading={isLoading}
              className="standalone_table"
              columns={columns}
              rowSelection={{
                ...rowSelection,
                selectedRowKeys: selectedTestCases,
                preserveSelectedRowKeys: true
              }}
              rowKey={(rec) => rec.testCaseId}
              onChange={(pagination) =>
                onPageChange(
                  {
                    page: pagination.current - 1,
                    customerId
                  })
              }
              pagination={{
                pageSize,
                current: !_.isEmpty(data) ? data.pageNo + 1 : 1,
                total: directionValue === '' || customer === '' ? 0 : !_.isEmpty(data) ? data.totalElements : 0,
                size: 'small',
                hideOnSinglePage: true,
                showPrevNextJumpers: true,
                showTitle: true,
              }}
            />
          </Box>
        </Grid>
        {isTestcaseDetailsVisible && (
          <Grid item xs={5}>
            <ViewTestCaseMaster
              testcase={selectedTestcase}
              onClose={setTestDataDetailsVisible}
              isBatchMode={isBatchMode}
            />
          </Grid>
        )}
      </Grid>
      <Modal
        onCancel={() => handleModalVisible(false)}
        open={isModalVisible} footer={[]}
        width={'90%'}
        style={{ width: '90%', height: '70%' }}
        closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
        destroyOnClose
      >
        {<ReviewTestCases show={isXmlShow} onChange={setIsXmlShow}
          testcaseDetails={{
            testCaseId: selectedRecord?.testCaseId, testCaseName: selectedRecord?.testCaseCode,
            testCaseVersionId: selectedRecord?.testCaseVerionId
          }}
          showSingleTestcase={true}
          masterTestCase={true}
          onClose={() => handleModalVisible(false)}
          selectedTestScenario={{
            // masterXMLPath: selectedRecord?.customerXmlFilePath,
            customerTestScenarioName: selectedRecord?.inboundOrOutbound,
            customerTestDescription: selectedRecord?.testCaseName,
            testCaseId: selectedRecord?.testCaseId
          }}
        />}
      </Modal>
    </Box>
  )
}

export default TestCaseRepo;
