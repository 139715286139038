import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import TestCaseHeader from '../testCaseHeader';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import { useNotificationMessageMutation, useNotificationXmlMutation } from '../../redux/Notifications/notifications.api';
import { Input, Modal } from 'antd';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSelector } from 'react-redux';



const Notification = () => {
    const { TextArea } = Input;
    const { customer } = useContext(DropdownValueContext);
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const { customerId } = useSelector((state) => state?.common);
    const [notificationsMessage] = useNotificationMessageMutation();
    const [notificationXml] = useNotificationXmlMutation();
    const [viewAll, setViewAll] = useState(false); // State to toggle view mode
    const [message, setMessage] = useState();
    console.log("message",message);
    const [isModalVisible, setModalVisibility] = useState(false);
    const [value, setValue] = useState([]);
    const payloadMsg = {
        customerId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer
    }

    useEffect(() => {
        if (customer) {
            notificationsMessage(payloadMsg).then(res => {
                // console.log("Res33", res?.data);
                setMessage(res?.data)
            });
        }
    }, [customer]);

    
    const handleButtonClick = async(messageId,isVisible) => {
        // console.log("Button clicked for messageId:", messageId,isVisible);
        // setModalVisibility(isVisible);
        const xmlPayload = {
            messageId:messageId
        };
        await notificationXml(xmlPayload).then(res => {
            // console.log("XML",res);
            setValue(res?.data?.responseXml);
            setTimeout(() => {
                setModalVisibility(isVisible);
            }, 500);
        })
        
    };

    const handleViewAllClick = () => {
        setViewAll(!viewAll); // Toggle the viewAll state
    };

    const displayedNotifications = viewAll ? message : message?.slice(0, 3);

    const onCancel = () => {
        setModalVisibility(false)
    };
    
    const downloadFile = () => {
        const element = document.createElement("a");
        const file = new Blob([value], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `Camt.054 Account Credit/Debit Notifications`
        document.body.appendChild(element);
        element.click();
        setModalVisibility(true)
    };
    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="Notifications"
                showTestCustomer={{
                    show: true,
                    // Assuming 'onTestCaseCustomerSelection' and 'customer' are defined and properly managed elsewhere in your code.
                    // onChange: onTestCaseCustomerSelection,
                    // value: customer
                }}
            />
             {/* <Button variant="contained" color="primary" onClick={handleDownload}>
                Download File
            </Button> */}
            {message?.length > 0 ? (
            <Box mx={2} mt={2}>
                {/* By using 'Grid container' without 'Grid item' wrapping each card, 
                  we make all cards stack vertically */}
                {displayedNotifications?.map((notification) => (
                    <Card key={notification.messageId} sx={{ mb: 2 }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body1" gutterBottom sx={{textAlign:"justify"}}>
                                {notification.notificationMessage}
                            </Typography>
                            <Button variant="text" sx={{ alignSelf: 'end',textTransform:'none',textDecoration: 'underline' }}
                              onClick={() => handleButtonClick(notification.messageId,true)}
                            >View Xml</Button>
                        </CardContent>
                    </Card>
                ))}
                {/* {message.length > 0 && } */}
                 {message?.length > 4 && ( // Only show the "View All" button if there are more than 4 notifications   
                 <Box>
                    <Button variant="text" sx={{ mt: 3, textTransform:'none',textDecoration:"underline",display:"flex" }} onClick={handleViewAllClick}>
                        {viewAll ? 'View Less' : 'View All'}
                    </Button>
                </Box>
                )} 
            </Box>
            ) 
             :
            (
                <Box mx={2} mt={2}>
                <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography variant="body1" gutterBottom>
                                No Notifications
                            </Typography>
                        </CardContent>
                    </Card>
                    </Box>
            )
            } 
            <Modal
                onCancel={onCancel}
                open={isModalVisible} footer={[]}
                closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
                destroyOnClose
                width={"70%"}
                maskClosable={false}
            >
                <Box>
                    <TestCaseHeader 
                    title={"Camt.054 Account Credit/Debit Notifications"}

                    showFileUpload={{
                        show: value ? true : false,
                        customIcon: <FileDownloadIcon />,
                        onClick: () => downloadFile()
                    }}
                    // showRefresh={{
                    //     show: false,
                    //     onClick: handleRefreshButton
                    // }}
                    />
                    {/* <WrapperComponent isSuccess={isSuccess} isFetching={isLoading}> */}
                    <div style={{ marginTop: 5 }}>

                        <TextArea
                            className="xml-txt-area"
                            rows={15}
                            readOnly
                            style={{ height: '100%' }}
                        // value={value.length > 0 ? value[startIndex]?.response : 'IN PROGRESS'} // Display the response based on the current page
                            value={value === null ? "NO DATA" : value}
                        />

                    </div>

                </Box>

            </Modal>
        </Box>
    );
}

export default Notification;
