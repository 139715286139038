import React, { useEffect, useState } from "react";
import "../../components/styles/loginStyles.css";
import "../../components/styles/forgotStyles.css";
import CustomerOnboardingTitle from "../customerOnboardingTitle";
import { Grid, TextField } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate, useLocation } from "react-router-dom";
import PayaptTextField from "../../shared/components/TextField";
import MUIButton from "../../shared/components/MUIButton";
import { useRequestOtpMutation } from "../../redux/registration/registration.api";
import { SnackbarContext } from "../../shared/context/SnackbarContext.Context";
import { useContext } from "react";
import { ALERT_SEVERITY } from "../../shared/constants/constants";

const ForgotEmail = () => {
  const navigate = useNavigate();
  const snackbar = useContext(SnackbarContext);
  const [email, setEmail] = useState();
  const location = useLocation();
  const [requestOtp, { data, isError, isSuccess }] = useRequestOtpMutation();

  const handleCreateOTPNavigation = async (xRes) => {
    const response = await xRes
    console.log("RESPONSE", response);
    if (response?.data?.message == "OTP sent to email") {
      snackbar.displayMsg(response?.data?.message, ALERT_SEVERITY.SUCCESS);
      navigate('/requestotp', { state: { email, fromPath: location.pathname } });
    }

    if (response?.error?.status === 404) {
      snackbar.displayMsg(response?.error?.data?.message, ALERT_SEVERITY.ERROR)

      // console.log("404", xRes?.error.status);
    }
  }

  const requestOTP = async () => {
    if (email) {
      const payload = {
        email: email
      }
      const resOTP = await requestOtp(payload);
      handleCreateOTPNavigation(resOTP);
    }
  }

  const onChangeEmailHandler = (event) => {
    setEmail(event.target.value)
  }

  return (
    <div className="container">
      <CustomerOnboardingTitle />
      <div>
        <h6 className="id-forgot">Forgot Password</h6>
        <p className="para">
          Give your registered email address for password reset instructions
        </p>
        <Grid style={{ marginTop: 30 }}>
          <Grid container spacing={1} style={{ textAlign: "left" }}>
            <Grid


              item
              className="login-grid-item"
            >
              <span style={{ textAlign: "left", color: "#001A11", fontWeight: 400, fontSize: '16px' }}>Email</span>
              <br />
              <PayaptTextField
                margin="dense"
                value={email}
                placeholder="Enter Your Email"
                className="signin-text-box"
                onChange={onChangeEmailHandler}
              />
            </Grid>
          </Grid>
          <br />
          <Grid

            style={{ justifyContent: "flex-end", display: "flex" }}
            className='login-grid-item'
          >
            <MUIButton
              startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
              title="Request OTP"
              onClick={requestOTP}
              className="mui-button-requestotp"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ForgotEmail;
