import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Grid } from "@mui/material";
import React, { useContext, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Dropdown from '../../shared/components/Dropdown';
import MUIButton from "../../shared/components/MUIButton";
import PayaptTextField from "../../shared/components/TextField";
import '../styles/accountInfoStyles.css';
import TestCaseHeader from '../testCaseHeader';
import { accoutInfoValidationSchema } from './accountInfoValidation';
import 'react-phone-input-2/lib/style.css';
import { useUpdateCustomerAccountDetailsMutation, useGetCustomerTypeListQuery } from '../../redux/accountInfo/accountInfo.api';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { CustomerMsg } from '../../shared/constants/AlertMessages';
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { LeftCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useGetCustomerDataByIdMutation, useGetCustomerStatusQuery, useGetCorrespondBankListQuery } from '../../redux/customers/customer.api';
import PayaptLoader from '../../shared/components/Spinner';
import AddCheckbox from '../../shared/components/forms/AddCheckbox';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from "moment";

const AccountInfoData = () => {
    const navigate = useNavigate()
    const resolver = yupResolver(accoutInfoValidationSchema);
    const { customerId } = useSelector((state) => state?.common);
    const Snackbar = useContext(SnackbarContext);
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const [headerDetails, setHeaderDetails] = React.useState({
        title: "",
        subTitle: ""
    });
    const [customerID, setCustomerID] = React.useState({
        customerId: customerId
    });

    const [customerTypes, setCustomerTypes] = React.useState();
    const [accountStatusList, setAccountStatusList] = React.useState();
    const [correspondBankList, setCorrespondBankList] = React.useState();
    const [startValidation, setStartValidation] = React.useState(false);
    const [endValidation, setEndValidation] = React.useState(false);
    const [endDateFormate, setEndDateFormate] = useState();


    const [updateCustomerAccountDetails, { isLoading }] = useUpdateCustomerAccountDetailsMutation();
    const { data: customerTypeList } = useGetCustomerTypeListQuery();
    const [getCustomerDataById, { data: customerAccountList }] = useGetCustomerDataByIdMutation();
    const { data: accountStatusData } = useGetCustomerStatusQuery();
    const { data: correspondBankData } = useGetCorrespondBankListQuery();

    const [startDate, setStartDate] = React.useState();
    const [StartDateFormate, setStartDateFormate] = useState();
    // console.log("startDate",startDate);
    const [endDate, setEnddate] = React.useState();
    // console.log('endDate', endDate );
    const handleDropDownValue = () => {
        const customerTypeData = _.map(customerTypeList, (m, index) => ({
            id: m.customerTypeId,
            value: m.customerTypeName,
            label: m.customerTypeName
        }));
        setCustomerTypes(customerTypeData);
    }
    const fetchCustomerStatusValue = () => {
        const accountStatusType = _.map(accountStatusData, (m) => ({
            id: m.accountStatusId,
            value: m.accountStatusName,
            label: m.accountStatusName
        }));
        setAccountStatusList(accountStatusType);
    }

    const fetchCorrespondBankValue = () => {
        const correspondBankTypes = _.map(correspondBankData?.data, (m) => ({
            id: m.customerId,
            value: m.customerName,
            label: m.customerName,
        }));
        setCorrespondBankList(correspondBankTypes);
    }


    React.useEffect(() => {
        if (customerId) {
            getCustomerDataById(customerID);
        }
    }, [accountStatusData])

    React.useEffect(() => {
        handleDropDownValue();
        fetchCustomerStatusValue();
        fetchCorrespondBankValue();
    }, [accountStatusData, correspondBankData, customerTypeList]);

    const {
        control,
        register,
        setValue,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        resolver,
        defaultValues: {
            customerType: "",
            companyName: "",
            address1: "",
            address2: "",
            city: "",
            country: "",
            postalCode: "",
            email: "",
            status: "",
            // customerType: "",
            primaryRtnNumber: "",
            mobile: "",
            contactPerson: "",
            // restApiConnectivity: "",
            // mqsetUp: "",
            validityStartDate: null,
            validityEndDate: null,

        }
    });

    const defaultValues = {
        customerType: customerAccountList?.customerType,
        companyName: customerAccountList?.companyName,
        address1: customerAccountList?.address1,
        address2: customerAccountList?.address2,
        city: customerAccountList?.city,
        country: customerAccountList?.country,
        postalCode: customerAccountList?.postalCode,
        primaryRtnNumber: customerAccountList?.primaryRtnNumber,
        email: customerAccountList?.email,
        contactPerson: customerAccountList?.contactPerson,
        mobile: customerAccountList?.contactNumber,
        status: customerAccountList?.statusId,
        restApiConnectivity: customerAccountList?.restApiConnectivity === "No" ? false : true,
        mqSetup: customerAccountList?.mqSetup === "No" ? false : true,
        validityStartDate: customerAccountList?.validityStartDate,
        validityEndDate: customerAccountList?.validityEndDate,
        correspondentCustomerId: customerAccountList?.correspondentCustomerId,
    }

    const watchFieldForOrganization = watch("customerType");
    console.log("watchFieldForOrganization", watchFieldForOrganization);
    React.useEffect(() => {
        setHeaderDetails({
            title: "Organization Account Information",
            subTitle: "About updating Organization Account Information details goes here"
        })
        reset({
            ...defaultValues,
        })
        return;
    }, [customerAccountList]);

    // console.log("customerAccountList", customerAccountList);
    const handleValidate = (value) => {
        const isValid = isValidPhoneNumber(value);
        return isValid;
    }

    const handleEditNavigation = async (xRes) => {
        if (xRes?.data?.customerId) {
            Snackbar.displayMsg(CustomerMsg.UPDATE_CUSTOMER_SUCCESS, ALERT_SEVERITY.SUCCESS);
        } else {
            Snackbar.displayMsg(`${xRes?.error?.data?.message}`, ALERT_SEVERITY.ERROR);
        }
    }


    const onSubmit = async (data) => {
        data.restApiConnectivity = data.restApiConnectivity === true ? "Yes" : "No";
        data.mqSetup = data.mqSetup === true ? "Yes" : "No";
        data.customerId = customerId;
        data.customerType = +data.customerType;
        data.validityStartDate = moment.utc(new Date(data.validityStartDate).toString()).format('YYYY-MM-DDTHH:mm:ss');
        data.validityEndDate = moment.utc(new Date(data.validityEndDate).toString()).format('YYYY-MM-DDTHH:mm:ss');
        const updateResult = await updateCustomerAccountDetails(data);
        handleEditNavigation(updateResult);
        console.log("ACCDATA", data);
    }

    const onChangeStartDateFrom = (event) => {
        setStartDateFormate(event.$d)
        const startDate = event ? new Date(event).toString() : null;
        console.log("startDateInside", startDate);
        const myFromDate = moment(new Date(event).toString());
        setValue('validityStartDate', myFromDate);
        setStartDate(startDate);
        if (startDate) {
            setStartValidation(true);
        }
    }

    const [errorMessage, setErrorMessage] = useState();
    const onChangeEndDateFrom = (event) => {
        // setEndDateFormate(event.$d);
        const endDate = event ? new Date(event).toString() : null;
        const myToDate = moment(new Date(event).toString());
        setValue('validityEndDate', myToDate);

        if (!endDate) {
            // Handle the case where endDate is empty
            setErrorMessage("Validity end date is required");//   const errorMessage = "Validity end date is required";
            setEndValidation(false); // Disable validation
            console.error(errorMessage);
        } else if (customerAccountList.validityStartDate) {
            const startDateObj = new Date(customerAccountList.validityStartDate);
            const endDateObj = new Date(endDate);
            console.log("startDateObj", startDateObj);
            // Check if endDate is higher than startDate
            if (endDateObj > startDateObj) {
                setEnddate(endDate);
                setEndValidation(true);
                setErrorMessage(""); // Clear any previous error message
            } else {
                // Handle the case where endDate is not higher than startDate
                Snackbar.displayMsg("End date should be higher than the start date", ALERT_SEVERITY.ERROR)
                // setErrorMessage("End date should be higher than the start date");
                setEndValidation(false);
                // console.error(errorMessage);
            }
        } else {
            // Handle the case where StartDateFormate is not set
            //   setErrorMessage("");
            setEndValidation(false);
            Snackbar.displayMsg("Start date should be set before setting the end date", ALERT_SEVERITY.ERROR)
            //   console.error(errorMessage);
        }
    };


    //   const onChangeEndDateFrom = (event) => {
    //     setEndDateFormate(event.$d)
    //     const endDate = event ? new Date(event).toString() : null ;
    //     const myToDate = moment(new Date(event).toString());
    //     setValue('validityEndDate', myToDate);
    //     setEnddate(endDate);
    //     if(endDate){
    //         setEndValidation(true);
    //     }

    //   }

    // const [errorMessage, setErrorMessage ] = useState();

    // const onChangeEndDateFrom = (event) => {
    //     setEndDateFormate(event.$d)
    //     const endDate = event ? new Date(event).toString() : null;
    //     const myToDate = moment(new Date(event).toString());
    //     console.log("endDateinside",endDate);
    //     console.log("EVENT",myToDate);
    //     if (!endDate) {
    //       // If endDate is empty, display a "Validity End Date is required" message
    //       setEndValidation(false);
    //       setErrorMessage('Validity End Date is required');
    //     } else if (startDate && myToDate.isAfter(startDate)) {
    //       setValue('validityEndDate', myToDate);
    //       setEnddate(endDate);
    //       setEndValidation(true);
    //       setErrorMessage(''); // Clear any previous error message

    //     } else {
    //       // If endDate is not greater than startdate, display an error message
    //       setEndValidation(false);
    //       setErrorMessage('End date should be higher than the start date');
    //     }
    //   }


    return (
        <>
            <Box sx={{ overflowY: 'auto', }}>
                {/* <TestCaseHeader height: `calc(100vh - 100px)`,
                    title={headerDetails.title}
                    subtitle={headerDetails.subTitle}
                /> */}
                {isLoading && <PayaptLoader />}
                <Box sx={{ overflowY: 'auto', maxHeight: '73vh', width: '98%' }}>
                    <Grid spacing={3} container>
                        <Grid item xs={0} > </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'left', marginTop: 0 }}>
                            <Box>
                                <Grid>
                                    <span>Organization Name<font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"companyName"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("companyName")}
                                                {...field}
                                                name="companyName"
                                                placeholder='Enter Company Name'
                                                margin='dense'
                                                error={!!errors['companyName']}
                                                helperText={errors['companyName']?.message}
                                                disabled={userRole?.name !== 'Super Admin'}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid >
                                    <span >Organization Type <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"customerType"}
                                        render={({ field }) => (
                                            <Dropdown
                                                inputRef={register('customerType', { required: true })}
                                                {...field}
                                                placeholder='Select Type'
                                                margin="dense"
                                                sx={{ mt: 1 }}
                                                className="user-dropdown"
                                                style={{ width: '100%', height: '56px' }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                options={customerTypes}
                                                error={!!errors['customerType']}
                                                helperText={errors['customerType']?.message}
                                                disabled={true}
                                            />
                                        )}
                                    />
                                    {errors.customerType && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Organization Type is required</div>
                                    )}
                                </Grid>
                                <br />
                                {1 === watchFieldForOrganization &&
                                    <Grid >
                                        <span >Master Routing Number<font color='red'>*</font></span>
                                        <br />
                                        <Controller
                                            control={control}
                                            name={"primaryRtnNumber"}
                                            render={({ field }) => (
                                                <PayaptTextField
                                                    {...register("primaryRtnNumber")}
                                                    rules={{ required: 'Routing number is required', pattern: /^[a-zA-Z0-9]{9}$/ }}
                                                    {...field}
                                                    name="primaryRtnNumber"
                                                    placeholder='Enter Master Routing Number'
                                                    margin='dense'
                                                    error={!!errors['primaryRtnNumber']}
                                                    helperText={errors.primaryRtnNumber ? errors.primaryRtnNumber.message : ''}
                                                    disabled={true}
                                                />
                                            )}
                                        />
                                    </Grid>
                                }
                                {4 === watchFieldForOrganization &&
                                    <Grid >
                                        <span style={{ textAlign: 'left' }}>Correspondent Bank

                                        </span>
                                        <br />
                                        <Controller
                                            control={control}
                                            name={"correspondentCustomerId"}
                                            render={({ field }) => (
                                                <Dropdown
                                                    inputRef={register('correspondentCustomerId', { required: true })}
                                                    {...field}
                                                    placeholder='Select Correpondent Bank'
                                                    margin="dense"
                                                    sx={{ marginTop: '7px' }}
                                                    className="user-dropdown"
                                                    style={{ width: '100%', height: '56px' }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    options={correspondBankList}
                                                    error={!!errors['correspondentCustomerId']}
                                                    helperText={errors['correspondentCustomerId']?.message}
                                                    disabled={userRole?.name !== 'Super Admin'}
                                                />
                                            )}
                                        />
                                        {errors.correspondentCustomerId && (
                                            <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Correspondent Bank Type is required</div>
                                        )}
                                    </Grid>
                                    
                                }
                                
                                <Grid>
                                    <span>Email Address<font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"email"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("email")}
                                                {...field}
                                                name="email"
                                                placeholder='Enter email address'
                                                margin='dense'
                                                error={!!errors['email']}
                                                helperText={errors['email']?.message}
                                                disabled={userRole?.name !== 'Super Admin'}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid>
                                    <span style={{ textAlign: 'left' }}>Contact Number<font color='red'>*</font> </span>
                                    <br />
                                    <Controller
                                        name="mobile"
                                        control={control}
                                        rules={{
                                            validate: (value) => handleValidate(value)
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                id="mobile"
                                                name="mobile"
                                                placeholder='Enter Mobile Number'
                                                margin="dense"
                                                addInternationalOption={true}
                                                international={true}
                                                style={errors.mobile && {
                                                    border: '1px solid red'
                                                }}
                                                inputProps={{
                                                    inputRef: register('mobile'),
                                                }}
                                                disabled={userRole?.name !== 'Super Admin'}
                                            />
                                        )}
                                    />
                                    {errors["mobile"] && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Contact Number is required</div>
                                    )}
                                    <br />
                                </Grid>

                                {/* <Grid >
                                    <span >Organization Type <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"customerType"}
                                        render={({ field }) => (
                                            <Dropdown
                                                inputRef={register('customerType', { required: true })}
                                                {...field}
                                                placeholder='Select Type'
                                                margin="dense"
                                                sx={{ mt: 1 }}
                                                className="user-dropdown"
                                                style={{ width: '100%', height: '56px' }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                options={customerTypes}
                                                error={!!errors['customerType']}
                                                helperText={errors['customerType']?.message}
                                            />
                                        )}
                                    />
                                    {errors.customerType && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Organization Type is required</div>
                                    )}
                                </Grid> */}
                                <br />
                                <Grid >
                                    <span >Address1<font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"address1"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("address1")}
                                                {...field}
                                                name="address1"
                                                placeholder='Enter address1'
                                                margin='dense'
                                                error={!!errors['address1']}
                                                helperText={errors['address1']?.message}
                                                disabled={userRole?.name !== 'Super Admin'}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid >
                                    <span >Address2<font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"address2"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("address2")}
                                                {...field}
                                                name="address2"
                                                placeholder='Enter address2'
                                                margin='dense'
                                                error={!!errors['address2']}
                                                helperText={errors['address2']?.message}
                                                disabled={userRole?.name !== 'Super Admin'}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid >
                                    <span >City<font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"city"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("city")}
                                                {...field}
                                                name="city"
                                                placeholder='Enter city'
                                                margin='dense'
                                                error={!!errors['city']}
                                                helperText={errors['city']?.message}
                                                disabled={userRole?.name !== 'Super Admin'}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid >
                                    <span >Country<font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"country"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("country")}
                                                {...field}
                                                name="country"
                                                placeholder='Enter country name'
                                                margin='dense'
                                                error={!!errors['country']}
                                                helperText={errors['country']?.message}
                                                disabled={userRole?.name !== 'Super Admin'}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid >
                                    <span >Postal Code<font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"postalCode"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("postalCode")}
                                                {...field}
                                                name="postalCode"
                                                placeholder='Enter postal code'
                                                margin='dense'
                                                error={!!errors['postalCode']}
                                                helperText={errors['postalCode']?.message}
                                                disabled={userRole?.name !== 'Super Admin'}
                                            />
                                        )}
                                    />
                                </Grid>



                                <Grid>
                                    <span style={{ textAlign: 'left', lineHeight: 2.5 }}>Subscription Start Date <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"validityStartDate"}
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DatePicker
                                                    format='YYYY-MM-DD'
                                                    value={field.value ? dayjs(new Date(field.value)) : null}
                                                    selected={field.value ? dayjs(new Date(field.value)) : null}
                                                    sx={{ width: '100%' }}
                                                    inputRef={register('validityStartDate')}
                                                    name="validityStartDate"
                                                    margin="dense"
                                                    onChange={(e) => onChangeStartDateFrom(e)}
                                                    slotProps={{
                                                        textField: {
                                                            error: !startValidation && errors["validityStartDate"],
                                                        },
                                                    }}
                                                    disabled={userRole?.name !== 'Super Admin'}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                    {!startValidation && errors["validityStartDate"] && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Validity Start Date is required</div>
                                    )}
                                </Grid>
                                <br />
                                <Grid>
                                    <span style={{ textAlign: 'left', lineHeight: 2.5 }}>Subscription End Date <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        defaultValue={null}
                                        name={"validityEndDate"}
                                        render={({ field }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DatePicker
                                                    format='YYYY-MM-DD'
                                                    value={field.value ? dayjs(new Date(field.value)) : null}
                                                    selected={field.value ? dayjs(new Date(field.value)) : null}
                                                    sx={{ width: '100%' }}
                                                    inputRef={register('validityEndDate')}
                                                    name="validityEndDate"
                                                    margin="dense"
                                                    onChange={(e) => onChangeEndDateFrom(e)}
                                                    slotProps={{
                                                        textField: {
                                                            error: !endValidation && errors["validityEndDate"],
                                                        },
                                                    }}
                                                    disabled={userRole?.name !== 'Super Admin'}
                                                />
                                            </LocalizationProvider>
                                        )}

                                    />
                                    {!endValidation && errors["validityEndDate"] && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>{errorMessage}</div>
                                    )}
                                </Grid>
                                <br />
                                <Grid >
                                    <span >Contact Person<font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"contactPerson"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("contactPerson")}
                                                {...field}
                                                name="contactPerson"
                                                placeholder='Enter Contact Person'
                                                margin='dense'
                                                error={!!errors['contactPerson']}
                                                helperText={errors['contactPerson']?.message}
                                                disabled={userRole?.name !== 'Super Admin'}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid >
                                    <span >Status<font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"status"}
                                        render={({ field }) => (
                                            <Dropdown
                                                inputRef={register('status', { required: true })}
                                                {...field}
                                                placeholder='Select Status Type'
                                                margin="dense"
                                                sx={{ mt: 1 }}
                                                className="user-dropdown"
                                                style={{ width: '100%', height: '56px' }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                options={accountStatusList}
                                                error={!!errors['status']}
                                                helperText={errors['status']?.message}
                                                disabled={userRole?.name !== 'Super Admin'}
                                            />
                                        )}
                                    />
                                    {errors.status && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Status is required</div>
                                    )}
                                </Grid>
                                <br />
                                {/* <Grid ml={-2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <AddCheckbox
                                        control={control}
                                        register={register}
                                        setValue={setValue}
                                        errors={errors}
                                        name={"restApiConnectivity"}
                                        label={"REST API Connect"}
                                    />
                                    <AddCheckbox
                                        control={control}
                                        register={register}
                                        setValue={setValue}
                                        errors={errors}
                                        name={"mqSetup"}
                                        label={"MQ Status"}
                                    />
                                </Grid> */}
                                <Box my={2} sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                                {(userRole?.name === 'Admin' || userRole?.name === 'Tester') ? 
                                        <MUIButton
                                        startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                        sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                                        color="inherit"
                                        title="Back"
                                        onClick={() => userRole?.name == 'Super Admin' ? navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`) : navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)}
                                    />
                                     :
                                     <>
                                    <MUIButton
                                        startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                        sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                                        color="inherit"
                                        title="Back"
                                        onClick={() => userRole?.name == 'Super Admin' ? navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`) : navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)}
                                    />&nbsp;&nbsp;&nbsp;
                                    <MUIButton
                                        startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                        title="Save Changes"
                                        onClick={handleSubmit(onSubmit)}
                                        type="submit" />
                                    </>
                                }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Box>
            </Box >
        </>
    )
}

export default AccountInfoData;
