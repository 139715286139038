import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, FormControl, Grid, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Dropdown from '../../shared/components/Dropdown';
import MUIButton from '../../shared/components/MUIButton';
import PayaptTextField from '../../shared/components/TextField';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { customerValidationSchema } from './utils';
import TestCaseHeader from '../testCaseHeader';
import { CustomerMsg } from '../../shared/constants/AlertMessages';
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import '../styles/users.css';
import AddCheckbox from '../../shared/components/forms/AddCheckbox';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateCustomerMutation, useGetCustomerDataByIdMutation, useGetCustomerTypeListQuery, useGetCustomerStatusQuery, useAddCustomerMutation, useGetCorrespondBankListQuery } from '../../redux/customers/customer.api';
import { useGetSubscriptionPlanListQuery, useGetParticipationTypeQuery } from '../../redux/customerOnBoarding/customerOnBoarding.api';
import moment from "moment";
// import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PayaptLoader from '../../shared/components/Spinner';
import _ from 'lodash';
import { LeftCircleFilled } from '@ant-design/icons';


const EditCustomer = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const { RangePicker } = DatePicker;
    const resolver = yupResolver(customerValidationSchema);
    const [phoneNumer, setPhoneNumer] = React.useState();
    const Snackbar = useContext(SnackbarContext);
    const [error, setError] = React.useState(null);

    const [headerDetails, setHeaderDetails] = React.useState({
        title: "",
        subTitle: ""
    })
    const [customerID, setCustomerID] = React.useState({
        customerId: params.customerId
    });

    const [customerList, setCustomerList] = React.useState();
    const [correspondBankList, setCorrespondBankList] = React.useState();
    const [participationList, setParticipationList] = React.useState();
    const [subscriptionList, setSubscriptionList] = React.useState();
    const [accountStatusList, setAccountStatusList] = React.useState();
    const [startValidation, setStartValidation] = React.useState(false);
    const [endValidation, setEndValidation] = React.useState(false);
    const { data: subscriptionPlanData } = useGetSubscriptionPlanListQuery();
    const { data: customerTypeData } = useGetCustomerTypeListQuery();
    const { data: accountStatusData } = useGetCustomerStatusQuery();
    const { data: participationTypeData } = useGetParticipationTypeQuery();
    const { data: correspondBankData } = useGetCorrespondBankListQuery();

    console.log("correspondBankList", correspondBankList);


    const [updateCustomer, { isLoading }] = useUpdateCustomerMutation();
    const [addCustomer, { isLoading: addCustomerLoading }] = useAddCustomerMutation();
    const [getCustomerDataById, { data: CustomerData }] = useGetCustomerDataByIdMutation();
    console.log("CustomerData", CustomerData);
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEnddate] = React.useState();

    React.useEffect(() => {
        if (params?.customerId) {
            getCustomerDataById(customerID);
        }
    }, [])

    // const fetchSubscriptionValue = () => {
    //     const subscriptionTypes = _.map(subscriptionPlanData, (m) => ({
    //         id: m.subscriptionPlanId,
    //         value: m.subscriptionPlanName,
    //         label: m.subscriptionPlanName
    //       }));
    //       setSubscriptionList(subscriptionTypes);
    // }
    // const fetchParticipationValue = () => {
    //     const participationTypes = _.map(participationTypeData, (m) => ({
    //         id: m.participation_type_id,
    //         value: m.participation_type_name,
    //         label: m.participation_type_name
    //       }));
    //       setParticipationList(participationTypes);
    // }
    const fetchCustomerTypeValue = () => {
        const customerTypes = _.map(customerTypeData, (m) => ({
            id: m.customerTypeId,
            value: m.customerTypeName,
            label: m.customerTypeName
        }));
        setCustomerList(customerTypes);
    }
    const fetchCustomerStatusValue = () => {
        const accountStatusType = _.map(accountStatusData, (m) => ({
            id: m.accountStatusId,
            value: m.accountStatusName,
            label: m.accountStatusName
        }));
        setAccountStatusList(accountStatusType);
    }

    const fetchCorrespondBankValue = () => {
        const correspondBankTypes = _.map(correspondBankData?.data, (m) => ({
            id: m.customerId,
            value: m.customerName,
            label: m.customerName,
        }));
        setCorrespondBankList(correspondBankTypes);
    }

    React.useEffect(() => {
        // fetchSubscriptionValue();
        // fetchParticipationValue();
        fetchCustomerTypeValue();
        fetchCustomerStatusValue();
        fetchCorrespondBankValue();

    }, [customerTypeData, accountStatusData, correspondBankData]);

    const {
        control,
        register,
        setValue,
        getValues,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        resolver,
        defaultValues: {
            customerName: "",
            customerType: "",
            correspondentCustomerId: 0,
            // participationTypeId: "",
            address1: "",
            address2: "",
            city: "",
            country: "",
            postalCode: "",
            contactNumber: "",
            contactPerson: "",
            email: "",
            // subscriptionPlanId: "",
            routingNumber: "",
            registeredDate: "",
            validityStartDate: null,
            validityEndDate: null,
            accountStatus: "",
            restApiConnectivity: 0,
            mqsetUp: 0,
        }
    });

    const defaultValues = {
        customerName: CustomerData?.organizationName,
        customerType: CustomerData?.customerType,
        correspondentCustomerId: CustomerData?.correspondentCustomerId,
        // participationTypeId: CustomerData?.participationType ,
        address1: CustomerData?.address1,
        address2: CustomerData?.address2,
        city: CustomerData?.city,
        country: CustomerData?.country,
        postalCode: CustomerData?.postalCode,
        contactNumber: CustomerData?.contactNumber,
        email: CustomerData?.email,
        // subscriptionPlanId: CustomerData?.subscriptionPlan ,
        routingNumber: CustomerData?.routingNumber,
        contactPerson: CustomerData?.contactPerson,
        registeredDate: moment(CustomerData?.registeredDate).format('YYYY-MM-DD'),
        validityStartDate: CustomerData?.validityStartDate,
        validityEndDate: CustomerData?.validityEndDate,
        accountStatus: CustomerData?.status,
        restApiConnectivity: CustomerData?.restApiConnectivity === "Yes" ? true : false,
        mqsetUp: CustomerData?.mqSetup === "Yes" ? true : false,
    };

    console.log("defaultValues", defaultValues);
    const handleValidate = (value) => {
        const isValid = isValidPhoneNumber(value);
        return isValid;
    }
    const watchFieldForOrganization = watch("customerType");


    React.useEffect(() => {
        if (params?.customerId) {
            setHeaderDetails({
                title: "Edit Customer Details",
                subTitle: "You can update the detail of the customer here"
            })
            reset({
                ...defaultValues,
            });

            return;
        } else {
            setHeaderDetails({
                title: "Add Customer",
                subTitle: "You can add new Customer by filling this form"
            });
        }

    }, [CustomerData]);

    const onChangeStartDateFrom = (event) => {
        const startDate = event ? new Date(event).toString() : null;
        // console.log("startDateInsideDit",startDate);
        setStartDate(startDate);
        const myFromDate = moment(new Date(event).toString());
        setValue('validityStartDate', myFromDate);
        if (startDate) {
            setStartValidation(true);
        }
    }

    const [errorMessage, setErrorMessage] = useState();
    const onChangeEndDateFrom = (event) => {
        const endDate = event ? new Date(event).toString() : null;
        const myToDate = moment(endDate);
        
        if (!endDate) {
            // If endDate is empty, display a "Validity End Date is required" message
            setEndValidation(false);
            setErrorMessage('Validity End Date is required');
        } else if (startDate && myToDate.isAfter(startDate)) {
            setValue('validityEndDate', myToDate);
            setEnddate(endDate);
            setEndValidation(true);
            setErrorMessage(''); // Clear any previous error message
        } else {
            // If endDate is not greater than startdate, display an error message
            setEndValidation(false);
            setErrorMessage('End date should be higher than the start date');
        }
    }

    const handleSaveNavigation = async (xRes) => {
        if (xRes?.data?.customerId) {
            Snackbar.displayMsg(CustomerMsg.ADD_CUSTOMER_SUCCESS, ALERT_SEVERITY.SUCCESS);
            navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER}`);
        } else {
            Snackbar.displayMsg(`${xRes?.error?.data?.message}`, ALERT_SEVERITY.ERROR);
        }
    }

    const handleEditNavigation = async (xRes) => {
        if (xRes?.data?.customerId) {
            Snackbar.displayMsg(CustomerMsg.UPDATE_CUSTOMER_SUCCESS, ALERT_SEVERITY.SUCCESS);
            navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER}`)
        } else {
            Snackbar.displayMsg(`${xRes?.error?.data?.message}`, ALERT_SEVERITY.ERROR);
        }
    }

    const onSubmit = async (data) => {
        console.log("dataSUBMIT", data);
        data.restApiConnectivity = data.restApiConnectivity === true ? 1 : 0;
        data.mqsetUp = data.mqsetUp === true ? 1 : 0;
        data.validityStartDate = moment.utc(new Date(data.validityStartDate).toString()).format();
        data.validityEndDate = moment.utc(new Date(data.validityEndDate).toString()).format();
        data.correspondentCustomerId = +data.correspondentCustomerId;



        if (params?.customerId) {
            data.customerId = params?.customerId;
            const updateResult = await updateCustomer(data);
            handleEditNavigation(updateResult);
        }
        else {
            const saveResult = await addCustomer(data);
            handleSaveNavigation(saveResult);
        }

    }

    return (
        <>
            <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, }}>
                <TestCaseHeader
                    title={headerDetails.title}
                    subtitle={headerDetails.subTitle}
                />
                {addCustomerLoading && <PayaptLoader />}
                {isLoading && <PayaptLoader />}
                <Box sx={{ overflowY: 'auto', maxHeight: '73vh', marginTop: 3, marginRight: '2%', width: '98%' }}>
                    <Grid spacing={3} container>
                        <Grid item xs={0} > </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'left', marginTop: 3 }}>
                            <Box>
                                <Grid>
                                    <span style={{ textAlign: 'left' }}>Organization Name <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"customerName"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("customerName")}
                                                {...field}
                                                name="customerName"
                                                placeholder='Enter Customer Name'
                                                margin="dense"
                                                inputProps={{
                                                    readOnly: false,
                                                }}
                                                // className="user-text-box"
                                                error={!CustomerData?.organizationName ? (!!errors['customerName']) : ''}
                                                helperText={!CustomerData?.organizationName ? errors['customerName']?.message : ''}

                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <br /> */}
                                <Grid>
                                    <span style={{ textAlign: 'left' }}>Organization Type <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"customerType"}
                                        render={({ field }) => (
                                            <Dropdown
                                                inputRef={register('customerType', { required: true })}
                                                {...field}
                                                placeholder='Select Customer Type'
                                                margin="dense"
                                                sx={{ marginTop: '7px' }}
                                                className="user-dropdown"
                                                style={{ width: '100%', height: '56px' }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                options={customerList}
                                                error={!!errors['customerType']}
                                                helperText={errors['customerType']?.message}
                                            />
                                        )}
                                    />
                                    {errors.customerType && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Organization Type is required</div>
                                    )}
                                </Grid>

                                {4 === watchFieldForOrganization &&

                                    <Grid >
                                        <span style={{ textAlign: 'left' }}>Correspondent Bank

                                        </span>
                                        <br />
                                        <Controller
                                            control={control}
                                            name={"correspondentCustomerId"}
                                            render={({ field }) => (
                                                <Dropdown
                                                    inputRef={register('correspondentCustomerId', { required: false })}
                                                    // {...register("correspondentCustomerId")}
                                                    {...field}
                                                    placeholder='Select Correpondent Bank'
                                                    margin="dense"
                                                    sx={{ marginTop: '7px' }}
                                                    className="user-dropdown"
                                                    style={{ width: '100%', height: '56px' }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    options={correspondBankList}
                                                    error={!!errors['correspondentCustomerId']}
                                                    helperText={errors['correspondentCustomerId']?.message}
                                                />
                                            )}
                                        />
                                        {errors.correspondentCustomerId && (
                                            <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Correspondent Bank Type is required</div>
                                        )}
                                    </Grid>
                                }
                                <br />

                                {1 === watchFieldForOrganization &&
                                    <Grid>
                                        <span style={{ textAlign: 'left' }}>Master Routing Number <font color='red'>*</font></span>
                                        <br />
                                        <Controller
                                            control={control}
                                            name={"routingNumber"}
                                            render={({ field }) => (
                                                <PayaptTextField
                                                    inputRef={register('routingNumber', { required: false })}
                                                    // {...register("routingNumber")}
                                                    {...field}
                                                    name="routingNumber"
                                                    placeholder='Enter Master Routing Number'
                                                    margin="dense"
                                                    // className="user-text-box"
                                                    error={!!errors['routingNumber']}
                                                    helperText={errors['routingNumber']?.message}

                                                />
                                            )}
                                        />
                                    </Grid>
                                }



                                {/* <Grid>
                                    <span style={{ textAlign: 'left' }}>Participation type <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"participationTypeId"}
                                        render={({ field }) => (
                                            <Dropdown
                                                inputRef={register('participationTypeId', { required: true })}
                                                {...field}
                                                placeholder='Select Participation type'
                                                margin="dense"
                                                sx={{marginTop:'7px'}}
                                                className="user-dropdown"
                                                style={{ width: '100%', height: '56px' }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                options={participationList}
                                                error={!!errors['participationTypeId']}
                                                helperText={errors['participationTypeId']?.message}
                                            />
                                        )}
                                    />
                                    {errors.participationTypeId && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Participation type is required</div>
                                    )}
                                </Grid> */}
                                {/* <br /> */}
                                <Grid>
                                    <span style={{ textAlign: 'left' }}>Address1 <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"address1"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("address1")}
                                                {...field}
                                                name="address1"
                                                placeholder='Enter Address1'
                                                margin="dense"
                                                // className="user-text-box"
                                                error={!!errors['address1']}
                                                helperText={errors['address1']?.message}

                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <br /> */}
                                <Grid>
                                    <span style={{ textAlign: 'left' }}>Address2 <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"address2"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("address2")}
                                                {...field}
                                                name="address2"
                                                placeholder='Enter Address2'
                                                margin="dense"
                                                // className="user-text-box"
                                                error={!!errors['address2']}
                                                helperText={errors['address2']?.message}

                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <br /> */}
                                <Grid>
                                    <span style={{ textAlign: 'left' }}>City <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"city"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("city")}
                                                {...field}
                                                name="city"
                                                placeholder='Enter City'
                                                margin="dense"
                                                // className="user-text-box"
                                                error={!!errors['city']}
                                                helperText={errors['city']?.message}

                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <br /> */}
                                <Grid>
                                    <span style={{ textAlign: 'left' }}>Country <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"country"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("country")}
                                                {...field}
                                                name="country"
                                                placeholder='Enter Country'
                                                margin="dense"
                                                // className="user-text-box"
                                                error={!!errors['country']}
                                                helperText={errors['country']?.message}

                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <br /> */}
                                <Grid>
                                    <span style={{ textAlign: 'left' }}>Postal Code <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"postalCode"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("postalCode")}
                                                {...field}
                                                name="postalCode"
                                                placeholder='Enter Postal Code'
                                                margin="dense"
                                                // className="user-text-box"
                                                error={!!errors['postalCode']}
                                                helperText={errors['postalCode']?.message}

                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <br /> */}
                                <Grid>
                                    <span style={{ textAlign: 'left' }}>Contact Number <font color='red'>*</font> </span>
                                    <br />
                                    <Controller
                                        name="contactNumber"
                                        control={control}
                                        rules={{
                                            validate: (value) => handleValidate(value)
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                id="contactNumber"
                                                name="contactNumber"
                                                placeholder='Enter Contact Number'
                                                margin="dense"
                                                addInternationalOption={true}
                                                international={true}
                                                style={errors.contactNumber && {
                                                    border: '1px solid red'
                                                }}
                                                inputProps={{
                                                    inputRef: register('contactNumber'),
                                                }}
                                            />
                                        )}
                                    />
                                    {errors["contactNumber"] && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Contact Number is required</div>
                                    )}
                                </Grid>
                                <br />
                                <Grid>
                                    <span style={{ textAlign: 'left' }}>Email <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"email"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("email")}
                                                {...field}
                                                name="email"
                                                placeholder='Enter Email address'
                                                margin="dense"
                                                // className="user-text-box"
                                                error={!!errors['email']}
                                                helperText={errors['email']?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <br /> */}
                                {/* <Grid>
                                    <span style={{ textAlign: 'left' }}>Subscription plan <font color='red'>*</font></span>
                                        <br />
                                        <Controller
                                            control={control}
                                            name={"subscriptionPlanId"}
                                            render={({ field }) => (
                                                <Dropdown
                                                    inputRef={register('subscriptionPlanId', { required: true })}
                                                    {...field}
                                                    placeholder='Select Subscription plan'
                                                    margin="dense"
                                                    sx={{marginTop:'7px'}}
                                                    className="user-dropdown"
                                                    style={{ width: '100%', height: '56px' }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    options={subscriptionList}
                                                    error={!!errors['subscriptionPlanId']}
                                                    helperText={errors['subscriptionPlanId']?.message}
                                                />
                                            )}
                                        />
                                        {errors.subscriptionPlanId && (
                                            <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Subscription plan is required</div>
                                        )}
                                </Grid> */}
                                <br />


                                {/* <br /> */}
                                {params?.customerId &&
                                    <Grid>
                                        <span style={{ textAlign: 'left' }}>Registered Date <font color='red'>*</font></span>
                                        <br />
                                        <Controller
                                            control={control}
                                            name={"registeredDate"}
                                            render={({ field }) => (
                                                <PayaptTextField
                                                    {...register("registeredDate")}
                                                    {...field}
                                                    format='YYYY-MM-DD'
                                                    name="registeredDate"
                                                    placeholder='Enter Registered Date'
                                                    margin="dense"
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                // className="user-text-box"
                                                />
                                            )}
                                        />
                                    </Grid>
                                }
                                <Grid>
                                    <span style={{ textAlign: 'left', lineHeight: 2.5 }}>Subscription Start Date <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"validityStartDate"}
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DatePicker
                                                    format='YYYY-MM-DD'
                                                    value={field.value ? dayjs(new Date(field.value)) : null}
                                                    selected={field.value ? dayjs(new Date(field.value)) : null}
                                                    sx={{ width: '100%' }}
                                                    inputRef={register('validityStartDate')}
                                                    name="validityStartDate"
                                                    margin="dense"
                                                    onChange={(e) => onChangeStartDateFrom(e)}
                                                    slotProps={{
                                                        textField: {
                                                            error: !startValidation && errors["validityStartDate"],
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                    {!startValidation && errors["validityStartDate"] && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Validity Start Date is required</div>
                                    )}
                                </Grid>
                                <br />
                                <Grid>
                                    <span style={{ textAlign: 'left', lineHeight: 2.5 }}>Subscription End Date <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        defaultValue={null}
                                        name={"validityEndDate"}
                                        render={({ field }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DatePicker
                                                    format='YYYY-MM-DD'
                                                    value={field.value ? dayjs(new Date(field.value)) : null}
                                                    selected={field.value ? dayjs(new Date(field.value)) : null}
                                                    sx={{ width: '100%' }}
                                                    inputRef={register('validityEndDate')}
                                                    name="validityEndDate"
                                                    margin="dense"
                                                    onChange={(e) => onChangeEndDateFrom(e)}
                                                    slotProps={{
                                                        textField: {
                                                            error: !endValidation && errors["validityEndDate"],
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        )}

                                    />
                                    {!endValidation && errors["validityEndDate"] && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>
                                            {errorMessage ? errorMessage : "Validity End Date is required"}
                                            </div>
                                    )}
                                </Grid>
                                <br />

                                <Grid >
                                    <span >Contact Person<font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"contactPerson"}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                {...register("contactPerson")}
                                                {...field}
                                                name="contactPerson"
                                                placeholder='Enter Contact Person'
                                                margin='dense'
                                                error={!!errors['contactPerson']}
                                                helperText={errors['contactPerson']?.message}
                                            />
                                        )}
                                    />

                                </Grid>
                                <Grid >
                                    <span style={{ textAlign: 'left' }}>Status <font color='red'>*</font></span>
                                    <br />
                                    <Controller
                                        control={control}
                                        name={"accountStatus"}
                                        render={({ field }) => (
                                            <Dropdown
                                                inputRef={register('accountStatus', { required: true })}
                                                {...field}
                                                placeholder='Select Customer status'
                                                margin="dense"
                                                sx={{ marginTop: '7px' }}
                                                className="user-dropdown"
                                                style={{ width: '100%', height: '56px' }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                options={accountStatusList}
                                                error={!!errors['accountStatus']}
                                                helperText={errors['accountStatus']?.message}
                                            />
                                        )}
                                    />
                                    {errors.accountStatus && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Status is required</div>
                                    )}
                                </Grid>
                                <br />
                                {/* <Grid ml={-2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <AddCheckbox
                                        control={control}
                                        register={register}
                                        setValue={setValue}
                                        errors={errors}
                                        name={"restApiConnectivity"}
                                        label={"REST API Connect"}
                                    />
                                    <AddCheckbox
                                        control={control}
                                        register={register}
                                        setValue={setValue}
                                        errors={errors}
                                        name={"mqsetUp"}
                                        label={"MQ Status"}
                                    />
                                </Grid> */}
                                <Box my={2} sx={{ display: 'flex', justifyContent: 'flex-start' }} >
                                    <MUIButton
                                        startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                        sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                                        color="inherit"
                                        title="Back"
                                        onClick={() =>  navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER}`) }
                                    />&nbsp;&nbsp;&nbsp;
                                    <MUIButton
                                        startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                        title="Save Changes"
                                        onClick={handleSubmit(onSubmit)}
                                        type="submit" />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default EditCustomer; 
