import React from 'react';
import { Box, FormGroup, Grid, Typography } from '@mui/material';
import RenderCondFormType from './RenderCondFormType';

const styles = {
    formGroup: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flexGrow: 1,
    }
};

function Filter(props) {
    const { options, filters = {}, setFilters = () => { }, totalElements, displayTotalCount=true } = props;
    // const handleOnChange = (newValue, name, catName) => {
    //     console.log("newValue",newValue);
    //     setFilters((prevSt) => ({
    //         ...prevSt,
    //         [catName]: {
    //             ...prevSt[catName],
    //             [name]: newValue
    //         }
    //     }))
    // }
    const handleOnChangee = (newValue, name, catName) => {   //this is for dropdown multiple select
        console.log("newVALUE",newValue);
        setFilters((prevSt) => {
            const prevCatFilters = prevSt[catName] || {};
            const prevFilterValues = prevCatFilters[name] || [];

            // Check if the new value is already in the array 
            // const updatedFilterValues = prevFilterValues.includes(newValue)
            //     ? prevFilterValues.filter(value => value !== newValue) // Remove the value if it exists
            //     : [...prevFilterValues, newValue]; // Add the value if it doesn't exist

            const updatedFilterValues = prevFilterValues.includes(newValue)
            ? [...prevFilterValues] // Keep the existing values as is
            : [...prevFilterValues, newValue]; // Add the new value if it doesn't exist
             console.log("updatedFilterValues",updatedFilterValues);
            return {
                ...prevSt,
                [catName]: {
                    ...prevSt[catName],
                    [name]: updatedFilterValues
                }
            };
        });
    }
    const handleOnChange = (newValue, name, catName, isMultiple = false) => {
        console.log("newVALUE", newValue);
    
        setFilters((prevSt) => {
            const prevCatFilters = prevSt[catName] || {};
    
            if (isMultiple) {
                let updatedFilterValues;
                const prevFilterValues = prevCatFilters[name] || [];
    
                if (prevFilterValues.includes(newValue)) {
                    // Remove the newValue from the array if it already exists
                    updatedFilterValues = prevFilterValues.filter(value => value !== newValue);
                } else {
                    // Add the new value if it doesn't exist
                    updatedFilterValues = [...prevFilterValues, newValue];
                }
                console.log("updatedFilterValues (multiple)", updatedFilterValues);
    
                return {
                    ...prevSt,
                    [catName]: {
                        ...prevCatFilters,
                        [name]: updatedFilterValues
                    }
                };
            } else {
                // Handle single selection
                console.log("updatedValue (single)", newValue);
                return {
                    ...prevSt,
                    [catName]: {
                        ...prevCatFilters,
                        [name]: newValue
                    }
                };
            }
        });
    };
    
    return (
        <Box mt={3} sx={{
            flex: 1,
            maxHeight: '50vh',
            overflowY: 'auto',
        }}>
            {/* {
               displayTotalCount &&
            <Box px={2} sx={{ display: 'flex', justifyContent:'flex-end' }}>
                <Typography sx={{fontWeight: 'bold'}}>Found <Typography sx={{display: 'inline-block', color: 'green'}}>{totalElements}</Typography> results</Typography>
            </Box>
            } */}

            {options?.map((xCat) => {
                return (
                    <Box key={xCat.id} my={1.5}>
                        <Grid container>
                            <Grid item xs={2} md={2.5} sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}><Typography sx={{ fontSize: '16px', fontWeight: '500' }}>{xCat.categoryLabel}</Typography></Grid>
                            <Grid item xs={6} md={9}>
                                <FormGroup sx={styles.formGroup}>
                                    {xCat?.subOptions?.map((ySubCat) => {
                                        return (
                                            <Box key={ySubCat.id}>
                                                <RenderCondFormType
                                                    value={filters[xCat.categoryName][ySubCat.filterName]}
                                                    type={ySubCat.filterType}
                                                    label={ySubCat.filterLabel}
                                                    catName={xCat.categoryName}
                                                    name={ySubCat.filterName}
                                                    dropdownOptions={ySubCat?.filterOptions || []}
                                                    onChange={handleOnChange} />
                                            </Box>
                                        )
                                    })}
                                </FormGroup>

                            </Grid>
                        </Grid>

                    </Box>
                )
            })}
        </Box>
    );
}

export default Filter;
