import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import { Box } from '@mui/material';
import AppHeader from '../components/header';
import TestRunsContainer from './testRunsContainer';
import ManageSettings from '../components/Settings/manageSettings';
import Users from '../components/Users/Users';
import customers from '../components/Customers/Customers';
import TestCasesContainer from './TestCases/TestCasesContainer';
import { menuItems } from '../constants/constants';
import { DashboardIcon, ExpandIcon, HeartIcon, MessagesIcon, SettingsIcon, SettlementIcon, TestcasesIcon, TestExecutionIcon, UsersIcon } from '../constants/menuIcons';
import { onLogout, updateSelectedMenuKeys } from '../redux/common/common.slice';
import { persistor } from '../redux/store';
import Menu from '../shared/components/Menu/menu';
import MessageXmlList from '../components/messages/messageXmlList';
import CreateIncomingTemplates from '../components/messages/createIncomingTemplates';
import CreateIncomingContainer from '../components/messages/createIncomingContainer';
import OutGoingXmlList from '../components/messages/outGoingXmlList';
import MessageTemplates from '../components/messages/messageTemplates';
import SchemaValidator from '../components/schemaValidator';
import { APP_ROUTE_KEYS } from '../shared/constants/constants';
import NotFoundPage from '../components/NotFoundPage';
import ConfigureTestData from '../components/onboarding/ConfigureTestData';
import SimulatorTestData from '../components/onboarding/simulatorTestData';
import DashBoardContainer from '../components/Dashboard/dashBoardContainer';
import AccountInfoData from '../components/onboarding/accountInfoData';
import Customers from '../components/Customers/Customers';
import EditCustomer from '../components/Customers/editCustomer';
import AccessPage from '../shared/components/AccessPage';
import { menus } from '../constants/constants';
import ActivityLogs from '../components/ActivityLog/activityLogs';
import ChooseSubscriptionPlan from '../components/onboarding/ChooseSubscriptionPlan';
import ChooseParticipationType from '../components/onboarding/ChooseParticipationType';
import ConfigureParticipationSettings from '../components/Settings/ConfigureParticipationSettings';
import { useOnUserLogOutMutation } from '../redux/users/users.api';
import EodProcessing from '../components/Settings/EodProcessing';
import SimulatorTestDataNewTable from '../components/onboarding/SimulatorTestDataNewTable';
import BatchExecutionSummary from '../components/homeScreens/BatchExecutionSummary';
import UploadTestCase from '../components/UploadTestCase/UploadTestCase';
import ConfigureParameter from '../components/Settings/ConfigureParameter';
import OrganizationDetails from '../components/Settings/OrganizationDetails';
import Connectivity from '../components/Users/Connectivity';
import TestCaseBatch from './Btaches/TestCaseBatch';
import TestCaseRepo from '../components/UploadTestCase/TestCaseRepo';
import UploadPreRequisite from '../components/UploadTestCase/UploadPreRequisite';
import RespondentBank from '../components/Settings/RespondentBank';
import SettlementAccount from '../components/Settings/SettlementAccount';
import AccountInfoContainer from '../components/Settings/AccountInfoContainer';
import RespondentRoutingNo from '../components/Settings/RespondentRoutingNo';
import UserProfile from '../components/Profile/updateUserProfile';
import ChangePassword from '../components/Profile/changePassword';
import Reports from '../components/Reports/Reports';
import EODReports from '../components/Reports/EODReports';
import Admi006 from '../components/Reports/Admi006';
import RetrievalRequest from '../components/Reports/RetrievalRequest';
import Admi004 from '../components/Reports/Admi004';
import FednowPartFile from '../components/Reports/FednowPartFile';
import Notification from '../components/Notifications/Notification';
import { useMenusBasedOnPaymentMutation, useMenusBasedOnPaymentQuery } from '../redux/dashboard/dashboard.api';
import ClearData from '../components/ClearData/ClearData';



const { Header } = Layout;

const getIcon = (icon, isActive) => {
  switch (icon) {
    case 'settings':
      return <SettingsIcon isActive={isActive} />
    case 'messages':
      return <MessagesIcon isActive={isActive} />
    case 'testcases':
      return <TestcasesIcon isActive={isActive} />
    case 'settlement':
      return <SettlementIcon isActive={isActive} />
    case 'users':
      return <UsersIcon isActive={isActive} />
    case 'dashboard':
      return <DashboardIcon isActive={isActive} />
    case 'standaloneMsg':
      return <ExpandIcon isActive={isActive} />
    case 'messageFlow':
      return <ExpandIcon isActive={isActive} />
    case 'testRuns':
      return <TestExecutionIcon isActive={isActive} />
    case 'SA':
      return <ExpandIcon isActive={isActive} />
    case 'MF':
      return <ExpandIcon isActive={isActive} />
    default:
      return null;
  }
}

const AuthenticatedRoutes = (props) => {
  // console.log('[AuthenticatedRoutes]');
  const dispatch = useDispatch();
  const [onUserLogOut, { data }] = useOnUserLogOutMutation()
  const navigate = useNavigate();
  const { privileges } = useSelector((state) => state?.common?.userDetails?.userRole)
  const { selectedSubMenuKey, selectedMenuKey, selectedSubMenuTitle, collapseSideMenu, isUserAuthenticated, isUserOnBoardingCompleted, userMenu } = useSelector((state) => state.common);
  const [desC, setDesc] = useState(selectedSubMenuTitle)
  const [pacs, setPacs] = useState(selectedMenuKey)
  const [msgTitle, setMsgTitle] = useState('')
  const [ getMenuData ,{data:menu}] = useMenusBasedOnPaymentMutation();
  const [latestMenu, setLatestMenu] = useState(userMenu);
 
  useEffect(() => {
    getMenuData().then(res => {
      console.log("33333",res?.data?.latestMenu);
      setLatestMenu(res?.data?.latestMenu)
    })
  },[])
  const onClick = async (e, item) => {
   const res=await getMenuData()
   if(res?.data?.latestMenu?.length>0){
    setLatestMenu(res?.data?.latestMenu)
   }
  //  console.log("res",res);
    setDesc(item?.description)
    setPacs(item?.key)
    setMsgTitle(item?.description)
    if (!item.disabled) {
      const xPayload = {
        selectedMenuKey: item.key,
        selectedSubMenuKey: item.parentKey,
        selectedSubMenuTitle: item.description
      }
      console.log('xPayload', xPayload);
      await dispatch(updateSelectedMenuKeys(xPayload));
      getUrl(item);
    }

  };

  const getUrl = (key) => {
    console.log("KEY", key);
    if (!key?.parentKey) {
      switch (key.key) {
        case 'Dashboard':
          return navigate(`${APP_ROUTE_KEYS.DASHBOARD.MASTER}`)
        case 'Batches':
          return navigate(`${APP_ROUTE_KEYS.BATCHES.MASTER}/cct`)
        case 'Users':
          return navigate(`${APP_ROUTE_KEYS.USERS.MASTER}`)
        // case 'Reports' :
        //   return navigate(`${APP_ROUTE_KEYS.REPORTS.MASTER}`)
        case 'Customers':
          return navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER}`)
        case 'User Activity Logs':
          return navigate(`${APP_ROUTE_KEYS.USER_AUDIT_LOG.MASTER}`)
        case 'Settings':
          return navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)
        case 'Master Test Cases':
          return navigate(`${APP_ROUTE_KEYS.TEST_CASES_REPO.MASTER}`)
        case 'Upload Test Cases':
          return navigate(`${APP_ROUTE_KEYS.UPLOAD_TESTCASE.MASTER}`)
        case 'Pre-requisite data':
          return navigate(`${APP_ROUTE_KEYS.PRE_REQUISITE_DATA.MASTER}`)
        case 'Notifications':
          return navigate(`${APP_ROUTE_KEYS.NOTIFICATIONS.MASTER}`)
        case 'Clear Data':
          return navigate(`${APP_ROUTE_KEYS.CLEAR_DATA.MASTER}`)
        default:
          return null
      }
    }
    else if (key?.parentKey === 'Test Cases Library') {
      console.log("ParentKey", key?.parentKey);
      switch (key.parentKey) {
        case 'Test Cases Library':
          return navigate(`${APP_ROUTE_KEYS.TEST_CASES.MASTER}`)
        // case 'Return Request':
        // return navigate(`${APP_ROUTE_KEYS.TEST_CASES.MASTER}`)
        // case 'Payment Return':
        // return navigate(`${APP_ROUTE_KEYS.TEST_CASES.MASTER}`)
        // case 'Payment Status Request':
        //   return navigate(`${APP_ROUTE_KEYS.TEST_CASES.MASTER}`)
        //   case 'RFP Cancellation Request':
        //     return navigate(`${APP_ROUTE_KEYS.TEST_CASES.MASTER}`)
        //   case 'Request for Payment(RFP)':
        //   return navigate(`${APP_ROUTE_KEYS.TEST_CASES.MASTER}`)   
        default:
          return null
      }
    }
    else if (key?.parentKey === 'Executions') {
      console.log("ParentKey", key?.parentKey);
      switch (key.key) {
        case 'Batch Execution Summary':
          return navigate(`${APP_ROUTE_KEYS.TEST_RUNS.MASTER}/summary`)
        case 'Test Execution Summary':
          return navigate(`${APP_ROUTE_KEYS.TEST_RUNS.MASTER}/cct`)
        default:
          return null
      }
    }
    else if (key?.parentKey === "System Messages") {
      switch (key.key) {
        case 'Admi.006':
          return navigate(`${APP_ROUTE_KEYS.SYSTEM_MESSAGES.MASTER}`)
      }
    }
    else if (key?.parentKey === "Reports/System Messages") {
      switch (key.key) {
        case 'Account Reporting Request':
          return navigate(`${APP_ROUTE_KEYS.REPORTS.MASTER}`)
        case 'System Messages':
          return navigate(`${APP_ROUTE_KEYS.REPORTS.EOD_REPORTS}`)
        case 'Retrieval Request':
          return navigate(`${APP_ROUTE_KEYS.REPORTS.RETRIEVAL_REQUEST_ADMI006}`)
        case 'Broadcast Messages':
          return navigate(`${APP_ROUTE_KEYS.REPORTS.SYSTEM_MESSAGES}`)
        case 'FedNow Participant File':
          return navigate(`${APP_ROUTE_KEYS.REPORTS.FEDNOW_PARTICIPANT}`)
        default:
          return null
      }
    }

  }
  const onLogOut = async () => {
    await dispatch(onLogout());
    persistor.purge();
    persistor.flush();
    onUserLogOut()
    localStorage.clear();
    navigate('/signin');
  }
  console.log('selectedMenuKey', selectedMenuKey);
  const getClassName = (className) => {
    if (collapseSideMenu) {
      return `${className}-collapsed`
    }
    return className;
  }
  const getItems = (menus, itemClassName, parentKey) => {
    const items = [];
    _.map(menus, m => {
      if (!_.isEmpty(m.children)) {
        const children = getItems(m.children, 'payapt-nested-menu-item', m.key);
        items.push({
          key: m.key,
          icon: getIcon(m.key, selectedSubMenuKey === m.key),
          label: <span className='menu-item-text'>{m.title}</span>,
          className: getClassName(selectedSubMenuKey === m.key ? 'payapt-submenu-item-selected' : 'payapt-submenu-item'),
          children,
          open: selectedSubMenuKey === m.key,
          onTitleClick: () => onClick(m.key, null, m.key)
        })
      } else {
        items.push({
          key: m.key,
          icon: getIcon(m.key, selectedMenuKey === m.key),
          label: <span className='menu-item-text'>{m.title}</span>,
          className: getClassName(selectedMenuKey === m.key ? `${itemClassName}-selected` : itemClassName),
          onClick: () => onClick(m.key, m.url, parentKey)
        })
      }
    })
    return items;
  }



  const getFilteredMenuItems = () => {
    const data = [];
    _.forEach(privileges, p => {
      const menu = _.find(menuItems, m => m?.privilege === p);
      if (menu) {
        data.push(menu);
      }

    })
    return data;
  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const type = searchParams.get('type')
  return (
    <Layout style={{ height: '100vh' }}>
      <Header className='app-header-full'><AppHeader onLogOut={onLogOut} isUserAuthenticated={isUserAuthenticated} /></Header>
      <Layout style={{ height: `calc(100vh-100px)`, display: 'flex', flexDirection: 'row', width: '100%' }}>
        {
          isUserOnBoardingCompleted &&
          <div style={{
            width: collapseSideMenu ? 40 : 394, display: 'flex', flexDirection: 'column',
            justifyContent: 'space-between', backgroundColor: '#ffffff', borderRight: '1px solid #D4D4D4', overflowY: "auto"
          }}>
            <Menu
              items={latestMenu && latestMenu ? latestMenu : userMenu}
              // items = {menuItems}
              onClick={(e, item) => onClick(e, item)}
              selectedKeys={[selectedMenuKey]}
              collapse={collapseSideMenu}
              openSubmenu={true}
              showExpandIcon={true}
              selectionKey='key'
              menuTitleKey='title'
              submenuTitleKey='description'
              childrenKey='children'
              width={collapseSideMenu ? 40 : 298}
              submenuSelectionKey='key'
              selectedSubmenuKeys={[selectedSubMenuKey]}
            />
            {!collapseSideMenu &&
              <div className='app-footer'>
                <span className='footer1'>Payapt &copy; 2023</span>
                {/* <span className='footer2'>Made with <HeartIcon /> Payapt</span> */}
              </div>
            }
          </div>
        }

        <Box style={{ backgroundColor: '#ffffff', width: 'inherit' }}>
          <Routes>
            {/* Core Routes */}

            <Route path={APP_ROUTE_KEYS.SIMULATOR.MASTER} element={<SchemaValidator />} />
            <Route path={`${APP_ROUTE_KEYS.MESSAGE.MASTER}/${APP_ROUTE_KEYS.MESSAGE.XML_LIST}`} element={
              <AccessPage menu={menus.MESSAGES}>
                <MessageXmlList />
              </AccessPage>
            } />
            <Route path={`${APP_ROUTE_KEYS.MESSAGE.MASTER}/${APP_ROUTE_KEYS.MESSAGE.TEMPLATE}`} element={
              <AccessPage menu={menus.MESSAGES}>
                <MessageTemplates />
              </AccessPage>
            } />
            <Route path={`${APP_ROUTE_KEYS.TEST_CASES.MASTER}`} element={<TestCasesContainer messageTypePayload={pacs} title={desC}
              subtitle={`Details of test cases for ${desC}`}
            />} />
            {/* <Route path={`${APP_ROUTE_KEYS.TEST_CASES.RETURN_REQUEST}/:testType`} element={<TestCasesContainer messageTypePayload={'Camt.056'} title={"Return Request"}
            subtitle={"Details of test cases for Return Request"}
            />} />
            <Route path={`${APP_ROUTE_KEYS.TEST_CASES.PAYMENT_RETURN}/:testType`} element={<TestCasesContainer messageTypePayload={'Pacs.004'} title={"Payment Return"}
            subtitle={"Details of test cases for Payment Return"}
            />} />
            <Route path={`${APP_ROUTE_KEYS.TEST_CASES.PAYMENT_CANCELLATION}/:testType`} element={<TestCasesContainer messageTypePayload={'Camt.055'} title={"Payment cancellation"}
            subtitle={"Details of test cases for Payment Cancellation"}
            />} />
            <Route path={`${APP_ROUTE_KEYS.TEST_CASES.PAYMENT_STATUS_REQUEST}/:testType`} element={<TestCasesContainer messageTypePayload={'Pacs.028'} title={"Payment Status Request"}
            subtitle={"Details of test cases for Payment Status Request"}
            />} />
            <Route path={`${APP_ROUTE_KEYS.TEST_CASES.INFORMATION_REQUEST_RESPONSE}/:testType`} element={<TestCasesContainer messageTypePayload={'Camt.026'} title={"Information Request Response"}
            subtitle={"Details of test cases for Information Request Response"}
            />} />
            <Route path={`${APP_ROUTE_KEYS.TEST_CASES.REQUEST_FOR_PAYMENT_RESPONSE}/:testType`} element={<TestCasesContainer messageTypePayload={'Pain.013'} title={"Request for Payment Response"}
            subtitle={"Details of test cases for Request for Payment Response"}
            />} /> */}
            <Route path={`${APP_ROUTE_KEYS.TEST_RUNS.MASTER}/:testType`} element={<TestRunsContainer />} />
            <Route path={`${APP_ROUTE_KEYS.TEST_RUNS.SUMMARY}`} element={<BatchExecutionSummary />} />
            <Route path={APP_ROUTE_KEYS.SETTINGS.MASTER} element={
              <AccessPage menu={menus.SETTINGS}>
                <ManageSettings />
              </AccessPage>
            } />

            <Route path={APP_ROUTE_KEYS.SETTINGS.ACCOUNT_INFO} element={
              <AccessPage menu={menus.SETTINGS}>
                <AccountInfoData />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.SETTINGS.STIMULATOR_TEST_DATA_NEW_TABLE} element={
              <AccessPage menu={menus.SETTINGS}>
                <SimulatorTestDataNewTable />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.SETTINGS.CHOOSE_PLAN} element={
              <AccessPage menu={menus.SETTINGS}>
                <ChooseSubscriptionPlan />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.SETTINGS.CHOOSE_TYPE} element={
              <AccessPage menu={menus.SETTINGS}>
                <ChooseParticipationType />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.SETTINGS.CONFIG_TYPE} element={
              <AccessPage menu={menus.SETTINGS}>
                <ConfigureParticipationSettings />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.SETTINGS.TEST_DATA} element={
              <AccessPage menu={menus.SETTINGS}>
                <ConfigureTestData />
              </AccessPage>
            } />

            <Route path={APP_ROUTE_KEYS.SETTINGS.STIMULATOR_TEST_DATA_NEW_TABLE} element={
              <AccessPage menu={menus.SETTINGS}>
                <SimulatorTestDataNewTable />
              </AccessPage>} />

            <Route path={APP_ROUTE_KEYS.SETTINGS.SIM_DATA} element={
              <AccessPage menu={menus.SETTINGS}>
                <SimulatorTestData />
              </AccessPage>} />

            <Route path={APP_ROUTE_KEYS.SETTINGS.ORGANIZ_DETAILS} element={
              <AccessPage menu={menus.SETTINGS}>
                <OrganizationDetails />
              </AccessPage>} />

            <Route path={APP_ROUTE_KEYS.SETTINGS.CONNECTIVITY} element={
              <AccessPage menu={menus.SETTINGS}>
                <Connectivity />
              </AccessPage>} />


            <Route path={APP_ROUTE_KEYS.SETTINGS.CONFIG_PARAMS} element={
              <AccessPage menu={menus.SETTINGS}>
                <ConfigureParameter />
              </AccessPage>} />

            <Route path={APP_ROUTE_KEYS.SETTINGS.RESPONDENT_BANK} element={
              <AccessPage menu={menus.SETTINGS}>
                <RespondentBank />
              </AccessPage>} />

            <Route path={APP_ROUTE_KEYS.SETTINGS.RESPONDENT_RTN_SETTINGS} element={
              <AccessPage menu={menus.SETTINGS}>
                <RespondentRoutingNo />
              </AccessPage>} />


            <Route path={APP_ROUTE_KEYS.SETTINGS.SETTLEMENT_ACCOUNT} element={
              <AccessPage menu={menus.SETTINGS}>
                <SettlementAccount />
              </AccessPage>} />

            <Route path={APP_ROUTE_KEYS.SETTINGS.ACCOUNT_INFO_SETTINGS} element={
              <AccessPage menu={menus.SETTINGS}>
                <AccountInfoContainer />
              </AccessPage>
            } />

            <Route path={APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <ManageSettings />
              </AccessPage>
            } />

            <Route path={APP_ROUTE_KEYS.CUSTOMERS.ACCOUNT_INFO_TAB} element={
              <AccessPage menu={menus.CUSTOMERS}>
                {/* <AccountInfoData /> */}
                <AccountInfoContainer />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.CUSTOMERS.ACCOUNT_INFO_SETTINGS} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <AccountInfoData />
                {/* <AccountInfoContainer/> */}
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.CUSTOMERS.CHOOSE_PLAN_SETTINGS} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <ChooseSubscriptionPlan />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.CUSTOMERS.CHOOSE_TYPE_SETTINGS} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <ChooseParticipationType />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.CUSTOMERS.CONFIG_TYPE_SETTINGS} element={
              <AccessPage menu={menus.CUSTOMERS}>
                {/* <ConfigureParticipationType screenType = {type !== 'review'} /> */}
                <ConfigureParticipationSettings />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.CUSTOMERS.TEST_DATA_SETTINGS} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <ConfigureTestData />
              </AccessPage>
            } />

            <Route path={APP_ROUTE_KEYS.CUSTOMERS.SIM_DATA_SETTINGS} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <SimulatorTestData />
              </AccessPage>} />
            <Route path={APP_ROUTE_KEYS.CUSTOMERS.STIMULATOR_TEST_DATA_NEW_TABLE_SETTINGS} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <SimulatorTestDataNewTable />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.CUSTOMERS.EOD_PROCESS_SETTINGS} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <EodProcessing />
              </AccessPage>} />

            <Route path={APP_ROUTE_KEYS.CUSTOMERS.ORGANIZ_DETAILS_SETTINGS} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <OrganizationDetails />
              </AccessPage>} />

            <Route path={APP_ROUTE_KEYS.CUSTOMERS.CONNECTIVITY_SETTINGS} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <Connectivity />
              </AccessPage>} />

            <Route path={APP_ROUTE_KEYS.CUSTOMERS.CONFIG_PARAMS_SETTINGS} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <ConfigureParameter />
              </AccessPage>} />

            <Route path={APP_ROUTE_KEYS.CUSTOMERS.SETTLEMENT_ACCOUNT} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <SettlementAccount />
              </AccessPage>} />

            <Route path={APP_ROUTE_KEYS.CUSTOMERS.RESPONDENT_BANK} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <RespondentBank />
              </AccessPage>} />

            <Route path={APP_ROUTE_KEYS.CUSTOMERS.RESPONDENT_RTN} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <RespondentRoutingNo />
              </AccessPage>} />

            <Route path={APP_ROUTE_KEYS.USERS.MASTER} element={
              <AccessPage menu={menus.USERS}>
                <Users />
              </AccessPage>
            } />

            <Route path={APP_ROUTE_KEYS.NOTIFICATIONS.MASTER} element={
              <AccessPage menu={menus.NOTIFICATIONS}>
                <Notification />
              </AccessPage>
            } />

            <Route path={APP_ROUTE_KEYS.CLEAR_DATA.MASTER} element={
              <AccessPage menu={menus.CLEARDATA}>
                <ClearData />
              </AccessPage>
            } />

            <Route path={APP_ROUTE_KEYS.REPORTS.MASTER} element={
              <AccessPage menu={menus.REPORTS}>
                <Reports />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.REPORTS.EOD_REPORTS} element={
              <AccessPage menu={menus.REPORTS}>
                <EODReports />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.REPORTS.RETRIEVAL_REQUEST_ADMI006} element={
              <AccessPage menu={menus.REPORTS}>
                <Admi006 />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.REPORTS.MESSAGE_ADMI006} element={
              <AccessPage menu={menus.REPORTS}>
                <RetrievalRequest />
              </AccessPage>
            } />

            <Route path={APP_ROUTE_KEYS.REPORTS.SYSTEM_MESSAGES} element={
              <AccessPage menu={menus.REPORTS}>
                <Admi004 />
              </AccessPage>
            } />

            <Route path={APP_ROUTE_KEYS.REPORTS.FEDNOW_PARTICIPANT} element={
              <AccessPage menu={menus.REPORTS}>
                <FednowPartFile />
              </AccessPage>
            } />

            <Route path={APP_ROUTE_KEYS.SYSTEM_MESSAGES.MASTER} element={
              <AccessPage menu={menus.SYSTEM_MESSAGES}>
                <Admi006 />
                {/* //optinal */}
              </AccessPage>
            } />
            {/* <Route path={APP_ROUTE_KEYS.SYSTEM_MESSAGES.RETRIEVAL_REQUEST} element={
              <AccessPage menu={menus.SYSTEM_MESSAGES}>
                <RetrievalRequest/>
                optional
              </AccessPage>
            } /> */}
            <Route path={APP_ROUTE_KEYS.CUSTOMERS.MASTER} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <Customers />
              </AccessPage>
            } />
            <Route path={APP_ROUTE_KEYS.CUSTOMERS.CREATE_DATA} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <EditCustomer />
              </AccessPage>
            } />
            <Route path={`${APP_ROUTE_KEYS.CUSTOMERS.UPDATE_DATA}/:customerId`} element={
              <AccessPage menu={menus.CUSTOMERS}>
                <EditCustomer />
              </AccessPage>
            } />
            <Route path={`${APP_ROUTE_KEYS.UPLOAD_TESTCASE.MASTER}`} element={
              <AccessPage menu={menus.UPLOAD_TESTCASE}>
                <UploadTestCase />
              </AccessPage>
            } />
            <Route path={`${APP_ROUTE_KEYS.PRE_REQUISITE_DATA.MASTER}`} element={
              <AccessPage menu={menus.PRE_REQUISITE_DATA}>
                <UploadPreRequisite />
              </AccessPage>
            } />
            <Route path={`${APP_ROUTE_KEYS.TEST_CASES_REPO.MASTER}`} element={
              <AccessPage menu={menus.TEST_CASES_REPO}>
                <TestCaseRepo />
              </AccessPage>
            } />

            <Route path={`${APP_ROUTE_KEYS.TEST_CASES_REPO.UPLOAD_TESTCASE}`} element={
              <AccessPage menu={menus.TEST_CASES_REPO}>
                <UploadTestCase />
              </AccessPage>
            } />
            {/* <Route path={APP_ROUTE_KEYS.DASHBOARD.MASTER} element={<TestDataReview />} /> */}
            <Route path={APP_ROUTE_KEYS.DASHBOARD.MASTER} element={
              <AccessPage menu={menus.DASHBOARD}>
                <DashBoardContainer />
              </AccessPage>
            } />
            <Route path={`${APP_ROUTE_KEYS.BATCHES.MASTER}/:testType`} element={
              <AccessPage menu={menus.BATCHES}>
                <TestCaseBatch title={"Batches"} messageTypePayload={'Pacs.008'} />
              </AccessPage>
            } />
            {/* <Route path='/userProfile' element={<UserProfileContainer />} /> */}
            <Route path='/userProfile' element={<UserProfile />} />
            <Route path='/changePassword' element={<ChangePassword />} />
            <Route path='/activityLogs' element={<ActivityLogs />} />
            {/* unknown routes */}
            <Route path="*" element={<NotFoundPage />} />

            {/* //TODO: Routes that should be changed to dynamic paths by Moorthy*/}
            <Route path={`${APP_ROUTE_KEYS.MESSAGE.MASTER}/${APP_ROUTE_KEYS.MESSAGE.INCOMING_TEMPLATE}`} element={
              <AccessPage menu={menus.MESSAGES}>
                <CreateIncomingTemplates />
              </AccessPage>
            } />
            <Route path={`${APP_ROUTE_KEYS.MESSAGE.MASTER}/${APP_ROUTE_KEYS.MESSAGE.XML_VALIDATE_REPORT}`} element={
              <AccessPage menu={menus.MESSAGES}>
                <CreateIncomingContainer />
              </AccessPage>} />
            <Route path={`${APP_ROUTE_KEYS.MESSAGE.MASTER}/${APP_ROUTE_KEYS.MESSAGE.OUT_GOING_XML_LIST}`} element={
              <AccessPage menu={menus.MESSAGES}>
                <OutGoingXmlList />
              </AccessPage>} />

          </Routes>
        </Box>
      </Layout>
    </Layout>
  )
}

export default AuthenticatedRoutes;
