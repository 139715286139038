import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from '@mui/icons-material/Create';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Modal, Upload } from "antd";
import { DeleteOutlined, LeftCircleFilled } from "@ant-design/icons";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DragDropFile from "../../shared/components/DragDropFile";
import AddData from "./UploadandAdd testData/AddData";
import '../styles/configStyles.css';
import PageEmpty from "../../shared/components/PageEmpty";
import MUIButton from "../../shared/components/MUIButton";
import { updateCurrentStep } from "../../redux/customerOnBoarding/customerOnBoarding.slice";
import TestCaseHeader from "../testCaseHeader";
import AntModal from "../../shared/components/Modal/AntModal";
import { getFilterOptions, getSimulatorTestDataFilterOptions, simulatorTestDataInitialFilters, testRunInitialFilters } from "../homeScreens/TestRun/constants";
import useModal from "../../shared/hooks/useModal.hook";
import { ALERT_SEVERITY, APP_ROUTE_KEYS, greyBorderColor, primaryColor, redColor } from "../../shared/constants/constants";
import Filter from "../../shared/components/Filter.popup";
import { useGetSimulatorTestDataWithTypeMutation, useSimulatorListMutation, useSimulatorTypeDropdownMutation, useUpdateCustomerSimulatorTestDataListMutation } from "../../redux/settings/settings.api";
import { useNavigate } from "react-router-dom";
import { ConfigProvider, Form, Input, InputNumber, Table, message } from 'antd';
import { useContext } from "react";
import { SnackbarContext } from "../../shared/context/SnackbarContext.Context";
import { colors } from "../../shared/colors";
import PayaptTable from "../../shared/components/Table";
import { CustomIconButton } from "../../shared/components/CustomIconButton";
import PayaptTextField from "../../shared/components/TextField";
import PayaptLoader from '../../shared/components/Spinner';
import Dropdown from "../../shared/components/Dropdown";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { generateCurrentEpochTimeValue } from "../../shared/utils";

const { Dragger } = Upload;
const styles = {
  draggerContainer: {
    minHeight: '35vh',
    maxHeight: '50vh',
    minWidth: "60%",
    borderRadius: '20px'
  },
}
const style = {
  menuProps: {
    sx: {
      '.MuiPaper-root': {
        maxHeight: '40%',
      },
    },
  },
}


const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  onChange,
  customerBankValue,
  typeDropDownList,
  changeType,
  textfieldValue,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (<Form.Item
        name={dataIndex}
        style={{
          margin: 0,
        }}
        rules={[
          {
            required: true,
            message: ` Input required!`,
          },
        ]}
      >

        {dataIndex === "type" ?
          <Dropdown
            placeholder={`Select Type`}
            margin="dense"
            size='small'
            className="user-dropdown"
            style={{ width: '95%', height: '40px', marginBottom: "11px", backgroundColor: "rgb(250 250 250 / 0%)" }}
            inputProps={{ 'aria-label': 'Without label' }}
            options={typeDropDownList?.customerDropdown || typeDropDownList?.bankDropDown}
            MenuProps={style.menuProps}
            valueKey='type'
            labelKey='type'
            value={record[dataIndex]}
            onChange={(value) => onChange(index, dataIndex, value)}
          />
          : <PayaptTextField type={inputType} size='small' value={record[dataIndex]} placeholder={`Enter ${title}`}
            onChange={(e) => onChange(index, dataIndex, e?.target?.value)} />}
      </Form.Item>)
        : (children)}


    </td>
  );

};




const ConfigureTestData = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userRole } = useSelector((state) => state?.common?.userDetails);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = React.useState('');
  const isEditing = (record) => record.customerSimulatorTestDataId === editingKey;
  const pageSize = useSelector((state) => state?.common?.pageSize);
  const customerId = useSelector((state) => state?.common?.customerId);
  const Snackbar = useContext(SnackbarContext);
  const [pageNo, SetPageNo] = useState(0)
  const [name, SetName] = useState("");
  const [address, Setaddress] = useState("");
  const [accountNo, SetaccountNo] = useState("");
  const [routingNo, SetroutingNo] = useState("");
  const [type, Settype] = useState("");
  const [connectionIdentifier, SetconnectionIdentifier] = useState("");
  const [usedFor, SetusedFor] = useState("");
  const { open, openModal, closeModal } = useModal();
  const { currentStep } = useSelector((state) => state.customerOnBoarding);
  const [searchTerm, setSearchTerm] = useState(null);
  const [filters, setFilters] = React.useState(simulatorTestDataInitialFilters || {});
  const [typeFilter, setTypeFilter] = useState(null)
  const [filterOptions, setFilterOptions] = useState(getSimulatorTestDataFilterOptions([]))
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [expandSimulatorTestDataList, { data: expandData, isLoading }] = useSimulatorListMutation();
  const [updateCustomerSimulatorTestDataList, { isLoading: updateLoading }] = useUpdateCustomerSimulatorTestDataListMutation();
  const [typeDropDown, { data: typeDropDownList }] = useSimulatorTypeDropdownMutation();
  const [customerBankValue, setCustomerBankValue] = useState();
  const [isSimulatorList, setIsSimulatorList] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const formData = new FormData();
  const token = localStorage.getItem('jwttoken');

  var initialPayload = {
    "search": searchTerm,
    "page": pageNo,
    "size": pageSize,
    "type": customerBankValue,
    "customerId": customerId
  }
  useEffect(() => {

    expandSimulatorTestDataList(initialPayload)
  }, [])


  useEffect(() => {
    expandSimulatorTestDataList(initialPayload)

  }, [isSimulatorList])

  useEffect(() => {
    if (customerBankValue) {
      typeDropDown({ type: customerBankValue })
    }
  }, [customerBankValue])

  const onConfirm = () => {
    dispatch(updateCurrentStep(currentStep + 1));
  }

  const onBack = () => {
    // dispatch(updateCurrentStep(currentStep - 1));
    userRole?.name == 'Super Admin' ?
      navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`)
      :
      navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)
  }

  // File Upload
  const handleOnChangeDragDrop = (xFilesInfo) => {
    const { fileList, file } = xFilesInfo;
    console.log("🚀 ~ file: simulatorTestData.js:94 ~ handleOnChangeDragDrop ~ xFilesInfo:", { xFilesInfo })
  }
  const handleFileChange = (file) => {
    setSelectedFile(file);
    setFileList([
      {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url: URL.createObjectURL(file),
      },
    ]);
  };
  const handleDelete = () => {
    setSelectedFile(null);
    setFileList([]);
  };

  const handleUploadClick = () => {
    setIsModalVisible(true);
  }

  const onUploadFile = (file) => {
    console.log("FILE UPLOAD",file);
    formData.append('customerId',customerId)
    formData.append('type',customerBankValue)
    formData.append('file',selectedFile)
    uploadSimulatorTestData(formData)
  }
  const uploadSimulatorTestData = () => {
    fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/Simulator/simulatorTestDataupload`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("RESPONSE", data);
                if (data.status == "success") {
                    // displaySnackbar("success", "Excel data processed and saved.")
                    Snackbar.displayMsg(data?.message, ALERT_SEVERITY.SUCCESS)
                    setIsModalVisible(false)
                    setSelectedFile(null);
                    setFileList([]);
                } else {
                    // displaySnackbar("error", "Excel data is Failed")  
                    Snackbar.displayMsg("File is Failed to Upload",ALERT_SEVERITY.ERROR )
                }
            })
  }
  // Add Test Data
  const handleAddTestDataClick = () => {
    setIsModalVisible2(true);
  }

  //  Filter Test Data

  useEffect(() => {
    console.log("filtersss", filters);
    if (filters?.type?.Customer && !filters?.type?.Bank) {
      setTypeFilter("Customer")
    }
    if (filters?.type?.Bank && !filters?.type?.Customer) {
      setTypeFilter("Bank")
    }
    if ((filters?.type?.Customer && filters?.type?.Bank)) {
      setTypeFilter(null)
    }
    if (!filters?.type?.Customer && !filters?.type?.Bank) {
      setTypeFilter(null)
    }

  }, [filters])

  const handleSaveFilter = async () => {

    var filterChangePayload = {
      page: 0,
      size: 10,
      filter: typeFilter,
      customerId: customerId
    }
    await simulatorTestDataWithTypeList(filterChangePayload)

    closeModal();
  }
  const handleCancelFilter = () => {
    setFilters({ ...simulatorTestDataInitialFilters });
    closeModal();
  }

  // Search Test Data
  const handleSearchOnChange = (xValue) => {

    var searchChangePayload = {

      search: xValue,
      page: pageNo,
      size: pageSize,
      type: customerBankValue,
      customerId: customerId
    }
    expandSimulatorTestDataList(searchChangePayload)
    setSearchTerm(xValue);
  };

  // Test Data Drop Down
  const onChangeCustomerBankDropDown = (event) => {

    setCustomerBankValue(event)
    var customerBankChangePayload = {
      "search": searchTerm,
      "page": pageNo,
      "size": pageSize,
      "type": event,
      "customerId": customerId
    }
    expandSimulatorTestDataList(customerBankChangePayload)
    setEditingKey('')

  }


  const edit = (record) => {

    setEditingKey(record?.customerSimulatorTestDataId);
    form.setFieldsValue({
      name: '',
      address: '',
      accountNo: '',
      type: '',
      routingNo: '',
      usedFor: '',
      connectionIdentifier: '',

      ...record,
    });
    SetName(record?.name != null ? record?.name : "");
    Setaddress(record?.address != null ? record?.address : "");
    SetaccountNo(record?.accountNo != null ? record?.accountNo : "");
    Settype(record?.type != null ? record?.type : "");
    SetroutingNo(record?.routingNo != null ? record?.routingNo : "");
    SetconnectionIdentifier(record?.connectionIdentifier != null ? record?.connectionIdentifier : "");
    SetusedFor(record?.usedFor != null ? record?.usedFor : "");


  };

  const columnsForCustomer = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      editable: true
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '30%',
      editable: true,
    },
    {
      title: 'Account Id',
      dataIndex: 'accountNo',
      key: 'accountNo',
      width: '20%',
      editable: true
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '20%',
      editable: true
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Grid container flexDirection={"row"} alignItems={'flex-end'}>
            <Grid item xs={6}>
              <CustomIconButton size="small" color={primaryColor} onClick={() => save(record?.customerSimulatorTestDataId)}>
                <CheckIcon fontSize='1rem' />
              </CustomIconButton>
            </Grid>
            <Grid item xs={6}>
              <CustomIconButton size="small" color={redColor} onClick={cancel}>
                <CloseIcon fontSize='1rem' />
              </CustomIconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container flexDirection={"row"} alignItems={'flex-end'}>
            <Grid item xs={6}>
              <CustomIconButton size="small" color={primaryColor} onClick={() => edit(record)}>
                <CreateIcon fontSize='1rem' />
              </CustomIconButton>
            </Grid>

          </Grid>
        );
      },
    },
  ];
  const mergedColumnsForCustomer = columnsForCustomer.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'accountNo' ? 'text' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        onChange: onInputChange,
        typeDropDownList

      }),
    };
  });



  const columnsForBank = [
    {
      title: 'RTN',
      dataIndex: 'routingNo',
      key: 'routingNo',
      width: '20%',
      editable: true
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      editable: true
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '20%',
      editable: true,
    },

    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '20%',
      editable: true
    },

    {
      title: 'Used For',
      dataIndex: 'usedFor',
      key: 'usedFor',
      width: '20%',
      editable: true
    },
    {
      title: 'Connection Party Indentifier',
      dataIndex: 'connectionIdentifier',
      key: 'connectionIdentifier',
      width: '20%',
      editable: true
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Grid container flexDirection={"row"} alignItems={'flex-end'}>
            <Grid item xs={6}>
              <CustomIconButton size="small" color={primaryColor} onClick={() => save(record?.customerSimulatorTestDataId)}>
                <CheckIcon fontSize='1rem' />
              </CustomIconButton>
            </Grid>
            <Grid item xs={6}>
              <CustomIconButton size="small" color={redColor} onClick={cancel}>
                <CloseIcon fontSize='1rem' />
              </CustomIconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container flexDirection={"row"} alignItems={'flex-end'}>
            <Grid item xs={6}>
              <CustomIconButton size="small" color={primaryColor} onClick={() => edit(record)}>
                <CreateIcon fontSize='1rem' />
              </CustomIconButton>
            </Grid>

          </Grid>
        );
      },
    },
  ];
  const mergedColumnsForBank = columnsForBank.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'routingNo' ? 'text' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        onChange: onInputChange,
        typeDropDownList
      }),
    };
  });


  const onInputChange = (index, key, val) => {



    if (key === "name") {
      SetName(val);
    } else if (key === "address") {
      Setaddress(val);
    } else if (key === "accountNo") {
      SetaccountNo(val);
    } else if (key === "type") {
      Settype(val);
    } else if (key === "routingNo") {
      SetroutingNo(val)
    }
    else if (key === "connectionIdentifier") {
      SetconnectionIdentifier(val)
    }
    else if (key === "usedFor") {
      SetusedFor(val)
    }


  }


  const save = async (key) => {

    try {
      const row = await form.validateFields();
      console.log("rowssss data", row);

      var updatePayload = {
        type: type,
        name: name,
        address: address,
        account_no: accountNo,
        routing_no: routingNo,
        usedFor: usedFor,
        connectionPartyIdentifier: connectionIdentifier,
        customerSimulatorTestDataId: key
      }

      await updateCustomerSimulatorTestDataList(updatePayload).then(res => {
        if (res?.error?.data === 'Customer Simulator Test Data Updated Successfully') {

          Snackbar.displayMsg("Customer Simulator Test Data Updated Successfully", ALERT_SEVERITY.SUCCESS);
          expandSimulatorTestDataList(initialPayload)
          setEditingKey("")
        }
      })
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };



  const cancel = () => {
    setEditingKey('');
  };


  const updatePageAndGetData = (options) => {

    SetPageNo(options.page)
    var pagechangedData = {
      search: searchTerm,
      page: options.page,
      size: pageSize,
      type: customerBankValue,
      customerId: customerId
    }
    expandSimulatorTestDataList(pagechangedData)

  };

  const handleDownloadClick = async () => {
    setIsDownloadLoading(true)
        const token = await localStorage.getItem('jwttoken');
        await fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/Simulator/downloadCustomerSimulatorTestData`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                  search: searchTerm,
                  page:pageNo,
                  type : customerBankValue,
                  size:pageSize,
                  customerId: customerId
                })
            })

            .then((response) => response.blob())
            .then((blob) => {
                console.log('blob',JSON.stringify(blob));
                console.log('response',JSON.stringify(blob));
                // Create blob link to download  
                setIsDownloadLoading(false) 
                const url = window.URL.createObjectURL(new Blob([blob]))
                // const filename = url.split('/').pop()
                // console.log("filename:",filename);
                const link = document.createElement('a');
                const fileName="Customer Simulator Test Data"+generateCurrentEpochTimeValue()
                link.href = url;
                link.setAttribute('download', `${fileName}.xlsx`);
                // Append to html link element page    
                document.body.appendChild(link); // Start download    
                link.click();// Clean up and remove the link    
                link.parentNode.removeChild(link);
            })

    }


  return (
    <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, overflowX: 'hidden', }}>
      {updateLoading && <PayaptLoader />}
      {isDownloadLoading && <PayaptLoader sx={{ position: "absolute", left: "55%" }} />}
      <Box sx={{ display: 'flex', flexDirection: 'column', }}>
        <TestCaseHeader
          title={"Simulator Test Data"}
          subtitle={"About updating Simulator Test Data details goes here"}

          showSearch={{
            show: true,
            value: searchTerm,
            onChange: handleSearchOnChange
          }}
          showFilter={{
            show: false,
            onClick: openModal
          }}

          showCustomerBank={{
            show: true,
            value: customerBankValue,
            onChange: onChangeCustomerBankDropDown,
          }}
          showFileUpload={{
            show: true,
            onClick: handleUploadClick,
            customIcon: <UploadIcon />
          }}
          showFileDownload={{
            show: true,
            onClick: handleDownloadClick,
            customIcon: <FileDownloadIcon />
          }}
          showFileAdded={{
            show: true,
            onClick: handleAddTestDataClick,
            customIcon: <PlaylistAddIcon />
          }}
        />
        <Box my={2} style={{ textTransform: 'none' }}>
          <Form form={form} component={false}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: colors.primary.main,
                },
              }}
            >
              {/* <Table */}

              {customerBankValue && <PayaptTable
                columns={customerBankValue === "Customer" ? mergedColumnsForCustomer : customerBankValue === "Bank" ? mergedColumnsForBank : {}}
                data={expandData?.simulatorData}
                bordered={false}
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                size="small"
                className="standalone_table"
                loading={isLoading}
                rowKey={(rec) => rec?.customerSimulatorTestDataId}
                // style={{ width: '1100px', maxHeight: '52vh', overflowY: 'auto' }}
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                onChange={(pagination) =>
                  updatePageAndGetData({
                    page: pagination.current - 1,
                  })
                }
                pagination={{
                  pageSize,
                  current: !_.isEmpty(expandData?.simulatorData) ? expandData.page + 1 : 1,
                  total: !_.isEmpty(expandData?.simulatorData) ? expandData.totalElement : 0,
                  showPrevNextJumpers: true,
                  showTitle: true,
                  hideOnSinglePage: true,

                }}
              />}

            </ConfigProvider>
          </Form>
        </Box>

        <Grid container justifyContent="flex-start" sx={{ mx: 1.5 }}>
          <MUIButton
            startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
            title="Back"
            sx={{ backgroundColor: '#F0F2F5', color: '#808080', mx: 2 }}
            color="inherit"
            onClick={onBack}
          />
          {/* <MUIButton
            startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
            title="Confirm"
            onClick={onConfirm}
          /> */}
        </Grid>

        <Modal
          onCancel={() => setIsModalVisible(false)}
          open={isModalVisible}
          footer={[]}
          centered
          closeIcon={<CancelOutlined sx={{ color: "#001A11", backgroundColor: "white" }} />}
          style={styles.draggerContainer}
        >
          <DragDropFile
            title={"Upload Your Test Data Here"}
            subTitle={"About uploading test data instruction goes here"}
            sampleFileUrl={""}
            hasDropdown
            onChange={handleOnChangeDragDrop}
            onUploadFile={onUploadFile}
            beforeUpload={file => {
              // handleFileChange(file);
              // return false;
              const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
              const fileType = file.type;

              if (allowedTypes.includes(fileType)) {
                handleFileChange(file);
              } else {
                // Display an error message or perform any desired action for unsupported file types.
                console.log('Unsupported file type ');
                Snackbar.displayMsg(`Unsupported File Type. Upload with .csv,.xls,.xlsx`, ALERT_SEVERITY.ERROR);
              }

              return false;
            }}
            showUploadList={{

              showRemoveIcon: true,
              removeIcon: <DeleteOutlined onClick={handleDelete} />,

            }}
            fileList={fileList}
          />

          {/* <TestCaseHeader
            title={"Upload Your Test Data Here"}
            subtitle={"About uploading test data instruction goes here"}
          />
          <Dragger style={{ padding: '30px', cursor: 'pointer',  }}
            name="file"
            multiple={false}
            // beforeUpload={file => {
            //     handleFileChange(file);
            //     return false;
            // }}
            beforeUpload={file => {
              const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
              const fileType = file.type;

              if (allowedTypes.includes(fileType)) {
                handleOnChangeDragDrop(file);
              } else {
                // Display an error message or perform any desired action for unsupported file types.
                console.log('Unsupported file type');
                Snackbar.displayMsg(`Unsupported File Type`, ALERT_SEVERITY.ERROR);
              }

              return false;
            }}
            showUploadList={{
              showRemoveIcon: true,
              removeIcon: <DeleteOutlined onClick={handleDelete} />,
            }}
            maxCount={1}
            fileList={fileList}
          >
            <CloudUploadIcon style={{ fontSize: 38, color: '#808080' }} />
            <p>Drag & Drop your file here</p>
          </Dragger> */}

        </Modal>
        <Modal
          onCancel={() => setIsModalVisible2(false)}
          open={isModalVisible2}
          footer={[]}
          width='762px'
          closeIcon={<CancelOutlined sx={{ color: "#001A11", backgroundColor: "white" }} />}
          style={{ height: '710px', borderRadius: '20px', }}
          destroyOnClose
        >
          <AddData
            setModalVisibility={setIsModalVisible2}
            setIsSimulatorList={setIsSimulatorList}
          />
        </Modal>
        {open && <AntModal
          title="Filter Results For Simulator Test Data "
          open={open}
          width={'750px'}
          style={{ top: '15%', left: '22%' }}
          mask={false}
          okText="Apply Filter"
          cancelText="Clear"
          onOk={handleSaveFilter}
          okButtonProps={{
            style: {
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: primaryColor
            },
            icon: <CheckCircleIcon sx={{ fontSize: '18px' }} />
          }}
          cancelButtonProps={{
            style: {
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#F0F2F5',
              color: greyBorderColor
            },
            icon: <CancelIcon sx={{ fontSize: '18px' }} />
          }}
          onCancel={handleCancelFilter}
          closeIcon={<CancelOutlinedIcon sx={{ color: "#001A11", backgroundColor: "white" }} />}
        >
          <Filter filters={filters} setFilters={setFilters} options={filterOptions} totalElements={data?.totalElements} />
        </AntModal>}
      </Box>
    </Box>
  );
};
export default ConfigureTestData;