import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Box, Checkbox, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useDispatch, useSelector } from 'react-redux';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';

import { sampleDataRowsConfigure } from './constants';
import ConditionalIcon from '../../assets/images/conditional.png';
import prohibitedIcon from '../../assets/images/prohibited.png';
import mandatoryIcon from '../../assets/images/mandatory.png'
import { MandatoryIcon, OptionalIcon, ProhibitedIcon, ConditionalIconSvg, UserCheckedIcon } from '../../assets/base64/images';
import MUIButton from '../../shared/components/MUIButton';
import { LeftCircleFilled } from '@ant-design/icons';
import { updateCurrentStep } from '../../redux/customerOnBoarding/customerOnBoarding.slice';
import { useNavigate } from 'react-router-dom';
import "../homeScreens/homeScreen.css";
import { useGetParticipationTypeByIdMutation, useGetUpdateParticipationTypeMutation, useGetUpdatedParticipationReviewListByIdMutation, } from '../../redux/customerOnBoarding/customerOnBoarding.api';
import { array } from 'prop-types';
import { onboardingScreen } from '../../constants/constants';
import _, { forEach, map } from 'lodash';
import { APP_ROUTE_KEYS } from '../../shared/constants/constants';



const ConfigureParticipationType = (props) => {
  const [getParticipationTypeById, { data }] = useGetParticipationTypeByIdMutation();
  const [getUpdateParticipationType] = useGetUpdateParticipationTypeMutation();
  const [getUpdatedParticipationReviewListById, { data: reviewList }] = useGetUpdatedParticipationReviewListByIdMutation();
  const { participationType } = useSelector(state => state?.common?.customerOnBoarding);
  const { screenType = false } = props;
  var payload = participationType?.participationTypeId

  React.useEffect(() => {
    getParticipationTypeById(payload)
    // getUpdatedParticipationReviewListById(payload)
  }, [participationType]);


  const handleUpdateCustomerSend = (event) => {
    console.log('handleUpdateCustomer', event);
    var updatePayload = [{
      // id: 0,
      participationId: payload,
      participationConfigId: event.participationTypeConfigId,
      send: "YES",
      receive: "NO"
    }]
    console.log('update', updatePayload);
    getUpdateParticipationType(updatePayload)
  }
  const handleUpdateCustomerReceive = (event) => {
    console.log('handleUpdateCustomer', event);
    var updatePayload = [{
      // id: 0,
      participationId: payload,
      participationConfigId: event.participationTypeConfigId,
      send: "YES",
      receive: "NO"
    }]
    console.log('update',);
    // getUpdateParticioationType(updatePayload)
  }
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.customerOnBoarding);
  console.log("🚀 ~ file: configureParticipationType.js:22 ~ Configure: ", { currentStep });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [review, setReview] = React.useState(false);
  const [tableData, setTableData] = React.useState();
  const [modifiedDataArray, setModifiedDataArray] = React.useState([]);

  const { isDashboard } = useSelector((state) => state.common)
  const { userRole } = useSelector((state) => state?.common?.userDetails)
  var modifiedDataRow = []
  const navigate = useNavigate();

  const onHandlePress = () => {
    setReview(!review)
  }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "none",
      fontSize: 14,
      border: 0,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      border: 0,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  React.useEffect(() => {
    if (review) {
      getUpdatedParticipationReviewListById(payload)
    }
  }, [participationType, review])

  const onConfirm = () => {
    // dispatch(updateCurrentStep(currentStep + 1))
    navigate('/onboardprocessdone')
  }
  const onReview = () => {
    setReview(true);

  }
  const onBack = () => {
    dispatch(updateCurrentStep(currentStep - 1))
    setReview(false);
  }
  const onReviewBack = () => { setReview(false) };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  React.useEffect(() => {
    if (sampleDataRowsConfigure)

      setTableData(sampleDataRowsConfigure);

    if (screenType) {
      setReview(true)

    }
  }, [])

  const configMenu = [
    {
      key: 'customerCreditTransfer',
      value: 'Customer Credit Transfer'
    },
    {
      key: 'paymentReturns',
      value: 'Payment Returns'
    },
    {
      key: 'requestForPayment',
      value: 'Request For Payment'
    },
    {
      key: 'informationRequestMessage',
      value: 'Information Request Message'
    },
    {
      key: 'accountReportingMessage',
      value: 'Account Reporting Message'
    },
    {
      key: 'systemMessages',
      value: 'System Messages'
    }
  ];




  // const dataArray = [sampleDataRowsConfigure];
  // console.log("tableee",dataArray);
  // const handleClose = (e) => {
  //     setAnchorEl(null);
  // if (e.currentTarget.localName !== "ul") {
  //     const menu = document.getElementById("simple-menu").children[2];
  //     const menuBoundary = {
  //         left: menu.offsetLeft,
  //         top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
  //         right: menu.offsetLeft + menu.offsetWidth,
  //         bottom: menu.offsetTop + menu.offsetHeight
  //     };
  //     if (
  //         e.clientX >= menuBoundary.left &&
  //         e.clientX <= menuBoundary.right &&
  //         e.clientY <= menuBoundary.bottom &&
  //         e.clientY >= menuBoundary.top
  //     ) {
  //         return;
  //     }
  // }
  // }
  // const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };
  // const handleClose = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }
  //   setOpen(false);
  // };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const menuItems = [
    {
      // src : MandatoryIcon, 
      svg: <MandatoryIcon />,
      label: "mandatory"
    },
    {
      svg: <ProhibitedIcon />,
      label: "Prohibited",
    },
    {
      svg: <OptionalIcon />,
      label: "Optional"
    },
    {
      svg: <ConditionalIconSvg />,
      label: "Conditional"
    },
    {
      Desc: '',
      label: "N/A Not Applicable"
    },
  ];

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const sendCheckboxClick = (zRow) => {
    setTableData(tableData?.filter(x =>
      x.rows.filter(y => y.name === zRow.name ?
        y.sendStatus = !y.sendStatus : '')))
    setTableData(tableData?.filter(x =>
      x.rows.filter(y => y.name === zRow.name ?
        y.isModified = !y.isModified : '')))
  };
  const receiveCheckboxClick = (zRow) => {
    setTableData(tableData?.filter(x =>
      x.rows.filter(y => y.name === zRow.name ?
        y.receiveStatus = !y.receiveStatus : '')))
    setTableData(tableData?.filter(x =>
      x.rows.filter(y => y.name === zRow.name ?
        y.isModified = !y.isModified : '')))
  };
  const getRowClassName = (index, rec) => {
    let className = "";
    return index % 2 === 0
      ? `${className} table-row-light`
      : `${className} table-row-dark`;
  };
  

  const rowHandler = (zRow) => {
    console.log('zrow', zRow);
    modifiedDataRow.push(zRow)
    setModifiedDataArray(modifiedDataRow);
    console.log('modifiedDataRow2', modifiedDataRow);
    console.log('modifiedDataRow2', modifiedDataRow[0].name);
  }
  

  const handleRowSelect = (id) => {
    setModifiedDataArray([...modifiedDataArray, id]);
  }


  const selectRowHeader = (keyy) => {
    const filteredValues = configMenu.filter((menuItem) => {
      return menuItem.key === keyy;
    }).map((menuItem) => {
      return menuItem.value;
    }).join(', ');
    return filteredValues

  }


  return (
    <div style={{ textAlign: 'left' }}>
      <Box my={1} mx={2}>
        {isDashboard ?
          !review ? <h4 className="title">Configure Participation Type &nbsp;

            <InfoSharpIcon
              className='step-info-icon'
              id="menuButton1"
              aria-owns={open ? "simple-menu" : null}
              aria-haspopup="true"
              onMouseEnter={handleClick}
            ></InfoSharpIcon>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {menuItems.map(item => (
                <Tooltip title={item.label} key={item.label}>
                  <div style={{ flexDirection: 'row' }}>

                    <MenuItem disabled={item.disabled}>{
                      item.svg ? <span style={{ marginRight: '5px' }}>{item.svg}</span> : <></>}{item.label}</MenuItem>
                  </div>
                </Tooltip>
              ))}
            </Menu>
          </h4> :
            <h4 className="title">Review And Confirm Participation Type &nbsp;
              <InfoSharpIcon
                className='step-info-icon'
                id="menuButton1"
                aria-owns={open ? "simple-menu" : null}
                aria-haspopup="true"
                onMouseEnter={handleClick}
              ></InfoSharpIcon>
            </h4>
          :
          <h4 className="title">Selected Participation Type &nbsp;
            <InfoSharpIcon
              className='step-info-icon'
              id="menuButton1"
              aria-owns={open ? "simple-menu" : null}
              aria-haspopup="true"
              onMouseEnter={handleClick}
            ></InfoSharpIcon>
          </h4>
        }
        {!review ?
          <p className='title-content'>You have selected <span style={{ fontWeight: 'bold' }}>{participationType?.participationTypeName}</span>, now you can configure it</p> :
          <p className='title-content'>You have selected <span style={{ fontWeight: 'bold' }}>{participationType?.participationTypeName}</span>, now you can configure it</p>
        }
      </Box>
      {/* <Stack direction="row" spacing={2}></Stack> */}
      {!review ?
        <TableContainer style={{ marginTop: '20px', maxHeight: '60vh', overflowY: 'auto' }} sx={{ py: 4 }} component={Paper}>
          {
            _.map(data, (valuee, key) =>
              _.map(valuee, (value, key) => (
                <Table
                  // key={xEl.participationTypeConfigId} 
                  size='small'
                  sx={{ minWidth: 700 }}
                  aria-label="customized table">
                  <TableHead>
                    <TableRow >
                      {/* {xEl.headCells?.map((yEl) => (
                  <StyledTableCell style={{ fontWeight: 'bold' }}>{yEl}</StyledTableCell>
                ))} */}

                      <StyledTableCell style={{ fontWeight: 'bold' }}>
                        {
                          configMenu &&
                          selectRowHeader(key)
                          //  key.charAt(0).toLocaleUpperCase() + key.slice(1).match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g).join(" ")
                        }
                      </StyledTableCell>


                      <StyledTableCell style={{ fontWeight: 'bold' }}></StyledTableCell>

                      <StyledTableCell style={{ fontWeight: 'bold', width: '15%' }}>
                        {key === 'customerCreditTransfer' ? <p>Send</p> :
                          key === 'systemMessages' ? "    " :
                            key === 'requestForPayment' ? "" :
                              key === 'accountReportingMessage' ? "" :
                                key === 'paymentReturns' ? "" :
                                  key === 'informationRequestMessage' ? "" : ''}
                      </StyledTableCell>

                      <StyledTableCell style={{ fontWeight: 'bold', width: '15%' }}>
                        {key === 'customerCreditTransfer' ? <p>Receive</p> :
                          key === 'systemMessages' ? " " :
                            key === 'requestForPayment' ? "" :
                              key === 'accountReportingMessage' ? "" :
                                key === 'paymentReturns' ? "" :
                                  key === 'informationRequestMessage' ? "" : ''}
                      </StyledTableCell>

                    </TableRow>

                    <TableRow >

                    </TableRow>

                  </TableHead>
                  <TableBody>
                    {/* {newArr?.map((nEl) => (
                <p>{nEl.receive}</p>
              ))} */}
                    {
                      // value.map((zRow) => 
                      _.map(value, (zRow, key) =>
                      // console.log('arraycheck',zRow)
                      (
                        <StyledTableRow onClick={handleRowSelect} key={zRow.participationTypeConfigId}>


                          <StyledTableCell sx={{ width: '50%' }} component="th" scope="row">{zRow.typeName}</StyledTableCell>

                          <StyledTableCell>{zRow.message}</StyledTableCell>

                          <StyledTableCell  >
                            {
                              zRow.send === "M" ?
                                <>
                                  {/* <Checkbox sx={{ height: 20, width: 20, }}
                            onClick={(event) => console.log(event)}
                            checked={zRow.send}
                            inputProps={{ 'aria-label': 'controlled' }} /> */}
                                  <Box component="img" sx={{ height: 20, width: 20, }} src={mandatoryIcon} />
                                </>
                                : zRow.send === "O" ?
                                  <>
                                    <Checkbox sx={{ height: 20, width: 20, }}
                                      onClick={() => handleUpdateCustomerSend(zRow)}
                                      // checked={zRow.send}
                                      inputProps={{ 'aria-label': 'controlled' }} />
                                  </>


                                  : zRow.send === 'P' ?
                                    <>
                                      <Box component="img" sx={{ height: 20, width: 20, }} src={prohibitedIcon} />
                                    </>

                                    : zRow.send === 'C' ?
                                      <>
                                        <Box component="img" sx={{ height: 20, width: 20, }} src={ConditionalIcon} />
                                      </>

                                      // : zRow.isModified === true ? <>

                                      //   <span><UserCheckedIcon /></span>
                                      // </> 
                                      : <><p>N/A</p></>
                            }</StyledTableCell>

                          <StyledTableCell>
                            {zRow.receive === "M" ? <>
                              {/* <Checkbox sx={{height: 20,width: 20,}}
                          // onClick={(e) => receiveCheckboxClick(zRow)}
                          checked={zRow.receive}
                          inputProps={{ 'aria-label': 'controlled' }}/> */}
                              <Box component="img" sx={{ height: 20, width: 20, }} src={mandatoryIcon} />
                            </> : zRow.receive === 'O' ? <>
                              <Checkbox sx={{ height: 20, width: 20, }}
                                onClick={() => handleUpdateCustomerReceive(zRow)}
                                // checked={zRow.receive}
                                inputProps={{ 'aria-label': 'controlled' }} />
                            </>
                              : zRow.receive === 'P' ?
                                <>
                                  <Box component="img" sx={{ height: 20, width: 20, }} src={prohibitedIcon} />
                                </>
                                : zRow.receive === 'C' ?
                                  <>
                                    <Box component="img" sx={{ height: 20, width: 20, }} src={ConditionalIcon} />
                                  </>
                                  : <><p>N/A</p></>
                            }
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              ))
            )
          }

        </TableContainer> :
        <TableContainer style={{ marginTop: '20px', maxHeight: '60vh', overflowY: 'auto',width:"95%" }} sx={{ py: 2, mx:2 }} component={Paper}>
          {
            // reviewList && 
            // Object.entries(reviewResponseData).map(([key, value,index]) => 
            _.map(reviewList, (valuee, key) =>
              _.map(valuee, (value, key, index) => (
                (
                  <Table
                    // key={xEl.participationTypeConfigId} 
                    size='small'
                    sx={{ minWidth: 700 }}
                    aria-label="customized table">
                    <TableHead>
                      <TableRow >
                        {/* {xEl.headCells?.map((yEl) => (
                <StyledTableCell style={{ fontWeight: 'bold' }}>{yEl}</StyledTableCell>
              ))} */}

                        <StyledTableCell style={{ fontWeight: 'bold' }}>{
                          //   _.map(reviewList, (e, v , i) => {
                          //     console.log('e',e)
                          // })
                          //  
                          configMenu &&
                          selectRowHeader(key)

                        }
                        </StyledTableCell>


                        <StyledTableCell style={{ fontWeight: 'bold' }}></StyledTableCell>

                        <StyledTableCell style={{ fontWeight: 'bold', width: '15%' }}>
                          {key === 'customerCreditTransfer' ? <p>Send</p> :
                            key === 'systemMessages' ? "    " :
                              key === 'requestForPayment' ? "" :
                                key === 'accountReportingMessage' ? "" :
                                  key === 'paymentReturns' ? "" :
                                    key === 'informationRequestMessage' ? "" : ''}
                        </StyledTableCell>

                        <StyledTableCell style={{ fontWeight: 'bold', width: '15%' }}>
                          {key === 'customerCreditTransfer' ? <p>Receive</p> :
                            key === 'systemMessages' ? " " :
                              key === 'requestForPayment' ? "" :
                                key === 'accountReportingMessage' ? "" :
                                  key === 'paymentReturns' ? "" :
                                    key === 'informationRequestMessage' ? "" : ''}
                        </StyledTableCell>

                      </TableRow>

                      <TableRow >

                      </TableRow>

                    </TableHead>
                    <TableBody>

                      {

                        _.map(value, (zRow, key) =>
                        (
                          <StyledTableRow onClick={handleRowSelect} key={zRow.participationTypeConfigId}>


                            <StyledTableCell sx={{ width: '50%' }} component="th" scope="row">{zRow.typeName}</StyledTableCell>

                            <StyledTableCell>{zRow.message}</StyledTableCell>

                            <StyledTableCell  >
                              {
                                zRow.send === "M" ?
                                  <>
                                    {/* <Checkbox sx={{ height: 20, width: 20, }}
                          onClick={(event) => console.log(value)}
                          checked={zRow.send}
                          inputProps={{ 'aria-label': 'controlled' }} /> */}
                                    <Box component="img" sx={{ height: 20, width: 20, }} src={mandatoryIcon} />
                                  </>
                                  : zRow.send === "O" ?
                                    <>
                                      <Checkbox sx={{ height: 20, width: 20, }}
                                        // onClick={()=>handleUpdateCustomerSend(zRow)}
                                        // checked={zRow.send}
                                        inputProps={{ 'aria-label': 'controlled' }} />
                                    </>


                                    : zRow.send === 'P' ?
                                      <>
                                        <Box component="img" sx={{ height: 20, width: 20, }} src={prohibitedIcon} />
                                      </>

                                      : zRow.send === 'C' ?
                                        <>
                                          <Box component="img" sx={{ height: 20, width: 20, }} src={ConditionalIcon} />
                                        </>

                                        : zRow.send === "Yes" ? <>
                                          {/* <Box component="img" sx={{ height: 20, width: 20, }} src={} /> */}
                                          <Checkbox sx={{ height: 20, width: 20, }}
                                            // onClick={()=>handleUpdateCustomerSend(zRow)}
                                            checked={zRow.send === 'Yes' ? true : false}
                                            inputProps={{ 'aria-label': 'controlled' }} />
                                        </>
                                          : <><p>N/A</p></>
                              }</StyledTableCell>

                            <StyledTableCell>
                              {zRow.receive === "M" ? <>
                                {/* <Checkbox sx={{height: 20,width: 20,}}
                        // onClick={(e) => receiveCheckboxClick(zRow)}
                        checked={zRow.receive}
                        inputProps={{ 'aria-label': 'controlled' }}/> */}
                                <Box component="img" sx={{ height: 20, width: 20, }} src={mandatoryIcon} />
                              </> : zRow.receive === 'O' ? <>
                                <Checkbox sx={{ height: 20, width: 20, }}
                                  // onClick={()=>handleUpdateCustomer(zRow)}
                                  // checked={zRow.receive}
                                  inputProps={{ 'aria-label': 'controlled' }} />
                              </>
                                : zRow.receive === 'P' ?
                                  <>
                                    <Box component="img" sx={{ height: 20, width: 20, }} src={prohibitedIcon} />
                                  </>
                                  : zRow.receive === 'C' ?
                                    <>
                                      <Box component="img" sx={{ height: 20, width: 20, }} src={ConditionalIcon} />
                                    </>
                                    : <><p>N/A</p></>
                              }
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                ))
              ))
          }
        </TableContainer>
      }
      <Box mt={2} mx={2}>
        {isDashboard ?

          !review ?
            <>
              <Box>
                <MUIButton
                  startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                  title="Back"
                  sx={{ backgroundColor: '#F0F2F5', color: '#808080', }}
                  color="inherit"
                  onClick={() => navigate(`/customer/onboarding?screen=${onboardingScreen.SELECT_PARTICIPATION_TYPE}`)}
                />
                <MUIButton
                  startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                  title="Review"
                  sx={{mx:2}}
                  onClick={onReview}
                />
              </Box>
            </> :
            <>
              <Box>
                <MUIButton
                  startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                  title="Edit/Back"
                  sx={{ backgroundColor: '#F0F2F5', color: '#808080',  }}
                  color="inherit"
                  onClick={onReviewBack}
                />
                <MUIButton
                  startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                  title="Confirm"
                  sx={{mx:2}}
                  onClick={onConfirm}
                />
              </Box>
            </>
          :
          (userRole?.name === 'Admin' || userRole?.name === 'Tester') ?

            <MUIButton
              startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
              sx={{ backgroundColor: '#F0F2F5', color: '#808080',  }}
              color="inherit"
              title="Back"
              onClick={() => navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)}
            />
            :
            <>

              <Box>
                <MUIButton
                  startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                  sx={{ backgroundColor: '#F0F2F5', color: '#808080', }}
                  color="inherit"
                  title="Back"
                  onClick={() => navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`)}
                />
                <MUIButton
                  startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                  title="Save"
                  sx={{mx:2}}
                  onClick={() => navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`)}
                />
              </Box>
            </>
        }

      </Box>


    </div>
  );
}

export default ConfigureParticipationType;
