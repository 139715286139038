import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Tooltip } from 'antd';
import { useState } from 'react';
import { Box, Typography } from "@mui/material";
import _ from 'lodash';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { EyeIcon, FileIcon } from '../../constants/menuIcons';

import ReviewTestCases from '../messages/reviewTestCases';
import { testruns_columns } from '../../constants/constants';
import { getFilterOptions, testRunInitialFilters } from './TestRun/constants';
import { ALERT_SEVERITY, greyBorderColor, primaryColor } from '../../shared/constants/constants';
import TestCaseHeader from '../testCaseHeader';

import AntModal from '../../shared/components/Modal/AntModal';
import useModal from '../../shared/hooks/useModal.hook';
import Filter from '../../shared/components/Filter.popup';

import { getSortDirection } from '../../utils/sorterUtils';
import { ViewLogs } from '../../screens/viewLogs';
import PayaptTable from '../../shared/components/Table';

import './homeScreen.css'
import '../styles/message.css';
import { useGetTestRunsMutation, useGetMessageTypeQuery } from '../../redux/testRuns/testRuns.api';
import { useSelector } from 'react-redux';
import WrapperComponent from '../../shared/components/wrapperComponent';
import XTwoWayLabelSwitch from '../../shared/components/XTwoWayLabelSwitch';
import XSwitch from '../../shared/components/XSwitch';
import TestExecution from './TestExecution';
import PayaptTooltip from '../../shared/components/Tooltip';
import { useGetTestBatchExecutionsMutation, useKillTestRunsMutation } from '../../redux/testRuns/BatchTestRuns.api';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { BatchTestRunsMsg } from '../../shared/constants/AlertMessages';
import TestRunResult from '../../shared/components/testResult';
import moment from "moment";
import { convertToLocalTime } from '../../shared/utils';
import MUIButton from '../../shared/components/MUIButton';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    height: `calc(100vh - 100px)`,
  }
};

// const rowSelection = {
//   onChange: (selectedRowKeys, selectedRows) => {
//     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
//   },
// };

const TestRuns = (props) => {
  // const {messageTypePayload} = props;
  const navigate = useNavigate();
  const { testType } = useParams();
  const { customer } = useContext(DropdownValueContext);

  const [getTestRuns, { data, isLoading, isSuccess, isError,
    refetch: forceRefetchGetTestRuns
  }] = useGetTestRunsMutation();
  const { data: message_types } = useGetMessageTypeQuery();

  const pageSize = useSelector((state) => state?.common?.pageSize);
  const { open, openModal, closeModal } = useModal();
  const { userName, userRole } = useSelector((state) => state?.common?.userDetails);
  // const [customer, selectedCustomer] = useState();
  const { customerId } = useSelector((state) => state?.common);
  const [filterByAll, setFilterByAll] = useState(false);
  
  var initialPageOptions = {
    pageNo: 0,
    pageSize: pageSize,
    sortBy: null,
    sortDir: 'desc',
    cusId: userRole?.name == 'Super Admin' ? customer ? customer : 0 : customerId,
    filters: {
      result: null,
      direction: null,
      triggerMessage: null,
      status: filterByAll === true ? "all" :"individual",
      startDate: null,
      endDate: null,
    }
  }

  const [filters, setFilters] = React.useState(testRunInitialFilters || {});
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isXmlShow, setIsXmlShow] = useState(true);
  const [filterOptions, setFilterOptions] = useState(getFilterOptions([]))
  const [pageOptions, setPageOptions] = useState({ ...initialPageOptions });
  const [searchedText, setSearchedText] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedEntity, setSelectedEntity] = useState(null);
  
  console.log("filterByAll",customer);
  const [filterByBatchFile, setFilterByBatchFile] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerSubTitle, setHeaderSubTitle] = useState("");
  const [testRunsToKill, setTestRunsToKill] = useState({});
  const [forceRefetchBatchApi, setForceRefetchBatchApi] = useState(false);
  const [filterData, setFilterData] = useState(false);
  console.log("filterData", filterData);
  const [messageTypePayload, setMessageTypePayload] = useState();

  const Snackbar = useContext(SnackbarContext);

  const [killTestRuns] = useKillTestRunsMutation();


  const columns = testruns_columns;
  useEffect(() => {
    if (!_.isEmpty(message_types)) {
      const triggerMessageTypes = _.map(message_types, d => d.messageTypeName);
      const uniqueMessageTypes = _.uniq(triggerMessageTypes);
      const messageTypes = _.map(uniqueMessageTypes, (m, index) => ({
        id: index,
        value: m,
        label: m
      }))
      const FILTER_OPTIONS = getFilterOptions(messageTypes);
      setFilterOptions(FILTER_OPTIONS)
    }
  }, [message_types])

  // useEffect(() => {
  //   if (customer && customerId) {
  //     setPageOptions(initialPageOptions) 
  //   } 
  // }, [customer])
  console.log('pageopt', pageOptions);
  const getSelectedFilter = (result) => {
    const selectedResults = _.filter(Object.keys(result), k => result[k])
    if (!_.isEmpty(selectedResults)) {
      return selectedResults
      // return selectedResults[0]
    }
    return null
  }
  async function refetchTestRunsQuery(xOptions, xIsBatch) {
    if (!xIsBatch) {
      setPageOptions((prevState) => ({
        ...prevState,
        ...xOptions,
        cusId : userRole?.name == 'Super Admin' ? customer ? customer : 0 : customerId
      }));
      xOptions={
        ...xOptions,
        cusId : userRole?.name == 'Super Admin' ? customer ? customer : 0 : customerId
      }
      // console.log("refetchTestRunsQuery",xOptions,pageOptions);
      await getTestRuns({ ...xOptions });
    }
  }

  async function refetchTestRunsQueryWhenTypeChange(xOptions) {
    // console.log('refetchTestRunsQueryWhenTypeChange-xOptions',xOptions,customer);
    setPageOptions(prevOptions => ({
      ...prevOptions, // <-- Merge the current pageOptions with the new xOptions
      ...xOptions
    }));
    xOptions={
      ...xOptions,
      cusId : userRole?.name == 'Super Admin' ? customer ? customer : 0 : customerId
    }
    if (!filterByBatchFile) {
      await getTestRuns({ ...xOptions });
    }
  }

  const forceRefreshApis = async (xOptions, xIsBatch) => {
    setPageOptions(xOptions);
    if (xIsBatch) {
      setForceRefetchBatchApi(true);
    } else {
      await getTestRuns({ ...xOptions });
    }
  }

  useEffect(() => {
    if (!filterByBatchFile) {
      refetchTestRunsQuery(initialPageOptions, filterByBatchFile);
    }
    if (testType === 'SA') {
      setHeaderTitle("Test Execution Summary")
      setHeaderSubTitle("Details of executed test cases for test execution eummary")
    } else {
      setHeaderTitle("Test Execution Summary")
      setHeaderSubTitle("Details of executed test cases for Test Execution Summary")
    }
    setTestRunsToKill({})
  }, [testType, filterByBatchFile, customer]);

  const handleRefreshButton = () => {
    forceRefreshApis(initialPageOptions, filterByBatchFile);
  }
  const handleSwitchExecutionTypeToggle = (e) => {
    setFilterByAll(e.target.checked);
    const xPayload = {
      ...pageOptions,
      filters: {
        ...pageOptions.filters,
        status: e.target.checked ? 'all' : 'individual',
        // individual
      }
    }
    setTestRunsToKill({})
    refetchTestRunsQueryWhenTypeChange(xPayload);
  }
  const handleSwitchBatchFile = (e) => {
    setFilterByBatchFile(e.target.checked);
  }

  const getFilterPayload = () => {
    return {
      ...pageOptions.filters,
      // testType: [testType],
      result: getSelectedFilter(filters.result),
      direction: getSelectedFilter(filters.direction),
      triggerMessage: !_.isEmpty(filters.triggerMessage) && !_.isEmpty(filters.triggerMessage.triggerMessage) ? filters.triggerMessage.triggerMessage : null,
      startDate: filters?.selectDate?.selectDate[0] ? moment(new Date(filters?.selectDate?.selectDate[0].$d)).format() : null,
      endDate: filters?.selectDate?.selectDate[0] ? moment(new Date(filters?.selectDate?.selectDate[1].$d)).format() : null,

      // startDate: filters?.selectDate?.selectDate[0] ? moment.utc(new Date(filters?.selectDate?.selectDate[0].$d)).format() : null,
      // endDate: filters?.selectDate?.selectDate[0] ? moment.utc(new Date(filters?.selectDate?.selectDate[1].$d)).format() : null,  previous formate
    }
  }

  // const getFilterPayload = () => {
  //   const startDate = filters?.selectDate?.selectDate[0] ? moment.utc(filters.selectDate.selectDate[0].$d).startOf('day').format() : null;
  //   const endDate = filters?.selectDate?.selectDate[1] ? moment.utc(filters.selectDate.selectDate[1].$d).startOf('day').format() : null;
  
  //   return {
  //     ...pageOptions.filters,
  //     result: getSelectedFilter(filters.result),
  //     direction: getSelectedFilter(filters.direction),
  //     triggerMessage: !_.isEmpty(filters.triggerMessage) && !_.isEmpty(filters.triggerMessage.triggerMessage) ? filters.triggerMessage.triggerMessage : null,
  //     startDate: startDate,
  //     endDate: endDate
  //   };
  // };
  

  // Assuming filters?.selectDate?.selectDate is an array of Moment.js objects
//   const dates = filters?.selectDate?.selectDate;
// let startDate = null;
// let endDate = null;

// if (dates && dates.length >= 2) {
//     // Convert the selected dates to Moment.js objects if they are not already
//     const startDateMoment = moment(dates[0].$d);
//     const endDateMoment = moment(dates[1].$d);

//     // Check if the Moment.js objects are valid
//     if (startDateMoment.isValid() && endDateMoment.isValid()) {
//         // Convert to UTC and set to the start of the day
//         startDate = startDateMoment.utc().startOf('day').format();
//         endDate = endDateMoment.utc().startOf('day').format();
//     } else {
//         console.error('Invalid date format');
//     }
// } else {
//     console.error('Selected dates are missing or incomplete');
// }

// const payload = {
//     startDate: startDate,
//     endDate: endDate
// };


  // useEffect(() => {
  //   if (initialPageOptions?.cusId !== 0) {
  //     getTestRuns(initialPageOptions)
  //   } 
  // }, [customer])

  const handleSaveFilter = async () => {
    const options = {
      ...pageOptions,
      cusId : userRole?.name == 'Super Admin' ? customer ? customer : 0 : customerId,
      filters: {
        ...getFilterPayload()
      }
    };
    setPageOptions(options);
    if (options.filters.direction === null && options.filters.endDate === null && options.filters.result === null &&
      options.filters.startDate === null && options.filters.triggerMessage === null) { 
      Snackbar.displayMsg("Please Select Any Values", ALERT_SEVERITY.ERROR)
      openModal()
    } else {
      closeModal()
    }
    if (!filterByBatchFile) {
      await getTestRuns({
        ...options
      });
    } else {
      setFilterData(true);
      // getTestRuns(...options)
      // setFilters({ ...testRunInitialFilters });
    }
    // setFilters({ ...testRunInitialFilters });

    closeModal();
    // console.log("OPTIONS", options.filters);
  }
  const handleCancelFilter = () => {
    // setFilters({ ...testRunInitialFilters });
    closeModal();
  };

  const onClear = async () => {
    setFilters({ ...testRunInitialFilters });
    if (!filterByBatchFile) {
      setPageOptions(initialPageOptions);
     await getTestRuns(initialPageOptions)
    } 
    else {
      forceRefreshApis(initialPageOptions, filterByBatchFile);
    }
  };

  const onSearchChange = (value) => {
    console.log("value",value);
    setSearchedText(value);
    setPageOptions({ ...pageOptions, search: value });
    refetchTestRunsQuery({ ...initialPageOptions, search: value }, filterByBatchFile);
  }
  const updatePageAndGetData = (options, sorter, pagination) => {

    const newSortColumn = sorter.field;
    const newSortDirection = pageOptions.sortDir === 'asc' ? 'desc' : 'asc';
    var newPageOptions;
    if (sorter?.order) {
      newPageOptions = {
        ...pageOptions,
        pageNo: pagination.current - 1,
        sortBy: getSortColumn(sorter.field),
        sortDir: sorter ? pageOptions.sortDir === 'asc' ? 'desc' : 'asc' : pageOptions.sortDir,
        cusId : userRole?.name == 'Super Admin' ? customer ? customer : 0 : customerId
      };
    }
    else {
      newPageOptions = {
        ...pageOptions,
        pageNo: pagination.current - 1,
        cusId : userRole?.name == 'Super Admin' ? customer ? customer : 0 : customerId
      };
    }
    setPageOptions(prevOptions => ({
      ...prevOptions,
      ...newPageOptions
    }));

    getTestRuns(newPageOptions);

  };
  const setIsModalVisible = (isVisible, rec, entity) => {
    console.log("setIsModalVisible", rec);
    if (isVisible) {
      setSelectedRecord(rec);
      setSelectedEntity(entity);
      setMessageTypePayload(rec?.messageType)
    } else {
      setSelectedRecord({})
      setSelectedEntity(null)
    }
    setModalVisibility(isVisible)
  }

  columns[1].render = (data, rec) => (
    <>
      {rec.testCaseCode}
      <br />
      {rec.testCaseName}
      <br />
      {rec.messageType}
    </>
  )

  // columns[4].render = (data) => 

  //   (
  //   <>
  //     <span style={{ textTransform: 'lowercase' }}>{data}</span>
  //   </>
  //   )

  columns[4].render = (data) =>

  (
    <>
      <span style={{ textTransform: 'lowercase' }}>{data != null ? convertToLocalTime(data) : ""}</span>
    </>
  )

  columns[5].render = (data) => (
    <>
      <span style={{ textTransform: 'lowercase' }}>{Math.round(((data / 1000)) * 100) / 100} s</span>
    </>
  )
  columns[6].render = (data, rec) => {
    // console.log('data',data)
    const xObj = {
      ...rec,
      openIndex: 0,
    }
    return <TestRunResult data={data} rec={{ ...rec, openIndex: 0, testrunId: rec?.testrunId }} setIsModalVisible={setIsModalVisible} />
  }

  // // while click on the Pass/Fail status we are opening and showing  by default, the Text Execution log //
  columns[7].render = (data, rec) => (
    <span
      onClick={() => (rec?.result === 'Fail' || rec?.result === 'Pass' || rec?.result === 'Time out' || rec?.result === 'In Progress') ?
        setIsModalVisible(true, { ...rec, testrunId: rec?.testrunId }, 'viewLogs') : null}
      style={{
        justifyContent: 'center', display: 'flex',
        cursor: (rec?.result === 'Fail' || rec?.result === 'Pass' || rec?.result === 'Time out' || rec?.result === 'In Progress') ? 'pointer' : 'not-allowed'
      }}>
      <FileIcon />
    </span>
  )
  columns[8].render = (data, rec) => (
    <PayaptTooltip title={rec?.testDataNumber}>
      <a onClick={() => setIsModalVisible(true, rec, 'viewData')} style={{ justifyContent: 'center', display: 'flex' }}>
        <span>{<EyeIcon />}</span>
        {/* <span className = {data ? 'review-column-checked' : 'review-column'}>{data ? 'Reviewed' : 'Click to Review'}</span> */}
      </a>
    </PayaptTooltip>
  )

  const getSortColumn = (key) => {
    switch (key) {
      case 'executedDate':
        return 'executedDateTime'
      case 'result':
        return 'testResultId'
      case 'testCaseCode':
        return 'cutsomerTestCaseId'
      case 'messageType':
        return 'triggerMessagetype'
      default:
        return null
    }
  }

  const killTestRunsProcess = async () => {
    try {
      const testRunIds = [];
      _.forEach(Object.keys(testRunsToKill), tr => {
        _.forEach(testRunsToKill[tr], k => {
          testRunIds.push(k)
        })
      })
      await killTestRuns(testRunIds);
      setTestRunsToKill({})
      handleRefreshButton()
      Snackbar.displayMsg(BatchTestRunsMsg.KILL_TEST_RUNS_SUCCESS, ALERT_SEVERITY.SUCCESS)
    } catch (error) {
      Snackbar.displayMsg(BatchTestRunsMsg.KILL_TEST_RUNS_FAIL, ALERT_SEVERITY.ERROR)
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setTestRunsToKill({
        testRunIds: selectedRowKeys,
      })
    },
    getCheckboxProps: (record) => ({
      className: "checkbox-red",
      disabled: record?.result !== 'Pending' && record?.result !== 'In Progress',
    }),
  };

  const getCountOfSelectedTestRuns = () => {
    const testRunIds = [];
    _.forEach(Object.keys(testRunsToKill), tr => {
      _.forEach(testRunsToKill[tr], k => {
        testRunIds.push(k)
      })
    })
    return _.size(testRunIds)
  }

  const onTestExecutionCustomerSelection = (event) => {
    console.log("TestExecution====>", event);
    selectedCustomer(event);
  }

  return (
    <Box sx={styles.container}>
      <TestCaseHeader
        title={headerTitle}
        subtitle={headerSubTitle}
        showFilter={{
          show: true,
          onClick: openModal
        }}
        showRefresh={{
          show: true,
          onClick: handleRefreshButton
        }}
        showSearch={{
          show: true,
          value: searchedText,
          onChange: onSearchChange
        }}
        showKillProcess={{
          show: true,
          onClick: () => killTestRunsProcess(),
          disabled: _.isEmpty(testRunsToKill) || !getCountOfSelectedTestRuns(),
        }}
        showTestCustomer={{
          show: true,
          // onChange: onTestExecutionCustomerSelection,
          // value: customer
        }}
      >
        <Box sx={{ display: "flex", }}>
          <XTwoWayLabelSwitch
            primary={"Test Cases"} secondary={"Batch Files"} checked={filterByBatchFile} onChange={handleSwitchBatchFile}
          />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <XTwoWayLabelSwitch
            primary={"My Runs"} secondary={"All Runs"} checked={filterByAll} onChange={handleSwitchExecutionTypeToggle}
          />
        </Box>
      </TestCaseHeader>
      <Box sx={{ display: 'flex', flexDirection: 'row', }} className='standalone_table'>
        <Typography sx={{ textAlign: 'left', fontSize: 14 }}>Selected Test Runs: <b>{getCountOfSelectedTestRuns()}</b></Typography>
      </Box>
      {!filterByBatchFile &&
        <Box my={2}>
          <WrapperComponent isSuccess={isSuccess} isFetching={isLoading} isError={isError}>
            <PayaptTable
              size='small'
              columns={columns}
              data={!_.isEmpty(data) ? data.customerRuntTestList : []}
              loading={isLoading}
              className='standalone_table'
              rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
              rowKey={(rec) => rec?.testrunId}
              onChange={(pagination, filters, sorter) => updatePageAndGetData({
                pageNo: pagination.current - 1,
                sortBy: sorter ? getSortColumn(sorter.field) : null,
                sortDir: getSortDirection(sorter.order) || pageOptions.sortDir,
                search: pageOptions.search,
              }, sorter, pagination)}
              // scroll={{ x: '80vw', y: '50vh' }} 
              pagination={{
                pageSize,
                current: !_.isEmpty(data) ? data.pageNo + 1 : 1,
                total: !_.isEmpty(data) ? data.totalElements : 0,
                hideOnSinglePage: true,
                size: 'small'
              }}
              rowSelection={{
                ...rowSelection,
                selectedRowKeys: testRunsToKill?.testRunIds || [],
                preserveSelectedRowKeys: true,
              }}
            />
          </WrapperComponent>
        </Box>
      }
      {
        filterByBatchFile &&
        <TestExecution
          forceRefetchBatchApi={forceRefetchBatchApi}
          setForceRefetchBatchApi={setForceRefetchBatchApi}
          setTestRunsToKill={setTestRunsToKill}
          testRunsToKill={testRunsToKill}
          allRuns={filterByAll}
          filters={getFilterPayload()}
          canFilterData={filterData && filterByBatchFile}
          setFilterData={setFilterData}
          searchValue={searchedText}
          customer={customer}
        />
      }

      <Modal
        onCancel={() => setIsModalVisible(false)}
        open={isModalVisible} footer={[]}
        width={'90%'}
        style={{ width: '90%', height: '70%' }}
        closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
        destroyOnClose
      >
        {
          selectedEntity && selectedEntity === 'viewLogs' ?
            <ViewLogs item={selectedRecord} testcaseName={selectedRecord?.testCaseCode} />
            : <ReviewTestCases show={isXmlShow} onChange={setIsXmlShow} messageTypePayload={messageTypePayload}
              testcaseDetails={{
                testCaseId: selectedRecord?.testCaseId, testCaseName: selectedRecord?.testCaseCode,
                // customerTestCaseVersionId: selectedRecord?.customerTestCaseVersionId
                testCaseVersionId: selectedRecord?.customerTestCaseVersionId
              }}
              showSingleTestcase={true}
              onClose={() => setIsModalVisible(false)}
              selectedTestScenario={{
                masterXMLPath: selectedRecord?.customerXmlFilePath,
                customerTestScenarioName: selectedRecord?.customerTestScenarioName,
                customerTestDescription: selectedRecord?.testCaseName,
                customerTestRunId: selectedRecord?.testrunId
              }}
            />
        }
        {/* <Testcases/> */}

      </Modal>
      {open && <AntModal
        title="Filter Results for Test Runs"
        open={open}
        width={'750px'}
        style={{ top: '15%', left: '22%' }}
        mask={false}
        okText="Apply Filter"
        cancelText="Clear"
        onOk={handleSaveFilter}
        footer={[]}
        okButtonProps={{
          style: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: primaryColor
          },
          icon: <CheckCircleIcon sx={{ fontSize: '18px' }} />
        }}
        cancelButtonProps={{
          style: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F0F2F5',
            color: greyBorderColor
          },
          icon: <CancelIcon sx={{ fontSize: '18px' }} />
        }}
        onCancel={handleCancelFilter}
        closeIcon={<CancelOutlinedIcon sx={{ color: "#001A11", backgroundColor: "white" }} />}
      >
        <Filter filters={filters} setFilters={setFilters} options={filterOptions} totalElements={data?.totalElements} displayTotalCount={!filterByBatchFile} />

        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }} px={"2%"} >

          <>
            <MUIButton
              startIcon={<CancelIcon style={{ fontSize: 18, color: greyBorderColor }} />}
              title="Clear"
              sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
              color="inherit"
              onClick={onClear}
            />
            <MUIButton
              startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
              title="Apply Filter"
              onClick={handleSaveFilter}
              sx={{ mx: 2 }}
            />
          </>


        </Box>
      </AntModal>}
    </Box>
  )
}

export default TestRuns;